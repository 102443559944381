import React from 'react';
import {
  Route, Switch, BrowserRouter, Redirect,
} from 'react-router-dom';
import Login from '../views/pages/login';
import PostDetails from '../views/pages/postDetails';
import Page404 from '../views/pages/page404';
import Page500 from '../views/pages/page500';
import Home from '../views/pages/home';
import HomerSeleto from '../views/pages/homerSeleto';
import PreResults from '../views/pages/preResult';
import Teste from '../views/pages/teste';
import EnterpriseList from '../views/pages/enterpriseList';
import EnterpriseDetails from '../views/pages/enterpriseDetails';

function BrowserRoute() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route exact path="/teste" name="Teste Page" component={Teste} />
        <Route exact path="/404" name="Page 404" component={Page404} />
        <Route exact path="/500" name="Page 500" component={Page500} />
        <Route exact path="/:slug/home" name="Home" component={Home} />
        <Route exact path="/:slug" name="Home" component={HomerSeleto} />
        <Route exact path="/buscar-imoveis" name="Homer Seleto" component={HomerSeleto} />
        <Route exact path="/:slug/post/:postId" name="Post" component={PostDetails} />
        <Route exact path="/:slug/search/:isSell/:lat/:long/:propertyType/:price/:rooms/:parkingSpots/:googlePlaceName" name="Results" component={PreResults} />
        <Route exact path="/:slug/empreendimentos/:integrator?" name="Enterprise List" component={EnterpriseList} />
        <Route exact path="/:slug/empreendimento/:postId" name="Enterprise Details" component={EnterpriseDetails} />
        <Route
          exact
          path="/"
          render={() => (<Redirect to="/buscar-imoveis" />)}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default BrowserRoute;
