import React, { Component } from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import IconCheck from '../../../assets/icons/icon_check.svg';
import Close from '../../../assets/icons/close_button.svg';

class ModalSuccess extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(event) {
    event.preventDefault();
    const p = this.props;
    p.toggle(event);
  }

  render() {
    const { isOpen } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={this.handleToggle} className="modal-body">
        <Row>
          <Col md={12} className="container-close">
            <Button onClick={this.handleToggle} className="buttonClose" id="close" name="close">
              <img loading="lazy" src={Close} alt="Fechar modal" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="content-modal-success">
            <img loading="lazy" src={IconCheck} alt="Check" />
            <br />
            <br />
            <Label className="label-success">Sucesso!</Label>
            <br />
            <Label className="label-success">Mensagem recebida.</Label>
            <br />
            <Label className="label-description">Em breve, o corretor responsável pelo site entrará em contato com você.</Label>
            <br />
            <br />
            <Button className="button-back" onClick={this.handleToggle}>Voltar e buscar mais</Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}

ModalSuccess.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ModalSuccess;
