import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { postDataContactNoPost, postDataContactOtherPost, postDataContactAgentPost } from '../../../actions/contact';
import LocationIcon from '../../../assets/imgs/ic_location.png';
import SearchIcon from '../../../assets/imgs/ic_search.png';
import HouseIcon from '../../../assets/imgs/ic_house.png';
import ArrowIcon from '../../../assets/imgs/ic_arrow.png';
import LineIcon from '../../../assets/imgs/ic_line.png';
import HorizontalIcon from '../../../assets/imgs/ic_hor_line.png';

class ContactPropDev extends Component {
  componentWillUnmount() {
    // this.setState({ Requested: false });
  }

  render() {
    const {
      isSeleto, getAgentDetails,
      toggle, isMobile,
    } = this.props;

    const agentDetails = getAgentDetails && getAgentDetails.data;

    if (isSeleto) {
      return null;
    }

    return (
      <div className="app div-size incorp">
        <Row className="justify-content-center align-center title-description-seleto">
          <h5 className="title-seleto">
            Conheça os imóveis de&nbsp;
            <strong className="title-seleto-decoration">Incorporadora</strong>
          </h5>
        </Row>
        <Row className="justify-content-center align-center description-seleto">
          <Col md={4}>
            <h5>
              Imóveis na planta, em construção, lançamentos e
              prontos para morar, perfeitos para a sua necessidade
            </h5>
            <br />
            <h5>Veja como funciona:</h5>
          </Col>
        </Row>
        <p />
        <p />
        <Row className="justify-content-center align-center">
          <Col md={2}>
            <img loading="lazy" src={LocationIcon} alt="Incorporadora" />
            <div>
              <p />
              <h4>
                Informe a região e carecterísticas do empreendimento que deseja.
              </h4>
              <h6>
                <strong>
                  *Apenas regiões de São Paulo, Rio de Janeiro, Balneário Camboriú,
                  Vitória e Belo Horizonte.
                </strong>
              </h6>
            </div>
          </Col>
          {isMobile && (
            <Col md={2}>
              <img loading="lazy" src={HorizontalIcon} className="arrow-icon" alt="Incorporadora" />
              <p />
              <p />
            </Col>
          )}
          {!isMobile && (
            <Col md={2}>
              <img loading="lazy" src={ArrowIcon} className="arrow-icon" alt="Incorporadora" />
            </Col>
          )}

          <Col md={2}>
            <img loading="lazy" src={SearchIcon} alt="Incorporadora" />
            <div>
              <p />
              <h4>
                {agentDetails ? agentDetails.FirstName : ''}
                &nbsp;buscará o empreendimento perfeito para você!`
              </h4>
            </div>
          </Col>
          {isMobile && (
            <Col md={2}>
              <img loading="lazy" src={HorizontalIcon} className="arrow-icon" alt="Incorporadora" />
              <p />
              <p />
            </Col>
          )}
          {!isMobile && (
            <Col md={2}>
              <img loading="lazy" src={LineIcon} className="arrow-icon" alt="Incorporadora" />
            </Col>
          )}
          <Col md={2}>
            <img loading="lazy" src={HouseIcon} alt="Incorporadora" />
            <div>
              <p />
              <h4>Você escolhe seu novo lar</h4>
            </div>
          </Col>
        </Row>
        <p />
        <p />
        <Row className="justify-content-center align-center">
          <Button
            type="button"
            className="btn-salmon"
            onClick={(e) => toggle(e, '', agentDetails.UserId, true)}
          >
            Tenho interesse
          </Button>
        </Row>
      </div>
    );
  }
}

ContactPropDev.defaultProps = {
  isSeleto: false,
  isMobile: false,
};

ContactPropDev.propTypes = {
  isSeleto: PropTypes.bool,
  isMobile: PropTypes.bool,
  getAgentDetails: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    postDataContactNoPost,
    postDataContactOtherPost,
    postDataContactAgentPost,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  getAgent: state.agentState.getAgent,
  statePostContact: state.contactState.statePostContact,
  getAgentDetails: state.agentdetailsState.getAgentDetails,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactPropDev));
