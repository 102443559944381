export default {
  LIST: {
    LOADING: 'Carregando PostDetailss...',
    TITLE: 'Lista de PostDetailss',
    ADD_BUTTON: 'Adicionar',
    TABLE_HEADER: {
      NAME: 'Nome',

    },
  },
  FORM: {
    LOADING: 'Aguardando...',
    NOTIFY_SUCCESS_CAD: 'PostDetails cadastrado com sucesso!',
    NOTIFY_SUCCESS_UPD: 'PostDetails atualizado com sucesso!',
    SAVE_BUTTON: 'Salvar',
    BACK_BUTTON: 'Voltar',
    TITLE: 'Detalhes do PostDetails',
    NAME_FIELD: 'Nome',

  },
  BACK: ' voltar',
  TALK_TO: 'Fale com o Homer',
  HOMER: 'homer',
  SELETO: 'Seleto',
  SELECTED: 'Imóvel selecionado pelo Homer',
  SHARE_TEXT: 'Divulgue este imóvel',
  ADRESS: 'Endereço',
  FEATURES: 'Outras Características',
  DESCRIPTION: 'Descrição',
  EXCELLENCE: 'Imóvel selecionado a dedos e o serviço de excelência do jeito que você merece',
  CHOOSE: 'Escolhemos os melhores imóveis, com preço de mercado e um serviço de excelência, para você comprar seu imóvel com segurança e qualidade.',
  IMMOBILE: 'Imóveis escolhidos a dedo',
  CURATORSHIP: 'Curadoria de imóveis completos para você só visitar os que realmente gosta.',
  REGULARIZED: 'Seleção de imóveis regularizados',
  DOCUMENTATION: 'Preparamos a documentação do imóvel para uma compra rápida, segura e sem dor de cabeça.',
  FINANCING: 'Financiamento Descomplicado',
  ASSISTENCE: 'Assistência gratuita desde a análise do crédito em todos os bancos até o registro do imóvel.',
  SIMILAR: 'Imóveis Similares',
  FLOATING_WHATSAPP: 'WhatsApp',
  WHATSAPP_DOUBT: 'Tirar dúvidas',
  WHATSAPP_VISIT: 'Marcar uma visita',
  WHATSAPP_PROPOSAL: 'Fazer uma proposta',
  YOU_SEARCH: 'Semelhantes ao local e características que você buscou',
  LOCATION: 'Explore os imóveis perto da região buscada.',
  HASREALESTATES: 'Conheça mais opções de imóveis com a localidade próxima.',
  EXPLORE: 'Ver imóveis na região',
  OPTIONPROPERTY: 'Quer mais opções de imóveis?',
  CHARACTERISTICS: 'Diga as características que você deseja que encontraremos o imóvel ideal para você!',
  SELECTSELETO: 'Imóvel especialmente selecionado pelo Homer',
  DESCRIPTIONSELETO: 'Documentação 100% regularizada, preço competitivo de mercado e financiamento descomplicado. A compra do seu tão sonhado imóvel, com total segurança e qualidade.',
  FINDFORME: 'Encontre para mim',
  LOCATIONMAP: 'Localização',
};
