import mixpanel from 'mixpanel-browser';
import Config from '../config/environments';

mixpanel.init(Config.MIXPANEL.TOKEN);

function refreshCampaignParams() {
  const campaignKeywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
  const firstParams = {};
  const params = {};
  const urlSearchParams = new URLSearchParams(document.location.search);
  for (let index = 0; index < campaignKeywords.length; index += 1) {
    const kw = urlSearchParams.get(campaignKeywords[index]);
    if (kw && kw.length) {
      firstParams[`${campaignKeywords[index]} [first touch]`] = kw;
      params[`${campaignKeywords[index]} [last touch]`] = kw;
    }
  }
  mixpanel.people.set(params);
  mixpanel.people.set_once(firstParams);
  mixpanel.register(params);
}

const actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    refreshCampaignParams();
    mixpanel.track(name, props);
  },
  trackEvent: (props) => {
    mixpanel.track(props.event_name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

const Mixpanel = actions;

export default Mixpanel;
