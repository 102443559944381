/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// import React from 'react';
import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from 'react-share';

import {
  // Container,
  Row,
  Col,
  // CardImg,
  // FormGroup,
  // CardText,
  // Nav,
  // NavItem,
  // NavLink,
} from 'reactstrap';

function Teste() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [
    'http://placeimg.com/1200/800/nature',
    'http://placeimg.com/800/1200/nature',
    'http://placeimg.com/1920/1080/nature',
    'http://placeimg.com/1500/500/nature',
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div>
      <h1>Teste react viewer</h1>

      {images.map((src, index) => (
        <img
          loading="lazy"
          src={src}
          onClick={() => openImageViewer(index)}
          onKeyDown={() => openImageViewer(index)}
          width="300"
          key={index}
          style={{ margin: '2px' }}
          alt=""
        />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}

      <br />
      <br />
      <br />

      <h1>Teste Share Buttons</h1>

      <div className="share-buttons-container">
        <Row>
          <Col md={12}>
            <FacebookShareButton url="http://localhost:3000/buscar-imoveis/post/616243" quote="Compartilhe no Facebook">
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <WhatsappShareButton url="http://localhost:3000/buscar-imoveis/post/616243" quote="Compartilhe no Whatsapp">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </Col>
        </Row>
      </div>

    </div>
  );
}

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Teste));
export default Teste;
