import React from 'react';
import Skeleton from 'react-loading-skeleton';

const inputFilterLoading = (props) => {
  const { sideFilter } = props;
  const loading = (
    <div
      id="InputFilterLoading"
      name="InputFilterLoading"
      data-testid="InputFilterLoading"
      className={`loading-input-filter text-left ml-0 ${sideFilter ? 'mb-1' : 'mb-3'}`}
      style={{
        marginTop: '-1px',
      }}
    >
      <Skeleton
        count={1}
        height={50}
        style={{
          width: sideFilter ? '75%' : '100%',
          borderRadius: '10px',
        }}
      />
    </div>
  );

  return loading;
};

inputFilterLoading.propTypes = {
};

export default inputFilterLoading;
