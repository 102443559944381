/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { enterpriseDetailsPhotosNext, enterpriseDetailsPhotosPrevious } from '../../const/mixpanel';
import Mixpanel from '../../mixpanel';

function ReactCarouselImageViewer(props) {
  const {
    src, closeOnClickOutside, onClose, disableScroll, closeComponent, isEnterpriseDetail,
  } = props;

  const [currentIndex, setCurrentIndex] = useState(props.currentIndex ? props.currentIndex : 0);

  const changeImage = useCallback(
    (delta) => {
      let nextIndex = (currentIndex + delta) % src.length;
      if (nextIndex < 0) nextIndex = src.length - 1;
      setCurrentIndex(nextIndex);
    },
    [currentIndex],
  );

  const handleClick = useCallback(
    (event) => {
      if (!event.target || closeOnClickOutside) {
        return;
      }

      const checkId = event.target.id === 'ReactSimpleImageViewer';
      const checkClass = event.target.classList.contains('react-simple-image-viewer__slide');

      if (checkId || checkClass) {
        event.stopPropagation();
        onClose();
      }
    },
    [onClose],
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        onClose();
      }

      if (['ArrowLeft', 'h'].includes(event.key)) {
        changeImage(-1);
        if (isEnterpriseDetail) {
          Mixpanel.trackEvent(enterpriseDetailsPhotosPrevious());
        }
      }

      if (['ArrowRight', 'l'].includes(event.key)) {
        changeImage(1);
        if (isEnterpriseDetail) {
          Mixpanel.trackEvent(enterpriseDetailsPhotosNext());
        }
      }
    },
    [onClose, changeImage],
  );

  const handleWheel = useCallback(
    (event) => {
      if (event.wheelDeltaY > 0) {
        changeImage(-1);
      } else {
        changeImage(1);
      }
    },
    [changeImage],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    if (disableScroll) {
      document.addEventListener('wheel', handleWheel);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);

      if (disableScroll) {
        document.removeEventListener('wheel', handleWheel);
      }
    };
  }, [handleKeyDown, handleWheel]);

  return (
    <div
      id="ReactSimpleImageViewer"
      className="backgroundStyle wrapper react-simple-image-viewer__modal react-carousel-image-viewer"
      onKeyDown={handleKeyDown}
      onClick={handleClick}
    >
      <span
        className="close react-simple-image-viewer__close"
        style={{ opacity: 1 }}
        onClick={() => onClose()}
      >
        {closeComponent || '×'}
      </span>

      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        centerMode
        centerSlidePercentage={100}
        selectedItem={currentIndex}
        renderArrowNext={(clickHandler, hasNext) => {
          if (!hasNext) return null;
          return (
            <div
              className="arrow-next-image-viewer"
              onClick={() => {
                Mixpanel.trackEvent(enterpriseDetailsPhotosNext());
                clickHandler();
              }}
              role="button"
              tabIndex={0}
              aria-hidden="true"
              id="ArrowNext"
              name="ArrowNext"
              data-testid="ArrowNext"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="6 0 12 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="9 18 15 12 9 6" />
              </svg>
            </div>
          );
        }}
        renderArrowPrev={(clickHandler, hasPrev) => {
          if (!hasPrev) return null;
          return (
            <div
              className="arrow-prev-image-viewer"
              onClick={() => {
                clickHandler();
                Mixpanel.trackEvent(enterpriseDetailsPhotosPrevious());
              }}
              role="button"
              tabIndex={0}
              aria-hidden="true"
              id="ArrowPrev"
              name="ArrowPrev"
              data-testid="ArrowPrev"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="6 0 12 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="15 18 9 12 15 6" />
              </svg>
            </div>
          );
        }}
      >
        {src.map((image) => (
          <Row className="carousel-container">
            <Col
              className="container-image"
              key="ContainerImage"
              id="ContainerImage"
              name="ContainerImage"
              data-testid="ContainerImage"
            >
              <img
                loading="lazy"
                className="img-carousel"
                src={image}
                alt="Fotos"
                id="Image"
                name="Image"
              />
            </Col>
          </Row>
        ))}
      </Carousel>
    </div>
  );
}

ReactCarouselImageViewer.defaultProps = {
  closeComponent: false,
};

ReactCarouselImageViewer.propTypes = {
  src: PropTypes.arrayOf(PropTypes.string).isRequired,
  closeOnClickOutside: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  disableScroll: PropTypes.bool.isRequired,
  isEnterpriseDetail: PropTypes.bool.isRequired,
  closeComponent: PropTypes.bool,
  currentIndex: PropTypes.number.isRequired,
};

export default ReactCarouselImageViewer;
