import { POST_CONTACT, LIST_CONTACT, MESSAGE_ENTERPRISE } from '../actionTypes/contact';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

export const INITIAL_STATE = {
  statePostContact: BASE,
  listContact: BASE_PAGINATION,
  getMessage: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_CONTACT:
      return { ...state, statePostContact: action.payload };
    case LIST_CONTACT:
      return { ...state, listContact: action.payload };
    case MESSAGE_ENTERPRISE:
      return { ...state, getMessage: action.payload };
    default:
      return state;
  }
};
