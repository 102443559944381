import Model from '../model/map';
import Config from '../config/environments';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.PostId = view.PostId;
    model.UserId = view.UserId;
    model.UserFullname = view.UserFullname;
    model.PostType = view.PostType;
    model.PropertyType = view.PropertyType;
    model.Location = view.Location;
    model.Latitude = view.Latitude;
    model.Longitude = view.Longitude;
    model.CreatedDateTime = view.CreatedDateTime;
    model.UpdatedDateTime = view.UpdatedDateTime;
    model.Rooms = view.Rooms;
    model.Area = view.Area;
    model.Price = parseInt(`${view.Price}00`, 10);
    model.ParkingSpots = view.ParkingSpots;
    model.Description = view.Description;
    model.AvatarUrl = view.AvatarUrl;
    model.WktPolygon = view.WktPolygon;
    model.IsActive = view.IsActive;
    model.MinimumPrice = view.MinimumPrice;
    model.MaximumPrice = view.MaximumPrice;
    model.MinimumArea = view.MinimumArea;
    model.MaximumArea = view.MaximumArea;
    model.IsRent = view.IsRent;
    model.PageTotalMatches = view.PageTotalMatches;
    model.HasExtraInfo = view.HasExtraInfo;
    model.OriginalPrice = view.OriginalPrice;
    model.StaticMapUrl = view.StaticMapUrl;
    model.ShowHomerSellsOffer = view.ShowHomerSellsOffer;
    model.UserCreciNumber = view.UserCreciNumber;
    model.UserCreciVerifiedIdStatus = view.UserCreciVerifiedIdStatus;
    model.UserCreciUF = view.UserCreciUF;
    model.PostMedia = view.PostMedia || [];
    model.Media = view.Media;
    model.IsHomerSell = view.IsHomerSell;
    model.CondoFee = parseInt(`${view.CondoFee}00`, 10);
    model.PropertyTaxes = parseInt(`${view.PropertyTaxes}00`, 10);

    model.PostMedia.forEach((media) => {
      const mediaProxy = media;
      const mediaProxyContains = mediaProxy && mediaProxy.MediaUrl && mediaProxy.MediaUrl.indexOf('homer-posts');
      if (mediaProxyContains > 0) {
        mediaProxy.MediaUrl = `${media.MediaUrl}`;
      } else {
        mediaProxy.MediaUrl = `${Config.END_POINT_API}${Config.POST.INSERT}${Config.POST.MEDIAS}${Config.POST.CONTENT}${Config.POST.URL_MEDIA}${media.MediaUrl}`;
      }
    });

    return model;
  } catch (error) {
    throw new Error(`MapMapper - ViewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models, state) => {
  try {
    if (!models) return null;
    const views = [];

    models.forEach(async (model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    const upState = state || {};
    const division = parseInt(models.total / upState.PageSize, 10);
    const remainder = models.total % upState.PageSize;

    const viewsPag = {
      total: division + (remainder <= 0 ? 0 : 1),
      list: views,
      lat1: Math.min.apply(null, views.map((item) => item.Latitude)),
      lat2: Math.min.apply(null, views.map((item) => item.Longitude)),
      lng1: Math.min.apply(null, views.map((item) => item.Latitude)),
      lng2: Math.min.apply(null, views.map((item) => item.Longitude)),
    };

    return viewsPag;
  } catch (error) {
    throw new Error(`MapMapper - ViewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (state, props, agent) => {
  try {
    if (!state) return null;

    const { ZoomLevel, MapNE, MapSW } = state;
    const { match } = props;
    const { params } = match;
    const {
      isSell, propertyType, price, rooms, parkingSpots,
    } = params;

    const view = {};

    view.AgentId = agent.UserId;
    view.IsRent = isSell;
    view.ZoomLevel = ZoomLevel;
    view.Lat1 = MapNE.lat || '-22.981604450672634';
    view.Lat2 = MapSW.lat || '-23.019132940776757';
    view.Lng1 = MapNE.lng || '-43.3384281796875';
    view.Lng2 = MapSW.lng || '-43.3933598203125';
    view.MaximumPrice = price || '5000000';
    view.MinimumPrice = '0';
    view.ParkingSpots = parkingSpots || '1';
    view.PropertyType = propertyType || 'Apartamento';
    view.Rooms = rooms || '2';

    return view;
  } catch (error) {
    throw new Error(`MapMapper - ModelToViewModel = ${error}`);
  }
};

export const modelToViewModelChange = (state, props, agent) => {
  try {
    if (!state) return null;

    const {
      ZoomLevel, MapNE, MapSW, PropertyType, QttRooms, Location,
    } = state;
    const { match } = props;
    const { params } = match;
    const {
      isSell, price, parkingSpots,
    } = params;

    const view = {};

    view.AgentId = agent.UserId;
    view.IsRent = !isSell;
    view.ZoomLevel = ZoomLevel;
    view.Lat1 = MapNE.lat || '-22.981604450672634';
    view.Lat2 = MapSW.lat || '-23.019132940776757';
    view.Lng1 = MapNE.lng || '-43.3384281796875';
    view.Lng2 = MapSW.lng || '-43.3933598203125';
    view.MaximumPrice = price || '5000000';
    view.MinimumPrice = '0';
    view.ParkingSpots = parkingSpots || '1';
    view.PropertyType = PropertyType || 'Apartamento';
    view.Rooms = QttRooms || '2';
    view.SearchText = Location || '';

    return view;
  } catch (error) {
    throw new Error(`MapMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`MapMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (props) => {
  try {
    if (!props) return null;

    const { match } = props;
    const { params } = match;
    const {
      isSell, propertyType, price, rooms, parkingSpots,
    } = params;

    const model = { ...Model };

    model.IsRent = isSell === 'true';
    // model.ZoomLevel = model.ZoomLevel;
    model.Lat1 = model.lat1 || '-22.981604450672634';
    model.Lat2 = model.lat2 || '-23.019132940776757';
    model.Lng1 = model.lng1 || '-43.3384281796875';
    model.Lng2 = model.lng2 || '-43.3933598203125';
    model.MaximumPrice = price || '5000000';
    model.MinimumPrice = model.minimumPrice || '0';
    model.ParkingSpots = parkingSpots || '1';
    model.PropertyType = propertyType || 'Apartamento';
    model.QttRooms = rooms || '2';

    return model;
  } catch (error) {
    throw new Error(`MapMapper - ViewModelToState = ${error}`);
  }
};

export const modelToFilter = (model) => {
  try {
    if (!model) return null;

    let view = '';

    model.Filter.forEach((filter) => {
      const id = filter.id.toLowerCase();

      if (id.includes('document')) {
        view += `${id}=${encodeURIComponent(filter.value.replace(/\D/g, ''))}&`;
      } else {
        view += `${id}=${encodeURIComponent(filter.value)}&`;
      }
    });

    model.Sort.forEach((sort) => {
      const id = sort.id.toLowerCase();
      view += `sort=${id}&`;
      view += `desc=${sort.desc}&`;
    });

    view += `page=${model.Page + 1}&`;
    view += `quantity=${model.PageSize}`;

    return view;
  } catch (error) {
    throw new Error(`MapMapper - ModelToFilter = ${error}`);
  }
};
