import { axiosApiHomer, axiosInstance as axios } from '../lib/axios';
import Config from '../config/environments';
import { POST_POSTDETAILS, LIST_POSTDETAILS, GET_POSTDETAILS } from '../actionTypes/postdetails';
import { viewsModelToModels, viewModelToModel } from '../mapper/postdetails';
import updateState from './common/common';

export const getDataPostDetails = (data, state) => async (dispatch) => {
  const base = {
    type: GET_POSTDETAILS,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.POST.INSERT}/${data.postId}`;

  axiosApiHomer.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data, state);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const getDataPostDetailsWithoutPagination = () => async (dispatch) => {
  const base = {
    type: LIST_POSTDETAILS,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.POSTDETAILS.URL}?page=1&quantity=100`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewsModelToModels(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const postDataPostDetails = (data) => async (dispatch) => {
  const base = {
    type: POST_POSTDETAILS,
    payload: {},
    state: true,
    success: true,
  };

  const url = Config.POSTDETAILS.URL;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const putDataPostDetails = (data) => async (dispatch) => {
  const base = {
    type: POST_POSTDETAILS,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.POSTDETAILS.URL}/${data.uuid}`;

  axios.put(url, data).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};
