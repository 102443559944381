import { axiosInstance as axios } from '../lib/axios';
import Config from '../config/environments';
import {
  POST_GEO, LIST_GEO, GET_GEO, GET_GEO_BREADCRUMBS,
} from '../actionTypes/geo';
import { viewsModelToModels, viewModelToModel } from '../mapper/geo';
import updateState from './common/common';

export const getDataGeo = (data) => async (dispatch) => {
  const base = {
    type: GET_GEO,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.GEO.URL}/${data.GooglePlaceId || data.Id}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const getDataGeoBreadcrumbs = (data) => async (dispatch) => {
  const base = {
    type: GET_GEO_BREADCRUMBS,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.GEO.URL}/${data.GooglePlaceId || data.Id}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const getDataGeoWithoutPagination = () => async (dispatch) => {
  const base = {
    type: LIST_GEO,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.GEO.URL}?page=1&quantity=100`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewsModelToModels(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const postDataGeo = (data) => async (dispatch) => {
  const base = {
    type: POST_GEO,
    payload: {},
    state: true,
    success: true,
  };

  const url = Config.GEO.URL;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const putDataGeo = (data) => async (dispatch) => {
  const base = {
    type: POST_GEO,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.GEO.URL}/${data.uuid}`;

  axios.put(url, data).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};
