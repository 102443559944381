import { POST_POSTDETAILS, LIST_POSTDETAILS, GET_POSTDETAILS } from '../actionTypes/postdetails';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

export const INITIAL_STATE = {
  statePostPostDetails: BASE,
  listPostDetails: BASE_PAGINATION,
  getPostDetails: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_POSTDETAILS:
      return { ...state, statePostPostDetails: action.payload };
    case LIST_POSTDETAILS:
      return { ...state, listPostDetails: action.payload };
    case GET_POSTDETAILS:
      return { ...state, getPostDetails: action.payload };
    default:
      return state;
  }
};
