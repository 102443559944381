import Model from '../model/contact';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.AgentId = view.agentId;
    model.PostId = view.postId;
    model.Name = view.name;
    model.Phone = view.phone;
    model.Email = view.email;
    model.Message = view.message;
    model.SourceId = view.sourceId;

    return model;
  } catch (error) {
    throw new Error(`ContactMapper - ViewModelToModel = ${error}`);
  }
};

export const requestEnterprise = (view, data) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Phone = view.contactPhoneNumber;
    model.Message = view.message;
    model.PostId = data;

    return model;
  } catch (error) {
    throw new Error(`UserContactsMapper - requestEnterprise = ${error}`);
  }
};

export const viewsModelToModels = (models, state) => {
  try {
    if (!models) return null;
    const views = [];

    models.data.forEach(async (model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    const upState = state || {};
    const division = parseInt(models.total / upState.PageSize, 10);
    const remainder = models.total % upState.PageSize;

    const viewsPag = {
      total: division + (remainder <= 0 ? 0 : 1),
      list: views,
    };

    return viewsPag;
  } catch (error) {
    throw new Error(`ContactMapper - ViewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (model, sourceId, agent, postId) => {
  try {
    if (!model) return null;

    const view = {};

    view.SiteOwnerUserId = agent.UserId;
    view.ClientName = model.Name;
    view.ClientPhone = `+55${model.Phone.replace(/\D/g, '')}`;
    view.ClientEmail = model.Email;
    view.ClientMessage = model.Message;
    view.UserPersonalSiteMessageSourceId = sourceId;

    if (model.PostId) {
      view.PostId = model.PostId;
    } else {
      view.PostId = postId;
    }

    return view;
  } catch (error) {
    throw new Error(`ContactMapper - ModelToViewModel = ${error}`);
  }
};

export const modelToViewModelContact = (model, sourceId, agent, postId) => {
  try {
    if (!model) return null;

    const view = {};

    view.ClientMessage = model.Message;
    view.ClientName = model.Name;
    view.ClientPhone = `+55${model.Phone.replace(/\D/g, '')}`;
    view.MessageSourceId = sourceId;
    view.UserId = agent.UserId;
    view.ClientEmail = model.Email;

    if (sourceId === 5) {
      view.DevelopmentId = model.PostId || postId;
    }

    if (!view.DevelopmentId) {
      if (model.PostId) {
        view.PostId = model.PostId;
      } else {
        view.PostId = parseInt(postId, 10);
      }
    }

    return view;
  } catch (error) {
    throw new Error(`ContactMapper - ModelToViewModelContact = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`ContactMapper - ModelsToViewsModel = ${error}`);
  }
};

export const modelToVieModelMessage = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.MessageId = view.UserPersonalSiteMessageId;

    return model;
  } catch (error) {
    throw new Error(`ContactMapperEmail - ModelToViewModelMessage = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.AgentId = view.AgentId || '';
    model.PostId = view.PostId || '';
    model.Name = view.Name || '';
    model.Phone = view.Phone || '';
    model.Email = view.Email || '';
    model.Message = view.Message || '';
    model.SourceId = view.SourceId || '';

    return model;
  } catch (error) {
    throw new Error(`ContactMapper - ViewModelToState = ${error}`);
  }
};

export const modelToFilter = (model) => {
  try {
    if (!model) return null;

    let view = '';

    model.Filter.forEach((filter) => {
      const id = filter.id.toLowerCase();

      if (id.includes('document')) {
        view += `${id}=${encodeURIComponent(filter.value.replace(/\D/g, ''))}&`;
      } else {
        view += `${id}=${encodeURIComponent(filter.value)}&`;
      }
    });

    model.Sort.forEach((sort) => {
      const id = sort.id.toLowerCase();
      view += `sort=${id}&`;
      view += `desc=${sort.desc}&`;
    });

    view += `page=${model.Page + 1}&`;
    view += `quantity=${model.PageSize}`;

    return view;
  } catch (error) {
    throw new Error(`ContactMapper - ModelToFilter = ${error}`);
  }
};
