/* eslint-disable class-methods-use-this */
import React from 'react';
import { AppHeader } from '@coreui/react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container, Row, Col, Input, Label, Button, Spinner, Card,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { geolocated } from 'react-geolocated';
import { Helmet } from 'react-helmet';
import ReactAutocomplete from 'react-autocomplete';
import BaseComponent from './base';
import Config from '../../config/environments';
import { FILTER_LIST_ENTERPRISE_PERSONAL } from '../../actionTypes/filter';
import { getDataEnterprises, clearDataEnterprises } from '../../actions/enterprise';
import DefaultHeader from '../../containers/defaultHeader';
import {
  PostEnterpriseComponent, InputFilterLoadingComponent, PostEnterpriseLoadingComponent,
  OrdinationLoadingComponent,
  CardInfoComponent,
} from '../component/post';
import ModalFilterEnterprise from '../component/modal/modalFilterEnterprise';
import Model from '../../model/enterprise';
import { getDataAgent } from '../../actions/agent';
import { getDataAgentDetails } from '../../actions/agentdetails';
import { modelToViewModel as modelToViewModelAgent } from '../../mapper/agent';
import { modelToViewModel as modelToViewModelAgentDetails } from '../../mapper/agentdetails';
import {
  getDataState, getDataCity, getDataDistrict, clearDataDistrict, clearDataCity, getDataDevelopers,
} from '../../actions/filter';
import { brlCurrencies } from '../../lib/mask';
import { localSet, localGet, localRemove } from '../../lib/session';
import {
  viewModelToModelFilter, pagination, paginationToState, paginationUrl,
} from '../../mapper/filter';
import IconOrdination from '../../assets/icons/ordinationIcon.svg';
import IconFilter from '../../assets/icons/whiteFilterIcon.svg';
import IconInterrogation from '../../assets/icons/interrogation.svg';
import IconClear from '../../assets/icons/icon-close.svg';
import HomerFind from '../../assets/imgs/homerFind.svg';
import {
  enterpriseCardHomerFind, enterpriseFilterArea, enterpriseFilterCity, enterpriseFilterDeveloper,
  enterpriseFilterMaxPrice, enterpriseFilterNeightborhood, enterpriseFilterParkingSpots,
  enterpriseFilterRooms, enterpriseFilterState, enterpriseOrdination,
  enterpriseOrdinationHigherCost, enterpriseOrdinationLowerCost, enterpriseOrdinationMostRecent,
} from '../../const/mixpanel';
import Mixpanel from '../../mixpanel';

class EnterpriseList extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ...Model,
      Width: window.innerWidth,
    };
    this.handleHome = this.handleHome.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleDistrict = this.handleDistrict.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleModalFilter = this.handleModalFilter.bind(this);
    this.handleClearFilter = this.handleClearFilter.bind(this);
    this.onChangePrice = this.onChangePrice.bind(this);
    this.handleDeveloperName = this.handleDeveloperName.bind(this);
    this.handlePrice = this.handlePrice.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.handleChangeOrdination = this.handleChangeOrdination.bind(this);
    this.handleDeveloper = this.handleDeveloper.bind(this);
    this.handleRooms = this.handleRooms.bind(this);
    this.handleParkingSpots = this.handleParkingSpots.bind(this);
    this.handleArea = this.handleArea.bind(this);
    this.onChangeArea = this.onChangeArea.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleBanner = this.handleBanner.bind(this);
    this.handleClearField = this.handleClearField.bind(this);
    this.scrollerRef = React.createRef();
  }

  componentDidMount() {
    const p = this.props;

    p.clearDataEnterprises();

    window.addEventListener('resize', this.handleResize);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      RequestedProperties, RequestedState, IsLoading, NumberCardsPosts,
      Scrolled, NewsConstructions, Requested, Initializated, RequestedAgentDetails,
      RequestedAgent, UpdateDeveloperName, Developer,
    } = nextState;
    const {
      match, listEnterprise, getAgent, history, listDevelopers,
    } = nextProps;
    const { params } = match;
    const { slug, integrator } = params;
    const previousState = this.state;
    const prevProps = this.props;
    const lastCardsPosts = previousState.NumberCardsPosts;
    const info = viewModelToModelFilter(params);
    const agent = getAgent && getAgent.data;
    const currentSlug = prevProps.match.params.slug;

    if (!RequestedAgent && currentSlug === slug) {
      const state = modelToViewModelAgent(slug);
      nextProps.getDataAgent(state);
      this.setState({ RequestedAgent: true });
      return false;
    }

    if (getAgent.success === true && getAgent.data === null && !RequestedAgentDetails) {
      history.push({ pathname: '/404' });
    }

    if (agent && !RequestedAgentDetails) {
      const state = modelToViewModelAgentDetails(agent);
      nextProps.getDataAgentDetails(state);
      this.setState({ RequestedAgentDetails: true });
      return false;
    }

    if (!RequestedState) {
      nextProps.getDataState();
      this.setState({ RequestedState: true });
      return false;
    }

    if (Initializated) {
      const state = pagination(nextState);
      const newState = paginationToState(integrator || localGet(FILTER_LIST_ENTERPRISE_PERSONAL));
      const stateLocal = pagination(newState, 20, false);

      nextProps.getDataEnterprises(stateLocal || state);
      nextProps.getDataDevelopers();
      this.setState({
        Initializated: false,
        State: newState && newState.State ? newState.State : 'Todos',
        City: newState && newState.City ? newState.City : 'Todos',
        Districts: newState && newState.Districts ? newState.Districts : 'Todos',
        Developer: newState && newState.Developer ? newState.Developer : '1',
        Ordination: newState && newState.Ordination ? newState.Ordination : '-date',
        Price: newState && newState.Price ? newState.Price : '0',
        Rooms: newState && newState.Rooms ? newState.Rooms : '0',
        ParkingSpots: newState && newState.ParkingSpots ? newState.ParkingSpots : '0',
        Area: newState && newState.Area ? newState.Area : '0',
        disabledCity: !(newState && newState.State) || newState.State === 'Todos',
        disabledDistrict: !(newState && newState.City) || newState.City === 'Todos',
        PreviousState: newState && newState.State ? newState.State : 'Todos',
        PreviousCity: newState && newState.City ? newState.City : 'Todos',
        PreviousDistricts: newState && newState.Districts ? newState.Districts : 'Todos',
        PreviousDeveloper: newState && newState.Developer ? newState.Developer : '1',
        PreviousOrdination: newState && newState.Ordination ? newState.Ordination : '-date',
        PreviousPrice: newState && newState.Price ? newState.Price : '0',
        PreviousRooms: newState && newState.Rooms ? newState.Rooms : '0',
        PreviousParkingSpots: newState && newState.ParkingSpots ? newState.ParkingSpots : '0',
        PreviousArea: newState && newState.Area ? newState.Area : '0',
      }, () => {
        if (newState && newState.State && newState.State !== 'Todos') {
          nextProps.getDataCity(newState.State);
        }

        if (newState && newState.City && newState.State !== 'Todos') {
          const newInfo = viewModelToModelFilter(newState);
          nextProps.getDataDistrict(newInfo);
        }
      });
    }

    if (listDevelopers && listDevelopers.state === false && UpdateDeveloperName) {
      if (listDevelopers && listDevelopers.data && listDevelopers.data.list.length) {
        const filteredDevelopers = listDevelopers.data.list.filter(
          (dev) => dev.Id === Developer,
        )[0];

        if (filteredDevelopers) {
          this.setState({ DevelopersName: filteredDevelopers.Name, UpdateDeveloperName: false });
        }
      }
    }

    if (listEnterprise && listEnterprise.state === false && Requested) {
      if (listEnterprise && listEnterprise.data && NewsConstructions.length < NumberCardsPosts) {
        if (listEnterprise && listEnterprise.data && listEnterprise.data.list.length > 0) {
          if (JSON.stringify(prevProps.listEnterprise) !== JSON.stringify(listEnterprise)) {
            this.setState({
              NewsConstructions: [...NewsConstructions, ...listEnterprise.data.list],
              Scrolled: false,
              Requested: false,
            });
          }
        }
      }
    }

    if (NumberCardsPosts > lastCardsPosts && Scrolled && RequestedState) {
      const state = pagination(nextState);
      nextProps.clearDataEnterprises();
      nextProps.getDataEnterprises(state);
    }

    if (!RequestedProperties && !IsLoading) {
      this.setState({ RequestedProperties: true, IsLoading: true });
      if (info.State !== '' && info.State !== undefined) {
        nextProps.getDataCity(info.State);
        this.setState({ City: info.City, disabledCity: false });
        if (info.City !== '' && info.City !== undefined) {
          nextProps.getDataDistrict(info);
          this.setState({ Districts: info.Districts, disabledDistrict: false });
        }
      }
      return false;
    }

    if (listEnterprise && listEnterprise.data && listEnterprise.data.list && IsLoading) {
      this.setState({ IsLoading: false });
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ Width: window.innerWidth });
  }

  handlePrice(event, data) {
    event.preventDefault();

    this.setState({
      Price: data,
      Loading: true,
      Loaded: false,
      Requested: true,
    });
  }

  handleArea(event) {
    event.preventDefault();

    this.setState({
      Loading: true,
      Loaded: false,
      Requested: true,
    });
  }

  onChangeArea(event, value) {
    event.preventDefault();

    this.setState({ Area: parseInt(value, 10) });
    Mixpanel.trackEvent(enterpriseFilterArea());
  }

  handleState(event, data) {
    if (event) {
      event.preventDefault();
    }

    const p = this.props;

    Mixpanel.trackEvent(enterpriseFilterState());

    p.clearDataDistrict();

    this.setState({
      State: data,
      City: 'Todos',
      Districts: 'Todos',
      disabledDistrict: true,
      Loading: true,
      Loaded: false,
      disabledCity: data === 'Todos',
      IsLoading: true,
      Requested: true,
    }, () => {
      if (data !== 'Todos') {
        p.getDataCity(data);
      }
    });

    if (data === 'Todos') {
      this.setState({ disabledCity: true, disabledDistrict: true });
    }
  }

  handleScroll(event) {
    event.preventDefault();
    const scroller = this.scrollerRef.current;
    const { listEnterprise } = this.props;
    const {
      NumberCardsPosts, Scrolled,
    } = this.state;
    const totalPosts = listEnterprise && listEnterprise.data
      && listEnterprise.data.total;
    const countActivePost = totalPosts - NumberCardsPosts;

    if ((scroller.scrollHeight - scroller.scrollTop) - 60 <= scroller.clientHeight && !Scrolled) {
      if (totalPosts > NumberCardsPosts) {
        this.setState({
          NumberCardsPosts: (countActivePost > 20
            ? (NumberCardsPosts + 20) : (NumberCardsPosts + countActivePost)),
          Scrolled: true,
          Requested: true,
        });
      }
    }
  }

  handleCity(event, data) {
    if (event) {
      event.preventDefault();
    }

    const p = this.props;

    Mixpanel.trackEvent(enterpriseFilterCity());

    this.setState({
      City: data,
      Districts: 'Todos',
      disabledDistrict: data === 'Todos',
      Loading: true,
      Loaded: false,
      Requested: true,
    }, () => {
      const info = viewModelToModelFilter(this.state);
      if (data !== 'Todos') {
        p.getDataDistrict(info);
      }
    });

    if (data === 'Todos') {
      this.setState({ disabledDistrict: true });
    }
  }

  handleChangeOrdination(event, data) {
    event.preventDefault();

    const p = this.props;
    const { IsOpenFilter } = this.state;
    if (!IsOpenFilter) {
      p.clearDataEnterprises();
    }

    Mixpanel.trackEvent(enterpriseOrdination());

    this.setState({
      Ordination: data,
    }, () => {
      if (!IsOpenFilter) {
        const url = paginationUrl(this.state);

        let path = window.location.pathname;
        const pathSplited = window.location.pathname.split('/');

        if (pathSplited && pathSplited.length === 4
          && pathSplited[pathSplited.length - 1]) {
          path = path.replace(pathSplited[pathSplited.length - 1], url);
        }

        window.history.pushState({}, 'Integrations', pathSplited.length === 4 && pathSplited[pathSplited.length - 1] ? path : `${window.location.pathname}${pathSplited[pathSplited.length - 1] ? '/' : ''}${url}`);
      }
    });
  }

  handleHome(event) {
    event.preventDefault();

    const { match, history } = this.props;
    const { params } = match;
    const { slug } = params;

    history.push(`/${slug}`);
  }

  handleModalFilter(event, isOrdination) {
    event.preventDefault();

    const p = this.props;
    const {
      IsOpenFilter, PreviousState, PreviousPrice, PreviousCity, PreviousDistricts,
      PreviousOrdination, State, City, PreviousDeveloper, PreviousRooms,
      PreviousParkingSpots, PreviousArea,
    } = this.state;

    this.setState({
      IsOpenFilter: !IsOpenFilter,
      IsOrdination: isOrdination,
    });

    if (IsOpenFilter) {
      this.setState({
        State: PreviousState,
        Price: PreviousPrice,
        City: PreviousCity,
        Districts: PreviousDistricts,
        Developer: PreviousDeveloper,
        Ordination: PreviousOrdination,
        Rooms: PreviousRooms,
        ParkingSpots: PreviousParkingSpots,
        Area: PreviousArea,
      });

      if (State && State !== 'Todos' && !PreviousState) {
        this.setState({
          disabledCity: true,
        });

        p.clearDataCity();
      }

      if (City && City !== 'Todos' && !PreviousCity) {
        this.setState({
          disabledDistrict: true,
        });

        p.clearDataDistrict();
      }
    }
  }

  handleClearFilter(event) {
    event.preventDefault();

    const p = this.props;
    const { match } = this.props;
    const { params } = match;
    const { slug } = params;

    window.history.pushState({}, 'Integrations', `${window.location.origin}/${slug}/empreendimentos`);

    this.setState({
      State: 'Todos',
      Price: '0',
      City: 'Todos',
      Districts: 'Todos',
      Developer: '1',
      DevelopersName: 'Todos',
      Rooms: '0',
      ParkingSpots: '0',
      Area: '0',
      PreviousState: 'Todos',
      PreviousPrice: '0',
      PreviousCity: 'Todos',
      PreviousDistricts: 'Todos',
      PreviousDeveloper: '1',
      PreviousRooms: '0',
      PreviousParkingSpots: '0',
      PreviousArea: '0',
      disabledDistrict: true,
      disabledCity: true,
      Requested: true,
      NewsConstructions: [],
      NumberCardsPosts: 20,
    }, () => {
      const state = pagination(this.state, 20);

      p.getDataEnterprises(state);
    });

    localRemove(FILTER_LIST_ENTERPRISE_PERSONAL);
  }

  handleFilterSubmit(event) {
    event.preventDefault();

    document.getElementById('TotalEnterprisesCount').scrollIntoView({ behavior: 'smooth' });

    const p = this.props;
    const {
      Price, State, City, Districts, Ordination, Developer, Rooms,
      ParkingSpots, Area,
    } = this.state;
    const state = pagination(this.state, 20);
    const url = paginationUrl(this.state);
    p.clearDataEnterprises();

    let path = window.location.pathname;
    const pathSplited = window.location.pathname.split('/');

    if (pathSplited && pathSplited.length === 4
      && pathSplited[pathSplited.length - 1]) {
      path = path.replace(pathSplited[pathSplited.length - 1], url);
    }

    window.history.pushState({}, 'Integrations', pathSplited.length === 4 && pathSplited[pathSplited.length - 1] ? path : `${window.location.pathname}${pathSplited[pathSplited.length - 1] ? '/' : ''}${url}`);

    localSet(FILTER_LIST_ENTERPRISE_PERSONAL, state, Config.EXPIRATION_AUTH);
    p.getDataEnterprises(state);
    this.setState({
      IsOpenFilter: false,
      NumberCardsPosts: 20,
      NewsConstructions: [],
      Requested: true,
      PreviousPrice: Price,
      PreviousState: State,
      PreviousCity: City,
      PreviousDistricts: Districts,
      PreviousDeveloper: Developer,
      PreviousOrdination: Ordination,
      PreviousRooms: Rooms,
      PreviousParkingSpots: ParkingSpots,
      PreviousArea: Area,
    });
  }

  handleDistrict(event, data) {
    if (event) {
      event.preventDefault();
    }

    Mixpanel.trackEvent(enterpriseFilterNeightborhood());

    this.setState({
      Districts: data,
      Requested: true,
    });
  }

  handleDeveloper(event, value, item) {
    if (event) {
      event.preventDefault();
    }

    Mixpanel.trackEvent(enterpriseFilterDeveloper());

    this.setState({
      Developer: value,
      DevelopersName: item.Name,
      Requested: true,
    });
  }

  handleRooms(event) {
    if (event) {
      event.preventDefault();
    }

    Mixpanel.trackEvent(enterpriseFilterRooms());

    this.setState({
      Rooms: event.target.value,
      Requested: true,
    });
  }

  handleParkingSpots(event) {
    if (event) {
      event.preventDefault();
    }

    Mixpanel.trackEvent(enterpriseFilterParkingSpots());

    this.setState({
      ParkingSpots: event.target.value,
      Requested: true,
    });
  }

  onChangePrice(event, value) {
    event.preventDefault();

    this.setState({ Price: value });
    Mixpanel.trackEvent(enterpriseFilterMaxPrice());
  }

  handleDeveloperName(event) {
    event.preventDefault();

    this.setState({ DevelopersName: event.target.value });
    Mixpanel.trackEvent(enterpriseFilterDeveloper());
  }

  handleClearField(event, field) {
    event.preventDefault();

    this.setState({ [field]: '' });
  }

  handleBanner(event) {
    event.preventDefault();

    const url = `${Config.FORM_JOT.URL}${Config.FORM_JOT.HOMER_FIND}`;

    Mixpanel.trackEvent(enterpriseCardHomerFind());

    window.open(url, '_self');
  }

  render() {
    const p = this.props;
    const {
      listEnterprise, listState, listCity, listDistrict, match, listDevelopers,
    } = this.props;
    const { params } = match;
    const { slug } = params;
    const {
      Width, IsOpenFilter, State, disabledCity, disabledDistrict, City,
      Districts, Price, Ordination, IsOrdination, NewsConstructions, Scrolled,
      IsViewerOpen, Developer, Area, Rooms, ParkingSpots, Quantities, DevelopersName,
    } = this.state;

    const states = (listState && listState.data && listState.data.list) || [];
    const isHomer = Config.SECURITY.SELETO === slug || false;
    const cities = (listCity && listCity.data && listCity.data.list) || [];
    const districts = (listDistrict && listDistrict.data && listDistrict.data.list) || [];
    const developers = (listDevelopers && listDevelopers.data && listDevelopers.data.list) || [];

    const isMobile = Width <= 999;
    const ordination = [{ title: 'Mais Recentes', value: '-date' }, { title: 'Menor Custo', value: '+price' }, { title: 'Maior Custo', value: '-price' }];

    return (
      <>
        {IsOpenFilter && (
          <ModalFilterEnterprise
            isOpen={IsOpenFilter}
            toggle={this.handleModalFilter}
            filters={{
              states,
              State,
              disabledCity,
              cities,
              City,
              disabledDistrict,
              districts,
              Districts,
              developers,
              Developer,
              Price,
              Ordination,
              IsOrdination,
              Rooms,
              ParkingSpots,
              Area,
              DevelopersName,
            }}
            Quantities={Quantities}
            listState={listState}
            listCity={listCity}
            listDistrict={listDistrict}
            listDevelopers={listDevelopers}
            handleState={this.handleState}
            handleCity={this.handleCity}
            handleDistrict={this.handleDistrict}
            handleDeveloper={this.handleDeveloper}
            handlePrice={this.handlePrice}
            onChangePrice={this.onChangePrice}
            handleFilterSubmit={this.handleFilterSubmit}
            handleChangeOrdination={this.handleChangeOrdination}
            handleRooms={this.handleRooms}
            handleParkingSpots={this.handleParkingSpots}
            handleArea={this.handleArea}
            onChangeArea={this.onChangeArea}
            handleDeveloperName={this.handleDeveloperName}
            handleClearField={this.handleClearField}
          />
        )}
        {isMobile && !IsViewerOpen ? (
          <Row className="container-filter-enterprise">
            <Col className="align-self-center">
              {State !== 'Todos' || City !== 'Todos' || Districts !== 'Todos' || Price !== '0' || Developer !== '1' || Rooms !== '0' || ParkingSpots !== '0' || Area !== '0' ? (
                <Button type="button" className="btn_web_primary" onClick={this.handleClearFilter}>
                  <img loading="lazy" src={IconClear} alt="Ícone ordenação" className="mr-2" style={{ width: 14 }} />
                  Limpar
                </Button>
              ) : (
                <Button type="button" className="btn_web_primary" onClick={(e) => this.handleModalFilter(e, false)}>
                  <img loading="lazy" src={IconFilter} alt="Ícone ordenação" className="mr-2" />
                  Filtrar
                </Button>
              )}
            </Col>
            <Col className="align-self-center">
              <Button type="button" className="btn_web_secondary" onClick={(e) => this.handleModalFilter(e, true)}>
                <img loading="lazy" src={IconOrdination} alt="Ícone ordenação" className="mr-3" />
                Ordenar
              </Button>
            </Col>
          </Row>
        ) : (<></>)}
        <div className="app new-constructions">
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
          </Helmet>
          {!isMobile ? (
            <AppHeader className={isMobile ? 'header-mobile' : 'header'}>
              <DefaultHeader
                isMobile={isMobile}
                isSeleto={isHomer}
                isHome
                isEnterprise
              />
            </AppHeader>
          ) : (<></>)}
          <div
            className="app-new-construction"
            ref={this.scrollerRef}
            onScroll={this.handleScroll}
          >
            <Row className="container-page">
              {!isMobile && (
                <Col xs={2} className="side-filter">
                  <h2>Filtros</h2>
                  <Row className="row-input-filter">
                    <Col>
                      {states && listState.state === false ? (
                        <>
                          <ReactAutocomplete
                            inputProps={{
                              onClick: (e) => this.handleClearField(e, 'State'),
                              className: 'input-autocomplete',
                            }}
                            items={states}
                            name="States"
                            value={State}
                            id="States"
                            getItemValue={(item) => item.Name}
                            onChange={(e) => {
                              this.setState({ State: e.target.value });
                              Mixpanel.trackEvent(enterpriseFilterState());
                            }}
                            onSelect={(value) => { this.handleState(null, value); }}
                            shouldItemRender={(state, value) => state.Name.toLowerCase()
                              .indexOf(value.toLowerCase()) !== -1}
                            renderItem={(item, isHighlighted) => (
                              <div
                                key={item.Name}
                                style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                                className="autocomplete"
                                id={`States-${item.Name}`}
                                name={`States-${item.Name}`}
                                data-testid={`States-${item.Name}`}
                              >
                                {item.Name}
                              </div>
                            )}
                            renderMenu={(items, value, style) => (
                              <div className="container-properties" style={{ ...style }}>
                                {items.map((i) => i)}
                              </div>
                            )}
                          />
                          <Label
                            htmlFor="PropertyType"
                            className="placeholder-input"
                            id="LabelImmobileType"
                            name="LabelImmobileType"
                            data-testid="LabelImmobileType"
                          >
                            Estado
                          </Label>
                        </>
                      ) : (
                        <InputFilterLoadingComponent sideFilter />
                      )}
                    </Col>
                  </Row>
                  <Row className="row-input-filter">
                    <Col>
                      {cities && listCity.state === false ? (
                        <>
                          <ReactAutocomplete
                            inputProps={{
                              disabled: disabledCity,
                              onClick: (e) => this.handleClearField(e, 'City'),
                              className: 'input-autocomplete',
                            }}
                            items={cities}
                            name="City"
                            value={City}
                            id="City"
                            getItemValue={(item) => item.Name}
                            onChange={(e) => {
                              this.setState({ City: e.target.value });
                              Mixpanel.trackEvent(enterpriseFilterCity());
                            }}
                            onSelect={(value) => { this.handleCity(null, value); }}
                            shouldItemRender={(state, value) => state.Name.toLowerCase()
                              .indexOf(value.toLowerCase()) !== -1}
                            renderItem={(item, isHighlighted) => (
                              <div
                                key={item.Name}
                                style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                                className="autocomplete"
                                id={`City-${item.Name}`}
                                name={`City-${item.Name}`}
                                data-testid={`City-${item.Name}`}
                              >
                                {item.Name}
                              </div>
                            )}
                            renderMenu={(items, value, style) => (
                              <div className="container-properties" style={{ ...style }}>
                                {items.map((i) => i)}
                              </div>
                            )}
                          />
                          <Label
                            htmlFor="PropertyType"
                            className="placeholder-input"
                            id="LabelImmobileType"
                            name="LabelImmobileType"
                            data-testid="LabelImmobileType"
                          >
                            Cidade
                          </Label>
                        </>
                      ) : (
                        <InputFilterLoadingComponent sideFilter />
                      )}
                    </Col>
                  </Row>
                  <Row className="row-input-filter">
                    <Col>
                      {districts && listDistrict.state === false ? (
                        <>
                          <ReactAutocomplete
                            inputProps={{
                              disabled: disabledDistrict,
                              onClick: (e) => this.handleClearField(e, 'Districts'),
                              className: 'input-autocomplete',
                            }}
                            items={districts}
                            name="District"
                            value={Districts}
                            id="District"
                            getItemValue={(item) => item.Name}
                            onChange={(e) => {
                              this.setState({ Districts: e.target.value });
                              Mixpanel.trackEvent(enterpriseFilterNeightborhood());
                            }}
                            onSelect={(value) => { this.handleDistrict(null, value); }}
                            shouldItemRender={(state, value) => state.Name.toLowerCase()
                              .indexOf(value.toLowerCase()) !== -1}
                            renderItem={(item, isHighlighted) => (
                              <div
                                key={item.Name}
                                style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                                className="autocomplete"
                                id={`Districts-${item.Name}`}
                                name={`Districts-${item.Name}`}
                                data-testid={`Districts-${item.Name}`}
                              >
                                {item.Name}
                              </div>
                            )}
                            renderMenu={(items, value, style) => (
                              <div className="container-properties" style={{ ...style }}>
                                {items.map((i) => i)}
                              </div>
                            )}
                          />
                          <Label
                            htmlFor="PropertyType"
                            className="placeholder-input"
                            id="LabelImmobileType"
                            name="LabelImmobileType"
                            data-testid="LabelImmobileType"
                          >
                            Bairro
                          </Label>
                        </>
                      ) : (
                        <InputFilterLoadingComponent sideFilter />
                      )}
                    </Col>
                  </Row>
                  <Row className="row-input-filter">
                    <Col>
                      {developers && listDevelopers.state === false ? (
                        <>
                          <ReactAutocomplete
                            inputProps={{
                              onClick: (e) => this.handleClearField(e, 'DevelopersName'),
                              className: 'input-autocomplete',
                            }}
                            items={developers}
                            name="Developers"
                            value={DevelopersName}
                            id="Developers"
                            getItemValue={(item) => item.Id}
                            onChange={(e) => {
                              this.setState({ DevelopersName: e.target.value });
                              Mixpanel.trackEvent(enterpriseFilterDeveloper());
                            }}
                            onSelect={(value, item) => {
                              this.handleDeveloper(null, value, item);
                            }}
                            shouldItemRender={(state, value) => state.Name.toLowerCase()
                              .indexOf(value.toLowerCase()) !== -1}
                            renderItem={(item, isHighlighted) => (
                              <div
                                key={item.Id}
                                style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                                className="autocomplete"
                                id={`Developers-${item.Id}`}
                                name={`Developers-${item.Id}`}
                                data-testid={`Developers-${item.Id}`}
                              >
                                {item.Name}
                              </div>
                            )}
                            renderMenu={(items, value, style) => (
                              <div className="container-properties" style={{ ...style }}>
                                {items.map((i) => i)}
                              </div>
                            )}
                          />
                          <Label
                            htmlFor="Developers"
                            className="placeholder-input"
                            id="LabelDevelopers"
                            name="LabelDevelopers"
                            data-testid="LabelDevelopers"
                          >
                            Incorporadora
                          </Label>
                        </>
                      ) : (
                        <InputFilterLoadingComponent sideFilter />
                      )}
                    </Col>
                  </Row>
                  <Row className="row-input-filter">
                    <Col>
                      <span
                        className="placeholder-input"
                        id="LabelPrice"
                        name="LabelPrice"
                        data-testid="LabelPrice"
                      >
                        Preço max.
                      </span>
                      <Input
                        className="price"
                        type="text"
                        required="required"
                        value={brlCurrencies(Price || 0)}
                        id="Price"
                        name="Price"
                        data-testid="InputPrice"
                        onBlur={(e) => this.handlePrice(e, e.target.value)}
                        onChange={(e) => this.onChangePrice(e, e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="row-input-filter">
                    <Col>
                      <Input
                        type="select"
                        id="Rooms"
                        name="Rooms"
                        value={Rooms}
                        className="state"
                        onChange={(e) => this.handleRooms(e, e.target.value)}
                      >
                        {Quantities.map((value) => (
                          <option value={value} key={`OptionRooms-${value}`}>{`${value}+`}</option>
                        ))}
                      </Input>
                      <Label
                        htmlFor="Rooms"
                        className="placeholder-input"
                        id="LabelRooms"
                        name="LabelRooms"
                        data-testid="LabeRooms"
                      >
                        Quartos
                      </Label>
                    </Col>
                  </Row>
                  <Row className="row-input-filter">
                    <Col>
                      <Input
                        type="select"
                        id="ParkingSpots"
                        name="ParkingSpots"
                        value={ParkingSpots}
                        className="city"
                        onChange={(e) => this.handleParkingSpots(e, e.target.value)}
                      >
                        {Quantities.map((value) => (
                          <option value={value} key={`OptionParkingSpots-${value}`}>{`${value}+`}</option>
                        ))}
                      </Input>
                      <Label
                        htmlFor="ParkingSpots"
                        className="placeholder-input"
                        id="LabelParkingSpots"
                        name="LabelParkingSpots"
                        data-testid="LabelParkingSpots"
                      >
                        Vagas
                      </Label>
                    </Col>
                  </Row>
                  <Row className="row-input-filter">
                    <Col>
                      <Input
                        className="price"
                        type="text"
                        required="required"
                        value={brlCurrencies(Area || 0)}
                        id="Area"
                        name="Area"
                        data-testid="Area"
                        onBlur={this.handleArea}
                        onChange={(e) => this.onChangeArea(e, e.target.value)}
                      />
                      <Label
                        htmlFor="Area"
                        className="placeholder-input"
                        id="LabelArea"
                        name="LabelArea"
                        data-testid="LabelArea"
                      >
                        Área
                      </Label>
                    </Col>
                  </Row>
                  <Row className="row-button-apply">
                    <Col>
                      <Button
                        type="button"
                        id="ApplyFilters"
                        name="ApplyFilters"
                        data-testid="ApplyFilters"
                        className="btn_web_primary"
                        onClick={this.handleFilterSubmit}
                      >
                        Aplicar Filtros
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col xs={isMobile ? 12 : 10} className="container-real-estates">
                <Container>
                  {!isMobile ? (
                    <>
                      <Row className="container-title-breadcrumbs">
                        <Col lg={12}>
                          <span
                            className="last-title"
                            onClick={this.handleHome}
                            onKeyUp={this.handleHome}
                            role="button"
                            tabIndex={0}
                            id="MyPublicationsBreadcrumbs"
                            name="MyPublicationsBreadcrumbs"
                            data-testid="MyPublicationsBreadcrumbs"
                          >
                            Início
                          </span>
                          <span className="space">{' > '}</span>
                          <span className="first-title">Imóveis de incorporadoras</span>
                        </Col>
                      </Row>
                      {listEnterprise && listEnterprise.data && listEnterprise.data.total
                        ? (
                          <Row className="row-ordination">
                            <Col>
                              <h1 id="TotalEnterprisesCount" name="TotalEnterprisesCount">
                                {`${listEnterprise.data.total} Empreendimentos encontrados`}
                              </h1>
                            </Col>
                            <Col className="col-autocomplete">
                              <ReactAutocomplete
                                inputProps={{
                                  required: 'required',
                                  onClick: () => {
                                    Mixpanel.trackEvent(enterpriseOrdination());
                                  },
                                }}
                                items={ordination}
                                name="OrdinationType"
                                value={ordination.filter((x) => x.value === Ordination)[0].title}
                                id="OrdinationType"
                                getItemValue={(item) => item.value}
                                onSelect={(value) => {
                                  p.clearDataEnterprises();

                                  switch (value) {
                                    case '-date':
                                      Mixpanel.trackEvent(enterpriseOrdinationMostRecent());
                                      break;
                                    case '+price':
                                      Mixpanel.trackEvent(enterpriseOrdinationLowerCost());
                                      break;
                                    case '-price':
                                      Mixpanel.trackEvent(enterpriseOrdinationHigherCost());
                                      break;
                                    default:
                                      Mixpanel.trackEvent(enterpriseOrdinationMostRecent());
                                      break;
                                  }

                                  this.setState({
                                    Ordination: value,
                                    NewsConstructions: [],
                                    Requested: true,
                                    NumberCardsPosts: 20,
                                  }, () => {
                                    const state = pagination(this.state, 20);
                                    const url = paginationUrl(this.state);
                                    let path = window.location.pathname;
                                    const pathSplited = window.location.pathname.split('/');

                                    if (pathSplited && pathSplited.length === 4
                                      && pathSplited[pathSplited.length - 1]) {
                                      path = path.replace(pathSplited[pathSplited.length - 1], url);
                                    }

                                    window.history.pushState({}, 'Integrations', pathSplited.length === 4 && pathSplited[pathSplited.length - 1] ? path : `${window.location.pathname}${pathSplited[pathSplited.length - 1] ? '/' : ''}${url}`);

                                    localSet(FILTER_LIST_ENTERPRISE_PERSONAL,
                                      state, Config.EXPIRATION_AUTH);
                                    p.getDataEnterprises(state);
                                  });
                                }}
                                renderItem={(item, isHighlighted) => (
                                  <div
                                    key={item.Id}
                                    style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                                    className="autocomplete"
                                    id={`OrdinationType-${item.value}`}
                                    name={`OrdinationType-${item.value}`}
                                    data-testid={`OrdinationType-${item.value}`}
                                  >
                                    {item.title}
                                  </div>
                                )}
                                renderMenu={(items, value, style) => (
                                  <div className="container-properties" style={{ ...style }}>
                                    {items.map((i) => i)}
                                  </div>
                                )}
                              />
                              <Label
                                htmlFor="PropertyType"
                                className="placeholder-input"
                                id="LabelOrdination"
                                name="LabelOrdination"
                                data-testid="LabelOrdination"
                              >
                                Ordenação
                              </Label>
                            </Col>
                          </Row>
                        ) : (
                          <>
                            {!(listEnterprise && listEnterprise.state === false
                              && listEnterprise.success === true) && !isMobile && (
                                <OrdinationLoadingComponent />
                            )}
                          </>
                        )}
                    </>
                  ) : (
                    <></>
                  )}
                  {listEnterprise && listEnterprise.data && listEnterprise.data.list
                    && isMobile ? (
                      <Row>
                        <Col>
                          <h1 className="total-enterprises" id="TotalEnterprisesCount" name="TotalEnterprisesCount">
                            {`${listEnterprise.data.total} Empreendimentos encontrados`}
                            <img loading="lazy" src={IconInterrogation} alt="Ícone de interrogação" />
                          </h1>
                        </Col>
                      </Row>
                    ) : (<></>)}
                  <div className="page-post">
                    <div className="tab-pane active">
                      <Row className="container-cards">
                        {NewsConstructions.length > 0 ? NewsConstructions.slice(0, 3).map(
                          (myPost, idx, array) => {
                            const lastPost = array[array.length - 1];
                            return (
                              <>
                                <PostEnterpriseComponent
                                  isMobile={isMobile}
                                  key={myPost.PostId}
                                  post={myPost}
                                  isHomer={isHomer}
                                  isEnterprise
                                />
                                {lastPost.PostId === myPost.PostId && (
                                  <CardInfoComponent isMobile={isMobile} />
                                )}
                              </>
                            );
                          },
                        ) : (
                          <>
                            {listEnterprise && listEnterprise.state === false
                              && listEnterprise.success === true ? (
                                <Col className="none-posts">
                                  <Row>
                                    <Col>
                                      <h4>
                                        Neste momento, não temos empreendimentos nesta região
                                        com o perfil que está procurando
                                      </h4>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <p>
                                        Por favor verifique os filtros escolhidos e tente novamente.
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                              ) : (
                                <>
                                  <Col className={`d-flex justify-content-center ${isMobile ? '' : 'p-0'}`} xs={isMobile ? 12 : 3}>
                                    <PostEnterpriseLoadingComponent isMobile={isMobile} />
                                  </Col>
                                  <Col className={`d-flex justify-content-center ${isMobile ? '' : 'p-0'}`} xs={isMobile ? 12 : 3}>
                                    <PostEnterpriseLoadingComponent isMobile={isMobile} />
                                  </Col>
                                  <Col className={`d-flex justify-content-center ${isMobile ? '' : 'p-0'}`} xs={isMobile ? 12 : 3}>
                                    <PostEnterpriseLoadingComponent isMobile={isMobile} />
                                  </Col>
                                  <Col className={`d-flex justify-content-center ${isMobile ? '' : 'p-0'}`} xs={isMobile ? 12 : 3}>
                                    <PostEnterpriseLoadingComponent isMobile={isMobile} />
                                  </Col>
                                </>
                              )}
                          </>
                        )}
                      </Row>
                      {!isMobile && isHomer ? (
                        <Row className="banner">
                          <Col lg={12} className="banner-img">
                            <Button className="banner-section" onClick={(e) => { this.handleBanner(e); }}>
                              <Card>
                                <Row>
                                  <Col xs={7} className="d-flex flex-column justify-content-center pr-0">
                                    <h4>
                                      Não encontrou o que procurava?
                                    </h4>
                                    <h4>
                                      O time do
                                      {' '}
                                      <strong>Homer Encontra</strong>
                                      {' '}
                                      pra você!
                                    </h4>
                                    <div className="btn_web_primary">
                                      {'> Enviar pedido'}
                                    </div>
                                  </Col>
                                  <Col xs={5} className="justify-content-center d-flex pl-0">
                                    <img src={HomerFind} alt="Logo Homer Encontra" className="logo" />
                                  </Col>
                                </Row>
                              </Card>
                            </Button>
                          </Col>
                        </Row>
                      ) : (<></>)}
                      <Row className="container-cards">
                        {NewsConstructions.length > 0
                          && NewsConstructions.slice(4, 9999).map((myPost) => (
                            <PostEnterpriseComponent
                              isMobile={isMobile}
                              key={myPost.PostId}
                              post={myPost}
                              isHomer={isHomer}
                              isEnterprise
                            />
                          ))}
                      </Row>
                      {Scrolled === true && (
                        <Row className="row-loading">
                          <Col>
                            <Spinner
                              id="SpinnerLoadingMyPublications"
                              name="SpinnerLoadingMyPublications"
                              data-testid="SpinnerLoadingMyPublications"
                            />
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                </Container>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

EnterpriseList.defaultProps = {
  coords: {},
};

EnterpriseList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
      postId: PropTypes.string,
      postType: PropTypes.string,
      userId: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  listPropertyTypes: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getDataState: PropTypes.func.isRequired,
  coords: PropTypes.shape({ latitude: PropTypes.number, longitude: PropTypes.number }),
  getDataEnterprises: PropTypes.func.isRequired,
  clearDataEnterprises: PropTypes.func.isRequired,
  clearDataDistrict: PropTypes.func.isRequired,
  clearDataCity: PropTypes.func.isRequired,
  getDataCity: PropTypes.func.isRequired,
  getDataDistrict: PropTypes.func.isRequired,
  listState: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listCity: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listDistrict: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listEnterprise: PropTypes.shape({
    data: PropTypes.shape({
      list: PropTypes.arrayOf(PropTypes.object),
      total: PropTypes.number,
    }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getDataAgent: PropTypes.func.isRequired,
  getAgent: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getDataAgentDetails: PropTypes.func.isRequired,
  getAgentDetails: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getDataDevelopers: PropTypes.func.isRequired,
  listDevelopers: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getDataEnterprises,
    clearDataEnterprises,
    getDataState,
    getDataCity,
    getDataDistrict,
    clearDataDistrict,
    clearDataCity,
    getDataAgent,
    getDataAgentDetails,
    getDataDevelopers,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  listPropertyTypes: state.propertytypesState.listPropertyTypes,
  listEnterprise: state.enterpriseState.listEnterprise,
  getGeo: state.geoState.getGeo,
  listState: state.filterState.listState,
  listCity: state.filterState.listCity,
  listDistrict: state.filterState.listDistrict,
  listNewConstruction: state.filterState.listNewConstruction,
  getAgent: state.agentState.getAgent,
  getAgentDetails: state.agentdetailsState.getAgentDetails,
  listDevelopers: state.filterState.listDevelopers,
});

const EnterpriseListGeoloc = geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 10000,
})(EnterpriseList);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnterpriseListGeoloc);
