import { axiosApiHomer as axios } from '../lib/axios';
import Config from '../config/environments';
import { GET_ENTERPRISE_DETAILS, LIST_ENTERPRISE } from '../actionTypes/enterprise';
import { viewModelToModel, viewsModelToModels } from '../mapper/enterprise';
import updateState from './common/common';

export const getDataEnterpriseDetail = (postId) => async (dispatch) => {
  const base = {
    type: GET_ENTERPRISE_DETAILS,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.POST.DEVELOPMENTS}/${postId}`;

  return axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });
};

export const getDataEnterprises = (filter) => async (dispatch) => {
  const base = {
    type: LIST_ENTERPRISE,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.POST.DEVELOPMENTS}/q${filter}`;

  return axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewsModelToModels(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });
};

export const clearDataEnterprises = () => async (dispatch) => {
  const base = {
    type: LIST_ENTERPRISE,
    payload: {
      data: null,
    },
    state: false,
  };

  dispatch(base);
};
