export default {
  AgentId: '',
  ClusterLat1: '',
  clusterLat2: '',
  clusterLng1: '',
  googlePlaceName: '',
  isRent: '',
  lat1: '',
  lat2: '',
  lng1: '',
  lng2: '',
  maximumPrice: '',
  minimumPrice: '',
  parkingSpots: '',
  propertyType: '',
  rooms: '',
  zoomLevel: '',
  IsHomerSell: false,
};
