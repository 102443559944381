export default {
  BACK: ' voltar',
  SEARCH: 'Buscar',
  NONE: 'Nenhum imóvel encontrado nessa região',
  ONE: ' imóvel encontrado nessa região',
  A_LOT: ' imóveis encontrados nessa região',
  REVEALING: 'Mais Relevantes',
  RECENT: 'Mais Recentes',
  LOWEST_COST: 'Menor custo',
  MORE_COST: 'Maior custo',
  LOAD_MORE: 'Carregar mais Imóveis',
  MAP: 'Mapa',
  LIST: 'Lista',
};
