import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Input,
  Label,
  FormGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Model from '../../../model/contact';
import { PhoneCompleteMask } from '../../../lib/mask';
import { postDataContactNoPost } from '../../../actions/contact';
import { modelToViewModel } from '../../../mapper/contact';
import { getSourceIdBasedOnPage } from '../../../lib/utils';
import ContactConst from '../../../const/contact';
import Mixpanel from '../../../mixpanel';

class Contact extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { ...Model };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    this.setState({ Requested: false });
  }

  handleSubmit(event) {
    event.preventDefault();

    const p = this.props;
    const { location, getAgent } = this.props;
    const { pathname } = location;
    const agent = getAgent && getAgent.data;
    const sourceId = getSourceIdBasedOnPage(pathname);
    const state = modelToViewModel(this.state, sourceId, agent);
    p.postDataContactNoPost(state);
    this.setState({ Requested: true });
  }

  render() {
    const {
      isSeleto, isMobile, agentDetails, statePostContact, location, getAgent,
    } = this.props;
    const {
      Name, Phone, Email, Message, Requested,
    } = this.state;

    if (!(!isMobile && !isSeleto)) {
      return null;
    }
    const agent = getAgent && getAgent.data;
    const { pathname } = location;
    const sourceId = getSourceIdBasedOnPage(pathname);
    const message = (statePostContact && statePostContact.data) || {};
    if (statePostContact && statePostContact.state === false && Requested) {
      if (statePostContact.data) {
        toast.success('Sua mensagem foi enviada! Entraremos em contato o mais breve possível.');
        Mixpanel.track('form_submit', {
          local_message_id: sourceId,
          post_id: '',
          user_id: agent.UserId,
          site_message_id: message.MessageId,
        });
      } else {
        toast.error('Ocorreu um erro! :(');
      }
    }

    return (
      <Container className="body">
        <Form onSubmit={this.handleSubmit}>
          <Row className="justify-content-center align-center">
            <Col md={7}>
              <h4>
                {ContactConst.HELP}
                {agentDetails.FullName}
                ?
              </h4>
            </Col>
          </Row>
          <Row className="justify-content-center align-center">
            <Col md={3}>
              <h5>{ContactConst.MESSAGE}</h5>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={4}>
              <FormGroup>
                <Label htmlFor="Name">{ContactConst.NAME}</Label>
                <Input
                  type="text"
                  required="required"
                  maxLength="100"
                  minLength="3"
                  id="Name"
                  name="Name"
                  value={Name}
                  onChange={(e) => this.setState({ Name: e.target.value.trimStart() })}
                />
                <Label htmlFor="Phone">{ContactConst.TELEPHONE}</Label>
                <Input
                  type="text"
                  required="required"
                  minLength="15"
                  maxLength="15"
                  id="Phone"
                  name="Phone"
                  value={Phone}
                  onChange={(e) => this.setState({
                    Phone:
                    PhoneCompleteMask(e.target.value.trimStart()),
                  })}
                />
                <Label htmlFor="Email">{ContactConst.EMAIL}</Label>
                <Input
                  type="email"
                  required="required"
                  maxLength="100"
                  minLength="3"
                  id="Email"
                  name="Email"
                  value={Email}
                  onChange={(e) => this.setState({ Email: e.target.value.trimStart() })}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label htmlFor="Message">{ContactConst.MESSAGE_LABEL}</Label>
                <Input
                  type="textarea"
                  style={{ resize: 'none' }}
                  required="required"
                  maxLength="100"
                  id="Message"
                  name="Message"
                  value={Message}
                  onChange={(e) => this.setState({ Message: e.target.value.trimStart() })}
                />
                <p>
                  {`${ContactConst.ONCLICK}${agentDetails.FirstName}",
                   ${ContactConst.CONFIRM}`}
                  <a href="https://www.homer.com.br/termos/termodeuso/" target="_blank" rel="noreferrer">{ContactConst.TERMS}</a>
                  {ContactConst.AND}
                  <a href="https://www.homer.com.br/termos/politicadeprivacidade/" target="_blank" rel="noreferrer">{ContactConst.PRIVACY}</a>
                </p>
                <Button>
                  {`${ContactConst.TALK_TO}${agentDetails.FirstName}`}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

Contact.defaultProps = {
  isSeleto: false,
  isMobile: false,
};

Contact.propTypes = {
  isSeleto: PropTypes.bool,
  isMobile: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
  }).isRequired,
  agentDetails: PropTypes.shape({
    FullName: PropTypes.string,
    FirstName: PropTypes.string,
  }).isRequired,
  postDataContactNoPost: PropTypes.func.isRequired,
  getAgent: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  statePostContact: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    postDataContactNoPost,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  getAgent: state.agentState.getAgent,
  statePostContact: state.contactState.statePostContact,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));
