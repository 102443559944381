import Model from '../model/agentdetails';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.FormattedAnswerIndex = view.FormattedAnswerIndex;
    model.CreatedDatetime = view.CreatedDatetime;
    model.ReferralUrl = view.ReferralUrl;
    model.IsTopHomer = view.IsTopHomer;
    model.UserId = view.UserId;
    model.FullName = view.Fullname;
    model.FirstName = view.Fullname && view.Fullname.split(' ')[0];
    model.Phone = view.Phone;
    model.Email = view.Email;
    model.CreciValidationStatus = view.CreciValidationStatus;
    model.PhoneValidationStatus = view.PhoneValidationStatus;
    model.EmailValidationStatus = view.EmailValidationStatus;
    model.AvatarImagePath = view.AvatarImagePath;
    model.Rating = view.Rating;
    model.FacebookId = view.FacebookId;
    model.AnswerIndex = view.AnswerIndex;
    model.AnswerRatio = view.AnswerRatio;
    model.Score = view.Score;
    model.UserLink = view.UserLink;
    model.CreciNumber = view.CreciNumber;
    model.CreciUF = view.CreciUF;

    return model;
  } catch (error) {
    throw new Error(`AgentDetailsMapper - ViewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models, state) => {
  try {
    if (!models) return null;
    const views = [];

    models.data.forEach(async (model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    const upState = state || {};
    const division = parseInt(models.total / upState.PageSize, 10);
    const remainder = models.total % upState.PageSize;

    const viewsPag = {
      total: division + (remainder <= 0 ? 0 : 1),
      list: views,
    };

    return viewsPag;
  } catch (error) {
    throw new Error(`AgentDetailsMapper - ViewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    view.userId = model.UserId;

    return view;
  } catch (error) {
    throw new Error(`AgentDetailsMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`AgentDetailsMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Uuid = view.Uuid || '';
    model.AvatarImagePath = view.AvatarImagePath || '';
    model.CreciNumber = view.CreciNumber || '';
    model.CreciUF = view.CreciUF || '';
    model.FullName = view.FullName || '';

    return model;
  } catch (error) {
    throw new Error(`AgentDetailsMapper - ViewModelToState = ${error}`);
  }
};

export const modelToFilter = (model) => {
  try {
    if (!model) return null;

    let view = '';

    model.Filter.forEach((filter) => {
      const id = filter.id.toLowerCase();

      if (id.includes('document')) {
        view += `${id}=${encodeURIComponent(filter.value.replace(/\D/g, ''))}&`;
      } else {
        view += `${id}=${encodeURIComponent(filter.value)}&`;
      }
    });

    model.Sort.forEach((sort) => {
      const id = sort.id.toLowerCase();
      view += `sort=${id}&`;
      view += `desc=${sort.desc}&`;
    });

    view += `page=${model.Page + 1}&`;
    view += `quantity=${model.PageSize}`;

    return view;
  } catch (error) {
    throw new Error(`AgentDetailsMapper - ModelToFilter = ${error}`);
  }
};
