import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const MetaDecorator = ({
  title, description,
}) => (
  <Helmet>
    {/* <title>{title}</title>
    <meta name="description" content={description} /> */}
    <meta property="og:title" content={title} />
    <meta property="og:type" content="article" />
    <meta property="og:description" content={description} />
    {/* <meta property="og:url" content={window.location.href} /> */}
    {/* <meta property="og:image" content={`https://${window.location.host}${imageUrl}`} /> */}
    <meta name="twitter:card" content="summary" />

    {/* <meta property="og:site_name" content="European Travel, Inc." /> */}
    {/* <meta name="twitter:image:alt" content={imageAlt} /> */}
  </Helmet>
);

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // imageUrl: PropTypes.string.isRequired,
  // imageAlt: PropTypes.string.isRequired,
};

export default MetaDecorator;
