import {
  POST_POST, LIST_POST, LIST_POST_SELETO, GET_POST,
} from '../actionTypes/post';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

export const INITIAL_STATE = {
  statePostPost: BASE,
  listPost: BASE_PAGINATION,
  listPostSeleto: BASE_PAGINATION,
  getPost: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_POST:
      return { ...state, statePostPost: action.payload };
    case LIST_POST:
      return { ...state, listPost: action.payload };
    case LIST_POST_SELETO:
      return { ...state, listPostSeleto: action.payload };
    case GET_POST:
      return { ...state, getPost: action.payload };
    default:
      return state;
  }
};
