import { combineReducers } from 'redux';
import AuthReducer from './authReducer';
import UserReducer from './userReducer';
import AgentDetailsReducer from './agentdetailsReducer';
import PostReducer from './postReducer';
import AgentReducer from './agentReducer';
import PropertyTypesReducer from './propertytypesReducer';
import LocationReducer from './locationReducer';
import PostDetailsReducer from './postdetailsReducer';
import ContactReducer from './contactReducer';
import GeoReducer from './geoReducer';
import MapReducer from './mapReducer';
import GalleryReducer from './galleryReducer';
import EnterpriseReducer from './enterpriseReducer';
import FilterReducer from './filterReducer';
// REPLACE1

export default (asyncReducers) => combineReducers({
  authState: AuthReducer,
  userState: UserReducer,
  agentdetailsState: AgentDetailsReducer,
  postState: PostReducer,
  agentState: AgentReducer,
  propertytypesState: PropertyTypesReducer,
  locationState: LocationReducer,
  postdetailsState: PostDetailsReducer,
  contactState: ContactReducer,
  geoState: GeoReducer,
  mapState: MapReducer,
  galleryState: GalleryReducer,
  enterpriseState: EnterpriseReducer,
  filterState: FilterReducer,
  // REPLACE2
  ...asyncReducers,
});
