import Model from '../model/home';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.HomeId = view.HomeId;
    model.Token = view.Token;

    return model;
  } catch (error) {
    throw new Error(`HomeMapper - viewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models, state) => {
  try {
    if (!models) return null;

    const views = [];

    models.result.forEach((model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    const upState = state || {};
    const division = parseInt(models.total / upState.PageSize, 10);
    const remainder = models.total % upState.PageSize;

    const viewsPag = {
      total: division + (remainder <= 0 ? 0 : 1),
      list: views,
    };

    return viewsPag;
  } catch (error) {
    throw new Error(`HomeMapper - viewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    view.uuid = model.Id;
    view.email = model.Email;
    view.name = model.Name;
    view.type = model.Type;
    view.password = model.Password;
    view.origin = 'IBEST';

    return view;
  } catch (error) {
    throw new Error(`HomeMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`HomeMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Id = view.Id || '';
    model.Email = view.Email || '';
    model.Name = view.Name || '';
    model.Type = view.Type || '';
    model.Password = view.Password || '';
    model.Token = view.Token || '';

    return model;
  } catch (error) {
    throw new Error(`HomeMapper - viewModelToState = ${error}`);
  }
};

export const modelToAuthViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    view.email = model.Email;
    view.password = model.Password;

    return view;
  } catch (error) {
    throw new Error(`HomeMapper - modelToAuthViewModel = ${error}`);
  }
};

export const modelToResetViewModel = (model, token) => {
  try {
    if (!model) return null;

    const view = {};

    view.token = token;
    view.password = model.Password;

    return view;
  } catch (error) {
    throw new Error(`HomeMapper - modelToAuthViewModel = ${error}`);
  }
};

export const modelToSearch = (state, props) => {
  try {
    let view = '';

    const {
      Location, IsRent, Amount, QttRooms, QttParkings,
      PropertyType, PropertyTypeSearch, QttRoomsSearch,
    } = state;
    const { match, getGeo } = props;
    const { params } = match;
    const { slug, price } = params;
    const geo = getGeo && getGeo.data;

    view += `/${slug}`;
    view += '/search';
    view += `/${IsRent}`;
    view += `/${geo.Latitude}`;
    view += `/${geo.Longitude}`;
    view += `/${PropertyTypeSearch || PropertyType}`;
    const newAmount = Amount ? `/${Amount.replace(/[^0-9]/g, '')}` : '/500000000';
    view += price ? `/${price}` : newAmount;
    view += `/${QttRoomsSearch || QttRooms}`;
    view += `/${QttParkings}`;
    view += `/${encodeURIComponent(Location)}`;

    return view;
  } catch (error) {
    throw new Error(`HomeMapper - ModelToFilter = ${error}`);
  }
};

export const modelToSearchBread = (location, props) => {
  try {
    let view = '';

    const { match, postDetails } = props;
    const { params } = match;
    const {
      slug, isSell, propertyType, price, rooms, parkingSpots,
    } = params;

    view += `/${slug}`;
    view += '/search';
    view += `/${postDetails ? postDetails.IsRent : isSell}`;
    view += '/0';
    view += '/0';
    view += `/${postDetails ? postDetails.PropertyType : propertyType}`;
    view += postDetails ? `/${postDetails.Price}` : `/${price}`;
    view += `/${postDetails ? postDetails.Rooms : rooms}`;
    const pk = postDetails ? postDetails.ParkingSpots : parkingSpots;
    view += `/${pk || 0}`;
    view += `/${encodeURIComponent(location)}`;

    return view;
  } catch (error) {
    throw new Error(`HomeMapper - modelToSearchBread = ${error}`);
  }
};
