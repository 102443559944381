/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container,
  Row,
  Col,
  CardTitle,
  FormGroup,
  CardText,
  Button,
  Label,
  CardBody,
  Card,
  // ButtonDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { AppHeader } from '@coreui/react';
import { geolocated } from 'react-geolocated';
import { toast } from 'react-toastify';
import {
  FacebookShareButton,
  // WhatsappShareButton,
  FacebookIcon,
  // WhatsappIcon,
} from 'react-share';
import GoogleMapReact from 'google-map-react';
import BaseComponent from './base';
import { authDataUser } from '../../actions/auth';
import { getDataAgent, getDataAgentSeleto } from '../../actions/agent';
import { getDataAgentDetails } from '../../actions/agentdetails';
import { getDataPostSeleto, getSimilarPosts } from '../../actions/post';
import { getDataPostDetails } from '../../actions/postdetails';
import Model from '../../model/login';
import { modelToViewModel as modelToViewModelAgent } from '../../mapper/agent';
import { modelToViewModel as modelToViewModelAgentDetails } from '../../mapper/agentdetails';
import { modelToViewModel as modelToViewModelPost } from '../../mapper/post';
import { modelToViewModel as modelToViewModelPostDetails } from '../../mapper/postdetails';
import DefaultHeader from '../../containers/defaultHeader';
import DefaultFooter from '../../containers/defaultFooter';
import LocationImage from '../../assets/imgs/location-image.svg';
import iconCar from '../../assets/icons/car.svg';
import iconRuler from '../../assets/icons/ruler.svg';
import iconBed from '../../assets/icons/bed.svg';
import iconClose from '../../assets/icons/close_button.svg';
import iconCopy from '../../assets/icons/copy.svg';
import { PostsComponent, ImagesComponent } from '../component/post';
import { groupArr } from '../../lib/utils';
import { ContactModalComponent } from '../component/contact';
import BreadcrumbsComponent from '../component/breadcrumbs';
import CopyToClipboard from '../../lib/copyToClipboard';
import Contact from '../../const/postdetails';
import Config from '../../config/environments';
import MetaDecorator from '../component/Meta';
import { modelToSearchBread } from '../../mapper/home';
import SofaImg from '../../assets/imgs/img_sofa.png';
import SearchImg from '../../assets/imgs/search_zoom.svg';
import TagSeleto from '../../assets/icons/tagHomerSeleto.svg';
// import WhatsappIconRed from '../../assets/icons/whatsapp_icon_red.svg';
import Pin from '../../assets/icons/pinred.svg';

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({ behavior: 'smooth' });

  return { executeScroll, elRef };
};
class PostDetails extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.handleResize = this.handleResize.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleBackSeleto = this.handleBackSeleto.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleShareModal = this.handleShareModal.bind(this);
    this.handleWhatsappOptions = this.handleWhatsappOptions.bind(this);
    this.handleLocationClick = this.handleLocationClick.bind(this);
    this.openImageViewer = this.openImageViewer.bind(this);
    this.closeImageViewer = this.closeImageViewer.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      ...Model,
      Width: window.innerWidth,
      PostId: '',
      OwnerId: '',
      isShareModalOpen: false,
      isWhatsappOptionsOpen: false,
      dropdownOpen: false,
      currentImage: 0,
      isViewerOpen: false,
    };
    this.elScroll = utilizeScroll();
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  shouldComponentUpdate(nextProps, nextState) {
    const p = this.props;
    const currentSlug = p.match.params.slug;
    const currentPost = p.match.params.postId;
    const {
      RequestedAgent, RequestedAgentDetails, RequestedAgentSeleto,
      RequestedPostDetails, RequestedPostSeleto,
    } = nextState;
    const {
      getAgent, getAgentSeleto, match,
    } = nextProps;
    const { params } = match;
    const { slug, postId } = params;
    const agent = getAgent && getAgent.data;
    const agentSeleto = getAgentSeleto && getAgentSeleto.data;
    if (!RequestedAgent && currentSlug === slug) {
      const state = modelToViewModelAgent(slug);
      nextProps.getDataAgent(state);
      this.setState({ RequestedAgent: true });
      return false;
    }

    if (!RequestedAgentSeleto && slug) {
      const slugSeleto = 'buscar-imoveis';
      const state = modelToViewModelAgent(slugSeleto);
      nextProps.getDataAgentSeleto(state);
      this.setState({ RequestedAgentSeleto: true });
      return false;
    }

    if (!RequestedPostSeleto && agentSeleto) {
      const state = modelToViewModelPost(agentSeleto);
      nextProps.getDataPostSeleto(state);
      this.setState({ RequestedPostSeleto: true });
      return false;
    }

    if (agent && !RequestedAgentDetails) {
      const state = modelToViewModelAgentDetails(agent);
      nextProps.getDataAgentDetails(state);
      this.setState({ RequestedAgentDetails: true });
      return false;
    }

    if (!RequestedPostDetails && currentPost === postId) {
      const state = modelToViewModelPostDetails(postId);
      nextProps.getDataPostDetails(state);
      nextProps.getSimilarPosts(state);
      this.setState({ RequestedPostDetails: true });
      return false;
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleLocationClick(event, homerLocation) {
    event.preventDefault();

    // const { history, address } = this.props;
    const { history, getPostDetails, match } = this.props;
    const { params } = match;
    const { postId } = params;

    const address = homerLocation;

    const addressSanitized = decodeURIComponent(address) || '';
    const paths = addressSanitized.replaceAll('-', ',').split(',').reverse();

    let location = '';
    let currentIdx = 1;

    while (currentIdx !== -1) {
      const path = paths[currentIdx];
      location += path.trim();
      currentIdx -= 1;
      location += currentIdx !== -1 ? ', ' : '';
    }

    const locaPostDetails = (getPostDetails && getPostDetails.data) || {};

    const state = modelToSearchBread(location, {
      location,
      address: locaPostDetails.Location,
      postId,
      postDetails: locaPostDetails,
      match,
    });

    modelToSearchBread(location, this.props);

    history.push({ pathname: state });
  }

  handleResize() {
    this.setState({ Width: window.innerWidth });
  }

  handleBack(event) {
    event.preventDefault();

    const { match, history } = this.props;
    const { params } = match;
    const { slug } = params;
    history.push({ pathname: `/${slug}` });
  }

  handleBackSeleto(event) {
    event.preventDefault();

    const { history } = this.props;
    history.push({ pathname: '/' });
  }

  handleModal(event, postId, ownerId) {
    event.preventDefault();
    const { IsOpen } = this.state;
    this.setState({ IsOpen: !IsOpen, PostId: postId, OwnerId: ownerId });
  }

  // eslint-disable-next-line class-methods-use-this
  handleCopy(event) {
    event.preventDefault();

    CopyToClipboard(window.location.href);
    toast.success('Link copiado para área de transferência');
  }

  handleShareModal(event) {
    event.preventDefault();

    const { isShareModalOpen } = this.state;

    this.setState({ isShareModalOpen: !isShareModalOpen });
  }

  handleWhatsappOptions(event) {
    event.preventDefault();

    const { isWhatsappOptionsOpen } = this.state;

    this.setState({ isWhatsappOptionsOpen: !isWhatsappOptionsOpen });
  }

  openImageViewer(event, index) {
    event.preventDefault();

    const { isOpenImage } = this.state;
    this.setState({ currentImage: index || 0, isOpenImage: !isOpenImage, isViewerOpen: true });
    return true;
  }

  closeImageViewer() {
    this.setState({ currentImage: 0, isViewerOpen: false, isOpenImage: false });
  }

  toggle() {
    const { dropdownOpen } = this.state;
    this.setState({
      dropdownOpen: !dropdownOpen,
    });
  }

  render() {
    const {
      getPostDetails, getAgentDetails, listPost, location, match,
    } = this.props;
    const { params } = match;
    const { postId, slug } = params;
    const {
      RequestedPost,
      RequestedPostDetails,
      Width, IsOpen, PostId,
      OwnerId, isShareModalOpen,
      currentImage, isViewerOpen, isOpenImage,
    } = this.state;

    const isMobile = Width <= 961;
    const agentDetails = getAgentDetails && getAgentDetails.data;
    const postDetails = (getPostDetails && getPostDetails.data) || {};
    const posts = (listPost && listPost.data && listPost.data.list) || [];
    const isSeleto = postDetails.IsHomerSell || false;
    const showBroker = Config.SECURITY.SELETO === slug || false;
    const shareIconSize = 38;

    const mockImgs = [{
      MediaUrl: 'placeholder_img',
      PostMediaId: 1,
    },
    {
      MediaUrl: '',
      PostMediaId: 2,
    },
    {
      MediaUrl: '',
      PostMediaId: 3,
    }];

    const qttImages = postDetails.PostMedia && postDetails.PostMedia.length > 0
      ? postDetails.PostMedia : mockImgs;

    const groupImages = groupArr(qttImages, isMobile ? 1 : 3);

    const images = [];

    groupImages.map((groupMedia) => (
      groupMedia.map((media) => (
        images.push(media)
      ))));

    const addressSanitized = decodeURIComponent(postDetails.Location) || '';
    const paths = addressSanitized.replaceAll('-', ',').split(',');
    const bairro = paths[1] ? paths[1].trim() : paths[1];

    // const AnyReactComponent = ({ text }) => <div>{text}</div>;
    const defaultProps = {
      center: {
        lat: postDetails.Latitude,
        lng: postDetails.Longitude,
      },
      zoom: 15,
    };

    const markerStyle = {
      position: 'absolute',
      with: '20px',
      height: '20px',
      top: '100%',
      left: '50%',
      color: 'red',
      transform: 'translate(-50%, -100%)',
    };
    return (
      <>
        <MetaDecorator
          title={`${postDetails.PropertyType}  ${postDetails.IsRent ? 'aluguel ' : 'à Venda na região | Homer Imóveis'} `}
          description={`${postDetails.Location}
          ${!!postDetails.Rooms && (`${postDetails.Rooms} quarto${postDetails.Rooms > 1 ? 's' : ''}`)}
          ${!!postDetails.Area && (`${postDetails.Area} m²`)} `}
          imageUrl={postDetails.PostMedia && postDetails.PostMedia.length > 0
            ? postDetails.PostMedia[0].MediaUrl : ''}
        />

        <AppHeader className={isMobile ? 'header-mobile' : 'header'}>
          <DefaultHeader
            isMobile={isMobile}
            isSeleto={showBroker}
            toggle={this.handleModal}
            isDetails
            isHome={false}
          />
        </AppHeader>
        <div className="post-details app pb-0 backgroundcolor-white">
          <div className="app">
            <ContactModalComponent
              isMobile={isMobile}
              isSeleto={isSeleto}
              isOpen={IsOpen}
              PostId={PostId}
              OwnerId={OwnerId}
              toggle={this.handleModal}
            />
            <Container className="post-details">
              <Row className="justify-content-center">
                <ImagesComponent
                  isMobile={isMobile}
                  isSeleto={isSeleto}
                  postDetails={postDetails}
                  isLoading={RequestedPostDetails}
                  currentImage={currentImage}
                  openImage={isOpenImage}
                  isViewerOpen={isViewerOpen}
                  openImageViewer={this.openImageViewer}
                  closeImageViewer={this.closeImageViewer}
                  // toggle={this.openImageViewer}
                />
              </Row>
            </Container>
            <div className="form-home">
              <Container className="post-details details">
                <Row className="Col-post-details homerseleto-row">
                  <Col md={8}>
                    {!isMobile && (
                      <BreadcrumbsComponent
                        location={location}
                        address={postDetails.Location}
                        postId={postId}
                        postDetails={postDetails}
                      />
                    )}
                    <>
                      <Col md={12}>
                        <Row className="justify-content-start button-group">
                          {postDetails.PostMedia && postDetails.PostMedia.length > 0 && (
                            <Button
                              className="btn-secondary-2 btn-see-photo"
                              onClick={(e) => this.openImageViewer(e)}
                            >
                              {isMobile ? 'Fotos' : 'Ver fotos'}
                            </Button>
                          )}
                          <Button
                            className="btn-secondary-2 btn-see-maps"
                            onClick={this.elScroll.executeScroll}
                          >
                            {isMobile ? 'Mapa' : 'Ver mapa'}
                          </Button>
                          <Button
                            className="btn-secondary-2 btn-share-post"
                            onClick={(e) => this.handleShareModal(e)}
                            onKeyPress={(e) => this.handleShareModal(e)}
                          >
                            {isMobile ? 'Divulgue' : Contact.SHARE_TEXT}
                          </Button>
                          {
                            isShareModalOpen && (
                              <div className="small-share-modal-container">
                                <div className="share-header">
                                  <div className="share-title share-header-item">
                                    Divulgue nas suas redes:
                                  </div>
                                  <div
                                    className="share-close-button share-header-item"
                                    onClick={(e) => this.handleShareModal(e)}
                                    onKeyPress={(e) => this.handleShareModal(e)}
                                    role="button"
                                    tabIndex="0"
                                  >
                                    <img
                                      loading="lazy"
                                      src={iconClose}
                                      alt="Fechar"
                                      className="close-button-image"
                                    />
                                  </div>
                                </div>
                                <div className="share-body">
                                  <div className="share-options">
                                    <div
                                      className="share-option"
                                      onClick={this.handleCopy}
                                      onKeyPress={this.handleCopy}
                                      role="button"
                                      tabIndex="0"
                                    >
                                      <img
                                        loading="lazy"
                                        src={iconCopy}
                                        alt="Copiar"
                                        className="share-option-image"
                                      />
                                      <div className="share-option-label">
                                        Copiar
                                      </div>
                                    </div>
                                    {/* <div className="share-option">
                                        <WhatsappShareButton url={window.location.href}>
                                          <WhatsappIcon size={shareIconSize} round />
                                        </WhatsappShareButton>
                                        <div className="share-option-label">
                                          Whatsapp
                                        </div>
                                      </div> */}
                                    <div className="share-option">
                                      <FacebookShareButton url={window.location.href}>
                                        <FacebookIcon size={shareIconSize} round />
                                      </FacebookShareButton>
                                      <div className="share-option-label">
                                        Facebook
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        </Row>
                        <Row>
                          <CardTitle tag="h2">
                            {postDetails.PropertyType}
                            &nbsp;
                            <font color="#27B062">{postDetails.IsRent ? 'aluguel ' : 'à venda '}</font>
                          </CardTitle>
                        </Row>
                        <Row>
                          <CardTitle>
                            <Label className="address">{postDetails.Location}</Label>
                          </CardTitle>
                        </Row>
                        {isMobile && (
                          <>
                            <Row className="card-label-card">
                              <Label className="price" htmlFor="price">{postDetails.MaximumPrice && postDetails.MaximumPrice.BRLCurrency()}</Label>
                              <div className="horizontal-line" />
                              {postDetails.PropertyTaxes > 0 && (
                                <>
                                  <Col xs={6} className="description-col">
                                    <Row style={{ marginTop: '15px' }}>
                                      <Label>Condominio</Label>
                                    </Row>
                                  </Col>
                                  <Col xs={6} className="description-col">
                                    <Row className="content-align" style={{ marginTop: '15px' }}>

                                      <Label>{`${postDetails.CondoFee.BRLCurrency()} / mês`}</Label>

                                    </Row>
                                  </Col>
                                </>
                              )}
                              {postDetails.PropertyTaxes > 0 && (
                                <>
                                  <Col xs={6} className="description-col">
                                    <Row>
                                      <Label>IPTU</Label>
                                    </Row>
                                  </Col>
                                  <Col xs={6} className="description-col">
                                    <Row className="content-align">
                                      <Label>{`${postDetails.PropertyTaxes.BRLCurrency()}`}</Label>
                                    </Row>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </>
                        )}
                      </Col>
                    </>
                    <FormGroup>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Row className="text-left pl-0 ml-1 pt-1">
                              {!!postDetails.Rooms && (
                                <div className="property-details">
                                  <>
                                    <img loading="lazy" src={iconBed} alt="Quartos" className="icons" />
                                    <p>
                                      {`${postDetails.Rooms} quarto${postDetails.Rooms > 1 ? 's' : ''}`}
                                    </p>
                                  </>

                                </div>
                              )}

                              {!!postDetails.Area && (
                                <div className="property-details">
                                  <>
                                    <img loading="lazy" src={iconRuler} alt="Área" className="icons" />
                                    <p>
                                      {`${postDetails.Area} m²`}
                                    </p>
                                  </>
                                </div>
                              )}
                              {!!postDetails.ParkingSpots && (
                                <div className="property-details">
                                  <>
                                    <img loading="lazy" src={iconCar} alt="Vagas" className="icons" />
                                    <p>
                                      {`${postDetails.ParkingSpots} vaga${postDetails.ParkingSpots > 1 ? 's' : ''}`}
                                    </p>
                                  </>
                                </div>
                              )}
                            </Row>
                            {postDetails.Description && (
                              <>
                                <CardTitle tag="h4">{Contact.DESCRIPTION}</CardTitle>
                                <p className="p-class max" dangerouslySetInnerHTML={{ __html: postDetails.Description }} />
                                {
                                  postDetails.Tags && postDetails.Tags.length > 0
                                  && (<CardTitle tag="h4">{Contact.FEATURES}</CardTitle>)
                                }
                                <Row>
                                  {
                                    postDetails.Tags && postDetails.Tags.map((tag) => (

                                      <CardText className="label-tag">{tag.Name}</CardText>

                                    ))
                                  }
                                </Row>
                              </>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Col md={12}>
                        <Row className="seleto-container">
                          {!isMobile && isSeleto && (
                            <>
                              <Col md={5} className="col-left">
                                <div>
                                  <img loading="lazy" src={SofaImg} alt="Sofa icon" />
                                </div>
                              </Col>
                              <Col md={7}>
                                <div>
                                  <div className="img-seleto">
                                    <img loading="lazy" src={TagSeleto} alt="Tag Seleto" />
                                  </div>
                                  <CardText tag="h3" className="title-seleto">
                                    {Contact.SELECTSELETO}
                                  </CardText>
                                  <CardText className="description-seleto" tag="h5">
                                    {Contact.DESCRIPTIONSELETO}
                                  </CardText>
                                </div>
                              </Col>
                            </>
                          )}
                          {isMobile && isSeleto && (
                            <Col md={12}>
                              <div>
                                <div className="img-seleto">
                                  <img loading="lazy" src={TagSeleto} alt="Tag Seleto" />
                                </div>
                                <CardText tag="h3" className="title-seleto">
                                  {Contact.SELECTSELETO}
                                </CardText>
                                <CardText className="description-seleto" tag="h5">
                                  {Contact.DESCRIPTIONSELETO}
                                </CardText>
                              </div>
                            </Col>
                          )}
                        </Row>
                        <div ref={this.elScroll.elRef} />
                        <Row>
                          <CardTitle tag="h4">{Contact.LOCATIONMAP}</CardTitle>
                          <div className="map-container">
                            {postDetails.Latitude !== undefined && (
                              <GoogleMapReact
                                bootstrapURLKeys={{ key: Config.SECURITY.GOOGLE_API_KEY }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                                yesIWantToUseGoogleMapApiInternals
                                onChange={this.handleChange}
                              >
                                <div
                                  key={postDetails.id}
                                  lat={postDetails.Latitude}
                                  lng={postDetails.Longitude}
                                >
                                  <img loading="lazy" style={markerStyle} src={Pin} alt="pin" />
                                </div>

                              </GoogleMapReact>
                            )}
                          </div>
                        </Row>
                        {agentDetails && agentDetails.FirstName === 'Juliana' && (
                          <Row className="search-container">
                            <Col md={2} className="col-left">
                              <img loading="lazy" src={SearchImg} alt="Sofa icon" />
                            </Col>
                            <Col md={6}>
                              <CardText tag="h6" className="title-search">{Contact.OPTIONPROPERTY}</CardText>
                              <CardText tag="h6">{Contact.CHARACTERISTICS}</CardText>
                            </Col>
                            <Col md={4}>
                              <a href="https://form.jotform.com/220265391500648" className="" target="_blank" rel="noreferrer">
                                <Button
                                  type="button"
                                  className="btn-forme"
                                >
                                  {Contact.FINDFORME}
                                </Button>
                              </a>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={4} className="is-desktop">
                    <Card className="card-label-card">
                      <CardBody>
                        <Row>
                          <Label className="title" htmlFor="title-pric">Valor anunciado</Label>
                        </Row>
                        {postDetails.MaximumPrice
                          && (
                            <Row>
                              <Label className="price" htmlFor="price">{postDetails.MaximumPrice && postDetails.MaximumPrice.BRLCurrency()}</Label>
                            </Row>
                          )}
                        {agentDetails && agentDetails.FirstName === 'Juliana' && isSeleto && (
                          <Row>
                            {/* <ButtonDropdown className="button-whats" isOpen={dropdownOpen}
                          toggle={this.toggle}>
                            <DropdownToggle className="btn-whatsapp" caret>
                              <img loading="lazy" src={WhatsappIconRed} alt="Drop whatsapp" />
                              WhatsApp
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem>
                                <a className="dropdown-item" target="_blank" rel="noreferrer"
                                href={linkWhatsappDoubt}>
                                  {Contact.WHATSAPP_DOUBT}
                                </a>
                              </DropdownItem>
                              <DropdownItem>
                                <a className="dropdown-item" target="_blank" rel="noreferrer"
                                href={linkWhatsappVisit}>
                                  {Contact.WHATSAPP_VISIT}
                                </a>
                              </DropdownItem>
                              <DropdownItem>
                                <a className="dropdown-item" target="_blank" rel="noreferrer"
                                href={linkWhatsappProposal}>
                                  {Contact.WHATSAPP_PROPOSAL}
                                </a>
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown> */}
                          </Row>
                        )}
                        <Row className="btn-group">
                          <Button
                            className="broker btn-group"
                            onClick={(e) => this.handleModal(e, postId, postDetails.UserId)}
                          >
                            {`Fale com ${(agentDetails && agentDetails.FirstName) || 'Homer'}`}
                          </Button>
                        </Row>
                        {postDetails.CondoFee > 0 && (
                          <Row className="horizontal-line">
                            <Col md={6} className="description-col">
                              <Row style={{ marginTop: '15px' }}>
                                <Label>Condominio</Label>
                              </Row>
                            </Col>
                            <Col md={6} className="description-col">
                              <Row className="content-align" style={{ marginTop: '15px' }}>
                                <Label>{`${postDetails.CondoFee.BRLCurrency()} / mês`}</Label>
                              </Row>
                            </Col>
                          </Row>
                        )}
                        {postDetails.PropertyTaxes > 0 && (
                          <Row>
                            <Col md={6} className="description-col">
                              <Row>
                                <Label>IPTU</Label>
                              </Row>
                            </Col>
                            <Col md={6} className="description-col">
                              <Row className="content-align">
                                <Label>{`${postDetails.PropertyTaxes.BRLCurrency()}`}</Label>
                              </Row>
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
            {posts.length > 0 && (

              <div className="app similar" style={{ width: '100%' }}>
                <Container className="post-details-similar">
                  <div className="homer-similar">
                    <CardTitle className="label-similar" tag="label">{Contact.SIMILAR}</CardTitle>
                    <CardText className="text-features">{Contact.YOU_SEARCH}</CardText>
                    <Row className="justify-content-center">
                      <PostsComponent
                        postDetails
                        isLoading={RequestedPost}
                        isSeleto={showBroker}
                        isMobile={isMobile}
                        agentDetails={agentDetails}
                        posts={posts}
                        toggle={this.handleModal}
                      />
                    </Row>
                  </div>
                </Container>
              </div>
            )}
            {
              (bairro) && (
                <Col md={12} className="homer-location">
                  <Container className="post-details details location">
                    <Row className="explore-container">
                      <Col md={6} className="left-side">
                        <div className="left-side-content">
                          <CardTitle className="section-title" tag="h2">
                            {Contact.LOCATION}
                          </CardTitle>
                          <div className="button-container">
                            <Button
                              type="button"
                              className="btn-secondary-explore-location"
                              onClick={(e) => this.handleLocationClick(e, postDetails.Location)}
                            >
                              {Contact.EXPLORE}
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col md={6} className="right-side">
                        <div className="right-side-content">
                          <img loading="lazy" src={LocationImage} alt="mapa" />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              )
            }
            {isMobile
              && (
                <Row className="floating-button">
                  {(isSeleto && showBroker) ? (
                    <>
                      <Col>
                        <div className="float-button-center">
                          <div className="center">
                            <Button className="combined" type="button" onClick={(e) => this.handleModal(e, postId, postDetails.UserId)}>{Contact.TALK_TO}</Button>
                          </div>
                          {/* <div className={`${isWhatsappOptionsOpen ? 'btn-group dropup
                          whatsapp-floating-button-container pull-left show'
                          : 'btn-group dropup whatsapp-floating-button-container pull-left'}`}>
                            <Button
                              className="btn btn-success dropdown-toggle"
                              onClick={(e) => this.handleWhatsappOptions(e)}
                              onKeyPress={(e) => this.handleWhatsappOptions(e)}
                            >
                              <img loading="lazy" src={WhatsappIconRed} alt="Drop whatsapp" />
                            </Button>
                            {
                              isWhatsappOptionsOpen && (
                                <div className="dropdown-menu">
                                  <a className="dropdown-item" target="_blank" rel="noreferrer"
                                  href={linkWhatsappDoubt}>
                                    {Contact.WHATSAPP_DOUBT}
                                  </a>
                                  <a className="dropdown-item" target="_blank" rel="noreferrer"
                                  href={linkWhatsappVisit}>
                                    {Contact.WHATSAPP_VISIT}
                                  </a>
                                  <a className="dropdown-item" target="_blank" rel="noreferrer"
                                  href={linkWhatsappProposal}>
                                    {Contact.WHATSAPP_PROPOSAL}
                                  </a>
                                </div>
                              )
                            }
                          </div> */}
                        </div>
                      </Col>
                    </>
                  )
                    : (
                      <Col className="float-button-center">
                        <Button type="button" onClick={(e) => this.handleModal(e, postId, postDetails.UserId)}>
                          {`Fale com o ${agentDetails && agentDetails.FirstName !== 'Juliana' ? agentDetails.FirstName : 'Homer'}`}
                        </Button>
                      </Col>
                    )}
                </Row>
              )}
            <DefaultFooter
              isMobile={isMobile}
              isSeleto={showBroker}
            />
          </div>
        </div>
      </>
    );
  }
}

PostDetails.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      postId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  getDataAgent: PropTypes.func.isRequired,
  getDataAgentDetails: PropTypes.func.isRequired,
  getSimilarPosts: PropTypes.func.isRequired,
  getDataPostDetails: PropTypes.func.isRequired,
  getDataPostSeleto: PropTypes.func.isRequired,
  getDataAgentSeleto: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getAgent: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getAgentSeleto: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getAgentDetails: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getPost: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getPostDetails: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listPost: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listPostSeleto: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    authDataUser,
    getDataAgent,
    getDataAgentDetails,
    getDataPostDetails,
    getDataAgentSeleto,
    getDataPostSeleto,
    getSimilarPosts,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  authUser: state.authState.authUser,
  getAgent: state.agentState.getAgent,
  getAgentSeleto: state.agentState.getAgentSeleto,
  getAgentDetails: state.agentdetailsState.getAgentDetails,
  getPost: state.postState.getPost,
  getPostDetails: state.postdetailsState.getPostDetails,
  listPost: state.postState.listPost,
  listPostSeleto: state.postState.listPostSeleto,
});

const PostDetailsGeoloc = geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(PostDetails);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostDetailsGeoloc);
