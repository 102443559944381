import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Col, Container, Row, Form, Button, Input, Label, FormGroup, Card, ButtonGroup,
} from 'reactstrap';
import {
  activate, fetchAndActivate, getRemoteConfig, getValue,
} from 'firebase/remote-config';
import { initializeApp } from 'firebase/app';
import PropTypes from 'prop-types';
import { AppHeader } from '@coreui/react';
import { geolocated } from 'react-geolocated';
import ReactAutocomplete from 'react-autocomplete';
import { withRouter } from 'react-router-dom';
import BaseComponent from './base';
import Config from '../../config/environments';
import { authDataUser } from '../../actions/auth';
import { getDataAgent, getDataAgentSeleto } from '../../actions/agent';
import { getDataAgentDetails } from '../../actions/agentdetails';
import { getDataPost } from '../../actions/post';
import { getDataPropertyTypes } from '../../actions/propertytypes';
import { getDataLocation } from '../../actions/location';
import { getDataGeo } from '../../actions/geo';
import Model from '../../model/home';
import { modelToViewModel as modelToViewModelAgent } from '../../mapper/agent';
import { modelToViewModel as modelToViewModelAgentDetails } from '../../mapper/agentdetails';
import { modelToViewModel as modelToViewModelPost } from '../../mapper/post';
import { modelToViewModel as modelToViewModelLocation } from '../../mapper/location';
import { modelToSearch } from '../../mapper/home';
import DefaultHeader from '../../containers/defaultHeader';
import { ContactSeletoComponent, ContactModalComponent } from '../component/contact';
import { PostsComponent, PostsEnterpriseComponent } from '../component/post';
import { getDataEnterprises } from '../../actions/enterprise';
import { pagination } from '../../mapper/filter';
import iconPin from '../../assets/icons/pin.svg';
import iconHome from '../../assets/icons/home.svg';
import iconMoney from '../../assets/icons/money.svg';
import iconBed from '../../assets/icons/greyBed.svg';
import iconCar from '../../assets/icons/greyCar.svg';
import iconPinBackground from '../../assets/imgs/iconpinbackground.png';
import vector3Background from '../../assets/imgs/vector3.png';
import vector4Background from '../../assets/imgs/vector4.png';
import vector5Background from '../../assets/imgs/vector5.png';
import vector6Background from '../../assets/imgs/vector6.png';
import Contact from '../../const/home';
import { brlCurrency } from '../../lib/mask';
import DefaultFooter from '../../containers/defaultFooter';
import Mixpanel from '../../mixpanel';
import {
  homeSearchAddress, homeSearchBanner, homeSearchConfirm,
  homeSearchParkingSpots, homeSearchPropertyType,
  homeSearchRooms, homeSearchValue,
} from '../../const/mixpanel';

class Home extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLocation = this.handleLocation.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleListEnterprise = this.handleListEnterprise.bind(this);
    this.state = {
      ...Model,
      Width: window.innerWidth,
      PostId: '',
      OwnerId: '',
      Incrop: false,
      Requested: true,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  shouldComponentUpdate(nextProps, nextState) {
    const p = this.props;
    const currentSlug = p.match.params.slug;
    const {
      RequestedAgentSeleto, RequestedAgent, RequestedAgentDetails,
      RequestedPost, RequestedProperties, Requested, GetBanner,
    } = nextState;
    const {
      getAgent, match, coords,
    } = nextProps;
    const { params } = match;
    const { slug } = params;
    const agent = getAgent && getAgent.data;
    const slugSeleto = 'buscar-imoveis';

    if (!RequestedAgent && currentSlug === slug) {
      const state = modelToViewModelAgent(slug);
      nextProps.getDataAgent(state);
      this.setState({ RequestedAgent: true });
      return false;
    }

    if (!RequestedAgentSeleto && slug) {
      const state = modelToViewModelAgent(slugSeleto);
      nextProps.getDataAgentSeleto(state);
      this.setState({ RequestedAgentSeleto: true });
      return false;
    }

    if (agent && !RequestedAgentDetails) {
      const state = modelToViewModelAgentDetails(agent);
      nextProps.getDataAgentDetails(state);
      this.setState({ RequestedAgentDetails: true });
      return false;
    }

    if (agent && !RequestedPost) {
      const state = modelToViewModelPost(agent);
      nextProps.getDataPost(state);
      this.setState({ RequestedPost: true });
      return false;
    }

    if (!RequestedProperties) {
      nextProps.getDataPropertyTypes();
      this.setState({ RequestedProperties: true });
      return false;
    }

    if (GetBanner) {
      const firebaseConfig = {
        apiKey: Config.FIREBASE.KEY,
        authDomain: 'homer-1359.firebaseapp.com',
        databaseURL: 'https://homer-1359.firebaseio.com',
        projectId: 'homer-1359',
        storageBucket: 'homer-1359.appspot.com',
        messagingSenderId: '913644986166',
        appId: '1:913644986166:web:fb506123a25cf1577edd97',
        measurementId: 'G-028WRLFMFH',
        minimumFetchIntervalMillis: 0,
      };
      const app = initializeApp(firebaseConfig);
      const remoteConfig = getRemoteConfig(app);

      activate(remoteConfig);
      fetchAndActivate(remoteConfig).then(() => {
        const banner = JSON.parse(getValue(remoteConfig, 'tegra_banner_site_imobiliario').asString());

        this.setState({ Banner: banner });
      });

      this.setState({ GetBanner: false });
    }

    if (Requested) {
      const state = pagination(this.state, 20, true, coords);

      p.getDataEnterprises(state);
      this.setState({ Requested: false });
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleModal(event, postId, ownerId, incorp) {
    event.preventDefault();
    const { IsOpen } = this.state;
    this.setState({
      IsOpen: !IsOpen, PostId: postId, OwnerId: ownerId, Incorp: incorp,
    });
  }

  handleResize() {
    this.setState({ Width: window.innerWidth });
  }

  handleLocation(event, location) {
    event.preventDefault();

    if (location.length >= 3) {
      const p = this.props;
      const state = modelToViewModelLocation(location, p.coords);
      p.getDataLocation(state);
    }

    this.setState({ Location: location });
    Mixpanel.trackEvent(homeSearchAddress());
  }

  handleSubmit(event) {
    event.preventDefault();

    const { history } = this.props;

    const state = modelToSearch(this.state, this.props);

    history.push({ pathname: state });
  }

  handleListEnterprise(event) {
    event.preventDefault();

    const { match } = this.props;
    const { params } = match;
    const { slug } = params;

    const url = `${window.location.origin}/${slug}${Config.ENTERPRISE.ENTERPRISE_LIST}`;

    window.open(url, '_self');
  }

  render() {
    const p = this.props;
    const {
      getAgentDetails, listLocation, listPost, isSeleto, listPropertyTypes, getAgent, history,
      listEnterprise,
    } = this.props;
    const {
      Location, Width, IsOpen, Amount, Banner,
      QttRooms, QttParkings, PropertyType, PostId, OwnerId, Incorp,
    } = this.state;

    const isMobile = Width <= 961;
    const agentDetails = (getAgentDetails && getAgentDetails.data) || {};
    const locations = (listLocation && listLocation.data && listLocation.data.list) || [];
    const enterprises = (listEnterprise && listEnterprise.data && listEnterprise.data.list) || [];
    const posts = (listPost && listPost.data && listPost.data.list) || [];
    const properties = (listPropertyTypes && listPropertyTypes.data
      && listPropertyTypes.data.list) || [];

    if (getAgent.success === true && getAgent.data === null) {
      history.push({ pathname: '/404' });
    }

    return (
      <>
        <div className="app pb-0 backgroundcolor-white">
          <AppHeader className={isMobile && !isSeleto ? 'header-mobile' : 'header'}>
            <DefaultHeader
              isMobile={isMobile}
              isSeleto={isSeleto}
              isHome
              toggle={this.handleModal}
            />
          </AppHeader>
          <div className="app-body">
            {!isMobile
              && (
                <div>
                  <img loading="lazy" src={iconPinBackground} alt="Endereço" className="img-pin-background" />
                  <img loading="lazy" src={vector5Background} alt="Endereço" className="vector5-background-top" />
                  <img loading="lazy" src={vector6Background} alt="Endereço" className="vector6-background-top" />
                  <img loading="lazy" src={vector3Background} alt="Endereço" className="vector3-background-top" />
                  <img loading="lazy" src={vector4Background} alt="Endereço" className="vector4-background-top" />
                </div>
              )}
            <ContactModalComponent
              isMobile={isMobile}
              isSeleto={isSeleto}
              PostId={PostId}
              OwnerId={OwnerId}
              isOpen={IsOpen}
              Incorp={Incorp}
              toggle={this.handleModal}
            />
            <Container className="body">
              <Form onSubmit={this.handleSubmit}>
                <Row className={`justify-content-center align-center ${!isMobile ? 'margin-top mx-auto' : ''}`}>
                  <Col md={12} className={`title-col ${isMobile ? 'p-0' : ''}`}>
                    <h2 className="h2-title">{Contact.MAKE_COMFORTABLE}</h2>
                    <h2 className="h2-mini-title">{!isMobile ? Contact.IMMOBILE : Contact.IMMOBILE_MOBILE}</h2>
                  </Col>
                </Row>
                {!isMobile && (
                  <Row className="justify-content-center align-center margin-tp-bt">
                    <Col md={6}>
                      <Card className="switch">
                        <ButtonGroup>
                          <Button
                            outline
                            type="button"
                            className="is-rent"
                          >
                            {Contact.BUY}
                          </Button>
                        </ButtonGroup>
                      </Card>
                    </Col>
                  </Row>
                )}
                <Row className="justify-content-center">
                  <Col md={7} className="padding10">
                    <Card className="card-inputs mx-auto">
                      <Row form className="justify-content-center">
                        <Col md={12}>
                          <FormGroup className="border-bottom">
                            <img loading="lazy" src={iconPin} alt="Endereço" className="icons-pl" />
                            &nbsp;
                            <Label>{Contact.ADRESS}</Label>
                            <br />
                            <ReactAutocomplete
                              inputProps={{
                                placeholder: 'Endereço, Bairro, Condomínio',
                                required: 'required',
                              }}
                              menuStyle={{ zIndex: 99, position: 'sticky' }}
                              required="required"
                              items={locations}
                              value={Location}
                              getItemValue={(item) => item.Name}
                              onSelect={(value, locale) => {
                                const data = {
                                  GooglePlaceId: locale.Id,
                                  Location: value,
                                };
                                p.getDataGeo(data);
                                return this.setState(data);
                              }}
                              onChange={(e) => {
                                this.handleLocation(e, e.target.value);
                              }}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  key={item.Name}
                                  style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                                  className="autocomplete"
                                >
                                  {item.Name}
                                </div>
                              )}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row form className="row-form-group">
                            <FormGroup className={isMobile ? 'border-right border-bottom' : 'border-right'}>
                              <img loading="lazy" src={iconHome} alt="Tipo do imóvel" className="icons-pl" />
                              <Label>{Contact.TYPE}</Label>
                              <Input
                                type="select"
                                id="PropertyType"
                                name="PropertyType"
                                value={PropertyType}
                                onChange={(e) => {
                                  this.setState({ PropertyType: e.target.value });
                                  Mixpanel.trackEvent(homeSearchPropertyType());
                                }}
                              >
                                {
                                  properties.map((property) => (
                                    <option key={property.Name}>
                                      {property.Name}
                                    </option>
                                  ))
                                }
                              </Input>
                            </FormGroup>
                            <FormGroup className={isMobile ? 'border-bottom' : 'border-right border-bottom'}>
                              <img loading="lazy" src={iconMoney} alt="Imóvel até" className="icons-pl" />
                              &nbsp;
                              <Label>{Contact.PRICE}</Label>
                              <Input
                                className="input-value"
                                type="text"
                                placeholder="Insira o valor"
                                id="Amount"
                                name="Amount"
                                value={Amount}
                                onChange={(e) => {
                                  this.setState({
                                    Amount: brlCurrency(e.target.value),
                                  });
                                  Mixpanel.trackEvent(homeSearchValue());
                                }}
                              />
                            </FormGroup>
                            <FormGroup className="border-right">
                              <img loading="lazy" src={iconBed} alt="Quartos" className="icons-pl" />
                              &nbsp;
                              <Label>{Contact.BEDROOMS}</Label>
                              <Input
                                type="select"
                                className="select-number margin-lf"
                                id="QttRooms"
                                name="QttRooms"
                                value={QttRooms}
                                onChange={(e) => {
                                  this.setState({ QttRooms: e.target.value });
                                  Mixpanel.trackEvent(homeSearchRooms());
                                }}
                              >
                                <option value="0">0+</option>
                                <option value="1">1+</option>
                                <option value="2">2+</option>
                                <option value="3">3+</option>
                                <option value="4">4+</option>
                                <option value="5">5+</option>
                                <option value="6">6+</option>
                                <option value="7">7+</option>
                                <option value="8">8+</option>
                                <option value="9">9+</option>
                              </Input>
                            </FormGroup>
                            <FormGroup>
                              <img loading="lazy" src={iconCar} alt="Vagas" className="icons-pl" />
                              <Label>{Contact.VACANCIES}</Label>
                              <Input
                                type="select"
                                className="select-number"
                                id="QttParkings"
                                name="QttParkings"
                                value={QttParkings}
                                onChange={(e) => {
                                  this.setState({ QttParkings: e.target.value });
                                  Mixpanel.trackEvent(homeSearchParkingSpots());
                                }}
                              >
                                <option value="0">0+</option>
                                <option value="1">1+</option>
                                <option value="2">2+</option>
                                <option value="3">3+</option>
                                <option value="4">4+</option>
                                <option value="5">5+</option>
                                <option value="6">6+</option>
                                <option value="7">7+</option>
                                <option value="8">8+</option>
                                <option value="9">9+</option>
                              </Input>
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row className="justify-content-center align-center">
                  <Col md={6}>
                    {isSeleto ? (
                      <Button type="submit" onClick={() => { Mixpanel.trackEvent(homeSearchConfirm()); }} className="btn-salmon-seleto">{Contact.SEARCH_PROPERTY}</Button>)
                      : (<Button type="submit" onClick={() => { Mixpanel.trackEvent(homeSearchConfirm()); }} className="btn-salmon">{Contact.SEARCH_PROPERTY}</Button>)}
                  </Col>
                </Row>
              </Form>
              <Row className={`container-banner ${isSeleto ? 'banner-seleto' : ''}`}>
                <Col xs={isMobile ? 11 : 8} onClick={() => { Mixpanel.trackEvent(homeSearchBanner()); window.open(Banner.action.url, isMobile ? '_self' : '_blank'); }}>
                  <img src={Banner.imageUrl} alt={Banner.title} />
                </Col>
              </Row>
            </Container>
          </div>
          <div className="app div-size">
            {!isSeleto && posts.length > 0
              && (
                <div>
                  <Row className="justify-content-center align-center my-properties">
                    <Col>
                      <h3 className="h3-title-card">
                        <strong className="title-seleto-decoration">Meus imóveis</strong>
                        &nbsp;cadastrados
                      </h3>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <PostsComponent
                      isLoading
                      isSeleto={isSeleto}
                      isMobile={isMobile}
                      agentDetails={agentDetails}
                      posts={posts}
                      toggle={this.handleModal}
                    />
                  </Row>
                </div>
              )}
          </div>
          <div className="app grey-div div-size">
            <Row className="justify-content-center align-center title-description-seleto">
              <h5 className="title-seleto">
                Imóveis de&nbsp;
                <strong className="title-seleto-decoration">Incorporadora</strong>
              </h5>
            </Row>
            <Row className="justify-content-center">
              <PostsEnterpriseComponent
                isLoading
                isMobile={isMobile}
                posts={enterprises}
                isHomer={isSeleto}
              />
            </Row>
            <Row className="justify-content-center align-center mb-4">
              <Button type="button" className="btn-salmon mb-5" onClick={(e) => this.handleListEnterprise(e)}>{Contact.SEE_ENTERPRISE}</Button>
            </Row>
          </div>
          <ContactSeletoComponent
            isSeleto={isSeleto}
            isMobile={isMobile}
            agentDetails={agentDetails}
          />
          <DefaultFooter
            isSeleto={isSeleto}
            isMobile={isMobile}
            isHome
          />
        </div>
      </>
    );
  }
}

Home.defaultProps = {
  coords: {},
  isSeleto: false,
};

Home.propTypes = {
  isSeleto: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  }).isRequired,
  getDataAgent: PropTypes.func.isRequired,
  getDataAgentDetails: PropTypes.func.isRequired,
  getDataPost: PropTypes.func.isRequired,
  getDataPropertyTypes: PropTypes.func.isRequired,
  getDataLocation: PropTypes.func.isRequired,
  getDataGeo: PropTypes.func.isRequired,
  getDataAgentSeleto: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getAgent: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getAgentSeleto: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getAgentDetails: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listPost: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listPropertyTypes: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listLocation: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  coords: PropTypes.shape({ latitude: PropTypes.number, longitude: PropTypes.number }),
  getGeo: PropTypes.shape({
    data: PropTypes.shape({ Latitude: PropTypes.number, Longitude: PropTypes.number }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getDataEnterprises: PropTypes.func.isRequired,
  listEnterprise: PropTypes.shape({
    data: PropTypes.shape({
      list: PropTypes.arrayOf(PropTypes.object),
      total: PropTypes.number,
    }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    authDataUser,
    getDataAgent,
    getDataAgentDetails,
    getDataPost,
    getDataPropertyTypes,
    getDataLocation,
    getDataGeo,
    getDataAgentSeleto,
    getDataEnterprises,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  authUser: state.authState.authUser,
  getAgent: state.agentState.getAgent,
  getAgentSeleto: state.agentState.getAgentSeleto,
  getAgentDetails: state.agentdetailsState.getAgentDetails,
  listPost: state.postState.listPost,
  listPropertyTypes: state.propertytypesState.listPropertyTypes,
  listLocation: state.locationState.listLocation,
  getGeo: state.geoState.getGeo,
  listEnterprise: state.enterpriseState.listEnterprise,
});

const HomeGeoloc = geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Home);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeGeoloc));
