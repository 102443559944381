import {
  LIST_STATE, LIST_CITY, LIST_DISTRICT, LIST_GALLERY,
} from '../actionTypes/gallery';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  loading: true,
  data: {
    total: 0,
    list: [],
  },
};

export const INITIAL_STATE = {
  stateGalleryState: BASE,
  listState: BASE_PAGINATION,
  listCity: BASE_PAGINATION,
  listDistrict: BASE_PAGINATION,
  listGallery: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_STATE:
      return { ...state, listState: action.payload };
    case LIST_CITY:
      return { ...state, listCity: action.payload };
    case LIST_DISTRICT:
      return { ...state, listDistrict: action.payload };
    case LIST_GALLERY: {
      const list = JSON.parse(JSON.stringify(state.listGallery));
      if (action.payload && action.payload.data) {
        list.data.list = [...action.payload.data.list];
        list.data.total += action.payload.data.total;
        list.data.success = action.payload.success;
        list.state = false;
        list.loading = false;
      } else {
        list.state = true;
      }
      return { ...state, listGallery: list };
    }
    default:
      return state;
  }
};
