import { axiosInstance as axios } from '../lib/axios';
import Config from '../config/environments';
import {
  POST_AGENT, LIST_AGENT, GET_AGENT, GET_AGENT_SELETO,
} from '../actionTypes/agent';
import { viewsModelToModels, viewModelToModel } from '../mapper/agent';
import updateState from './common/common';

export const getDataAgent = (data) => async (dispatch) => {
  const base = {
    type: GET_AGENT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.AGENT.URL}/${data.slug}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const getDataAgentSeleto = (data) => async (dispatch) => {
  const base = {
    type: GET_AGENT_SELETO,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.AGENT.URL}/${data.slug}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const getDataAgentWithoutPagination = () => async (dispatch) => {
  const base = {
    type: LIST_AGENT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.AGENT.URL}?page=1&quantity=100`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewsModelToModels(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const postDataAgent = (data) => async (dispatch) => {
  const base = {
    type: POST_AGENT,
    payload: {},
    state: true,
    success: true,
  };

  const url = Config.AGENT.URL;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const putDataAgent = (data) => async (dispatch) => {
  const base = {
    type: POST_AGENT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.AGENT.URL}/${data.uuid}`;

  axios.put(url, data).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};
