export default {
  LOADING: 'Carregando...',
  TITLE: 'Bem vindo',
  SUB_TITLE: 'Acesse sua conta',
  LOGIN: 'Login',
  LOGIN_TEXT: 'Faça seu login',
  LOGIN_BUTTON: 'Login',
  FORGOT_BUTTON: 'Esqueceu sua senha?',
  FORGOT_MSG: 'Para recuperar sua senha, entre com contato com o Administrador do Sistema.',
};
