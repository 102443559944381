import Local from './local';
import Dev from './dev';
import Hml from './hml';
import Staging from './staging';
import Prod from './prod';
import log from '../log/logger';
import packageJson from '../../../package.json';

const env = process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE.trim();

log.info(env, 'environment');
log.info(`${packageJson.name}@${packageJson.version}`, 'version');
console.log(`environment: ${env}`);
console.log(`version: ${packageJson.name}@${packageJson.version}`);

let config = env === 'LOCAL' ? Local : Local;
config = env === 'DEV' ? Dev : config;
config = env === 'HML' ? Hml : config;
config = env === 'STAGING' ? Staging : config;
config = env === 'PROD' ? Prod : config;

export default {
  ...config,
};
