import { axiosInstance as axios } from '../lib/axios';
import Config from '../config/environments';
import { POST_AGENTDETAILS, LIST_AGENTDETAILS, GET_AGENTDETAILS } from '../actionTypes/agentdetails';
import { viewsModelToModels, viewModelToModel } from '../mapper/agentdetails';
import updateState from './common/common';

export const getDataAgentDetails = (data) => async (dispatch) => {
  const base = {
    type: GET_AGENTDETAILS,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.AGENTDETAILS.URL}/${data.userId}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const getDataAgentDetailsWithoutPagination = () => async (dispatch) => {
  const base = {
    type: LIST_AGENTDETAILS,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.AGENTDETAILS.URL}?page=1&quantity=100`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewsModelToModels(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const postDataAgentDetails = (data) => async (dispatch) => {
  const base = {
    type: POST_AGENTDETAILS,
    payload: {},
    state: true,
    success: true,
  };

  const url = Config.AGENTDETAILS.URL;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const putDataAgentDetails = (data) => async (dispatch) => {
  const base = {
    type: POST_AGENTDETAILS,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.AGENTDETAILS.URL}/${data.uuid}`;

  axios.put(url, data).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};
