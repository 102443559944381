export default {
  END_POINT: process.env.REACT_APP_API_BASE_URL || 'https://service-test.homer.com.br/api/v3/',
  END_POINT_AUTH: process.env.REACT_APP_API_BASE_URL_AUTH || 'https://auth.kroonar.com/api/',
  END_POINT_API: process.env.REACT_APP_API_BASE_URL_MIC || 'https://api-hml.homer.com.br/v1',
  EXPIRATION_AUTH: 2.592e+9 || process.env.MAX_IDLE_TIME,
  EXPIRATION_FORM: 5, // Minuts
  SECURITY: {
    APPLICATION_ID: 23,
    EMAIL: 'homerservice@homer.com',
    PASSWORD: '7vlBccKH9f4T',
    SELETO: 'buscar-imoveis',
    GOOGLE_API_KEY: process.env.GOOGLE_API_KEY || 'AIzaSyAKwL3k95_InxVMsyNe4T_lbXFQ_YTsy5U',
    GOOGLE_ANALYTICS_TRACKING_ID: process.env.GOOGLE_ANALYTICS_TRACKING_ID || 'UA-79864034-10',
    TOKEN: 'MkNzVHZ1ajIrNmdwNzhTRmxXVkgraFNkL3ZnMndvQWtMZzJCUmlRVzhrM3luSkhWakxhTmJpMit2UkEwMy92L0ZGUkZVeU8xMHVvTUxvbFdySWlxZ3dIbUIzWVFKamJUcTFMcjk3OEZzUzB3QWxiR3VweSttNERoRHFDTTQ4aEhzVEE1cWQ5SGp5SWt0azdpalBWVnZBPT0=',
  },
  FIREBASE: {
    KEY: 'AIzaSyAKwL3k95_InxVMsyNe4T_lbXFQ_YTsy5U',
  },
  DEFAULT_VALUES: {
    LATITUDE: -23.000372,
    LONGITUDE: -43.365894,
  },
  DEBUG: {
    LEVEL: 'debug',
    AVAILABLE: true,
    LOCAL: true,
  },
  AUTH: {
    URL: '/users/login',
  },
  ACCOUNT: {
    URL: 'https://conta-test.homer.com.br',
    REAL_ESTATE: '/creditoimobiliario',
    CALCULATOR: '/calculadora',
    UTM_SOURCE: '?utm_source=site_imobiliario',
    UTM_MEDIUM: '&utm_medium=calculadora',
    UTM_CAMPAIGN: '&utm_campaign=seleto_post_credito',
    UTM_TAG: '&utm_tag=credito',
  },
  AGENT: {
    URL: '/personal-site',
  },
  AGENTDETAILS: {
    URL: '/users',
  },
  POST: {
    URL: '/personal-site/posts/active/users',
    END_URL: 'paginate/30/1',
    INSERT: '/posts',
    MEDIAS: '/medias',
    CONTENT: '/content',
    URL_MEDIA: '?url=',
    DEVELOPMENTS: '/real-estate/developments',
    CONTACT_MESSAGE: '/contact-message?contactType=',
    DEVELOPERS: '/developers',
    REAL_ESTATE: '/real-estate',
    OFFSET: '?offset=',
    LIMIT: '&limit=',
    STATE: '&state=',
    CITY: '&city=',
    NEIGHBORHOOD: '&neighborhood=',
    DEVELOPER: '&developerId=',
    MAXIMUM_PRICE: '&maximumPrice=',
    MINIMUM_ROOMS: '&minimumRooms=',
    MINIMUM_PARKINGS: '&minimumParkingSpaces=',
    MINIMUM_LIVING_AREA: '&minimumLivingArea=',
    SORT: '&sort=',
    EMPTY_CITY: '&IfEmptyCityGetState=',
    LAT: '&latitude=',
    LNG: '&longitude=',
  },
  LP_HOMER: {
    URL: 'https://lp.homer.com.br',
    BALNEARIO: '/balneario-camboriu',
  },
  FORM_JOT: {
    URL: 'https://form.jotform.com',
    HOMER_FIND: '/232015701460644',
  },
  ENTERPRISE: {
    ENTERPRISE_LIST: '/empreendimentos',
    ENTERPRISE_DETAILS: '/empreendimento',
  },
  POSTDETAILS: {
    URL: '/homer-sells/status/post',
  },
  PROPERTYTYPES: {
    URL: '/propertytypes',
  },
  LOCATION: {
    URL: '/locations',
    END_URL: 'simplified',
  },
  CONTACT: {
    URL: '/personal-site/contact/site-owner/from',
    ME: '/me',
    MESSAGES: '/messages',
    URL_NO_POST: 'direct',
    URL_OTHER_POST: 'other-post',
    URL_AGENT_POST: 'owner-post',
    ULR_PROP_DEV: 'property-developer',
  },
  GEO: {
    URL: '/locations/geocode/places',
  },
  MAP: {
    URL: '/personal-site/map/search',
  },
  LIST_SELETO: {
    URL: 'https://api-hml.homer.com.br/v1/homer-sells',
    STATE: '/states',
    CITY: '/cities?state=',
    DISCTRICT: '/districts?city=',
    LIST_GALLERY: '/q',
  },
  SIMILAR_POST: {
    URL: 'https://api-hml.homer.com.br/v1/posts/similars',
  },
  MIXPANEL: {
    TOKEN: '4f9d444d210f0de747cf2f0703897ebe',
  },
  FILTER_NEWCONSTRUTCION: {
    NEW_CONSTRUCTION: '/new-constructions',
    STATE: '/states',
    CITY: '/cities?state=',
    DISCTRICT: '/neighborhoods?city=',
    LIST_GALLERY: '/q',
  },
};
