import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Row } from 'reactstrap';

const postLoading = (props) => {
  const { isMobile } = props;

  const loading = (
    <div
      className="text-left mb-4"
      style={{
        width: isMobile ? '79%' : '96%', height: 442, border: 'solid 1px #ddd', borderRadius: '10px', display: 'flex', flexDirection: 'column',
      }}
    >
      <Skeleton
        count={1}
        height={169}
        style={{
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          paddingTop: 4,
        }}
      />
      <div style={{
        height: 271, padding: '10px 17px 19px', display: 'flex', flexDirection: 'column',
      }}
      >
        <div style={{ height: 15 }}>
          <Skeleton
            className="mb-1"
            count={1}
            width={85}
            height={14}
            style={{
            }}
          />
        </div>
        <Skeleton
          count={1}
          width={170}
          height={16}
          style={{
            marginTop: '-3px',
          }}
        />
        <div style={{ height: 16 }}>
          <Skeleton
            className="mt-3"
            count={1}
            width={220}
            height={15}
            style={{
            }}
          />
        </div>
        <div>
          <Skeleton
            className="mt-3"
            count={1}
            width={180}
            height={15}
            style={{
            }}
          />
        </div>
        <div style={{ height: 25 }}>
          <Skeleton
            className="mt-3"
            count={1}
            width={50}
            height={10}
            style={{
            }}
          />
        </div>
        <div style={{ height: 18 }}>
          <Skeleton
            count={1}
            width={100}
            height={16}
            style={{
            }}
          />
        </div>
        <div style={{ height: 18 }}>
          <Skeleton
            className="mt-0"
            count={1}
            width={211}
            height={17}
            style={{
            }}
          />
        </div>
        <Row style={{ margin: '0 0 auto 0' }}>
          <Skeleton
            className="mt-2 mr-1"
            count={1}
            width={17}
            height={20}
            style={{
            }}
          />
          <Skeleton
            className="mt-2 mr-5"
            count={1}
            width={8}
            height={20}
            style={{
            }}
          />
          <Skeleton
            className="mt-2 mr-1"
            count={1}
            width={17}
            height={20}
            style={{
            }}
          />
          <Skeleton
            className="mt-2"
            count={1}
            width={8}
            height={20}
            style={{
            }}
          />
        </Row>
        <Skeleton
          count={1}
          width="100%"
          height={41}
          style={{
            borderRadius: 10,
          }}
        />
      </div>

    </div>

  );

  return loading;
};

postLoading.propTypes = {
};

export default postLoading;
