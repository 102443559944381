import React from 'react';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';

const Loading = ({ msg }) => (
  <div className="float-loading">
    <div className="loading">
      <center>
        <ReactLoading color="#20a8d8" height="70px" width="100px" />
        <span>{msg}</span>
      </center>
    </div>
  </div>
);

Loading.propTypes = {
  msg: PropTypes.string.isRequired,
};

export default Loading;
