import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ordinationLoading = () => {
  const loading = (
    <>
      <div
        className="d-flex mt-4"
        style={{
          alignSelf: 'center',
        }}
      >
        <Skeleton
          count={1}
          width={270}
          height={18}
          style={{ marginTop: 15 }}
        />
        <div className="ml-auto">
          <Skeleton
            count={1}
            width={165}
            height={48}
            style={{
              borderRadius: 10,
            }}
          />
        </div>
      </div>
    </>
  );

  return loading;
};

ordinationLoading.propTypes = {
};

export default ordinationLoading;
