import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Col,
  Modal,
  Row,
  Button,
  Form,
  Input,
  Label,
} from 'reactstrap';
import ReactAutocomplete from 'react-autocomplete';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { brlCurrencies } from '../../../lib/mask';
import CloseModal from '../../../assets/icons/close_button.svg';
import { InputFilterLoadingComponent } from '../post';
import Mixpanel from '../../../mixpanel';
import { enterpriseFilterCity, enterpriseFilterNeightborhood, enterpriseFilterState } from '../../../const/mixpanel';

function ModalFilterEnteprise(props) {
  const {
    isOpen, toggle, filters, handleState, handleCity, handleDistrict, handlePrice, handleDeveloper,
    onChangePrice, handleFilterSubmit, handleChangeOrdination, listState, listCity, listDistrict,
    listDevelopers, handleRooms, handleParkingSpots, handleArea, onChangeArea, Quantities,
    handleDeveloperName, handleClearField,
  } = props;
  const ordination = [{ title: 'Mais Recentes', value: '-date' }, { title: 'Menor Custo', value: '+price' }, { title: 'Maior Custo', value: '-price' }];

  const filter = filters;

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} scrollable centered={false} className="modal-filter-enterprise">
        <Row>
          <Col className="close-modal">
            <Button
              onClick={toggle}
              type="button"
            >
              <img loading="lazy" src={CloseModal} alt="Fechar Modal" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="title">
            <h1>
              {`${filter.IsOrdination ? 'Ordenar' : 'Filtrar'} por:`}
            </h1>
          </Col>
        </Row>
        <Form>
          {!filter.IsOrdination ? (
            <>
              <Row>
                <Col className="container-autocomplete">
                  {filter.states && listState.state === false ? (
                    <>
                      <ReactAutocomplete
                        inputProps={{
                          onClick: (e) => handleClearField(e, 'State'),
                        }}
                        items={filter.states}
                        name="States"
                        value={filter.State}
                        id="States"
                        getItemValue={(item) => item.Name}
                        onChange={(e) => {
                          handleState(e, e.target.value, true);
                          Mixpanel.trackEvent(enterpriseFilterState());
                        }}
                        onSelect={(value) => { handleState(null, value, true); }}
                        shouldItemRender={(state, value) => state.Name.toLowerCase()
                          .indexOf(value.toLowerCase()) !== -1}
                        renderItem={(item, isHighlighted) => (
                          <div
                            key={item.Name}
                            style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                            className="autocomplete"
                            id={`States-${item.Name}`}
                            name={`States-${item.Name}`}
                            data-testid={`States-${item.Name}`}
                          >
                            {item.Name}
                          </div>
                        )}
                        renderMenu={(items, value, style) => (
                          <div className="container-properties" style={{ ...style }}>
                            {items.map((i) => i)}
                          </div>
                        )}
                      />
                      <Label>
                        Estado
                      </Label>
                    </>
                  ) : (
                    <InputFilterLoadingComponent />
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="container-autocomplete">
                  {filter.cities && listCity.state === false ? (
                    <>
                      <ReactAutocomplete
                        inputProps={{
                          disabled: filter.disabledCity,
                          onClick: (e) => handleClearField(e, 'City'),
                        }}
                        items={filter.cities}
                        name="City"
                        value={filter.City}
                        id="City"
                        getItemValue={(item) => item.Name}
                        onChange={(e) => {
                          handleCity(e, e.target.value, true);
                          Mixpanel.trackEvent(enterpriseFilterCity());
                        }}
                        onSelect={(value) => { handleCity(null, value, true); }}
                        shouldItemRender={(state, value) => state.Name.toLowerCase()
                          .indexOf(value.toLowerCase()) !== -1}
                        renderItem={(item, isHighlighted) => (
                          <div
                            key={item.Name}
                            style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                            className="autocomplete"
                            id={`City-${item.Name}`}
                            name={`City-${item.Name}`}
                            data-testid={`City-${item.Name}`}
                          >
                            {item.Name}
                          </div>
                        )}
                        renderMenu={(items, value, style) => (
                          <div className="container-properties" style={{ ...style }}>
                            {items.map((i) => i)}
                          </div>
                        )}
                      />
                      <Label>
                        Cidade
                      </Label>
                    </>
                  ) : (
                    <InputFilterLoadingComponent />
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="container-autocomplete">
                  {filter.districts && listDistrict.state === false ? (
                    <>
                      <ReactAutocomplete
                        inputProps={{
                          disabled: filter.disabledDistrict,
                          onClick: (e) => handleClearField(e, 'Districts'),
                        }}
                        items={filter.districts}
                        name="District"
                        value={filter.Districts}
                        id="District"
                        getItemValue={(item) => item.Name}
                        onChange={(e) => {
                          handleDistrict(e, e.target.value, true);
                          Mixpanel.trackEvent(enterpriseFilterNeightborhood());
                        }}
                        onSelect={(value) => { handleDistrict(null, value, true); }}
                        shouldItemRender={(state, value) => state.Name.toLowerCase()
                          .indexOf(value.toLowerCase()) !== -1}
                        renderItem={(item, isHighlighted) => (
                          <div
                            key={item.Name}
                            style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                            className="autocomplete"
                            id={`Districts-${item.Name}`}
                            name={`Districts-${item.Name}`}
                            data-testid={`Districts-${item.Name}`}
                          >
                            {item.Name}
                          </div>
                        )}
                        renderMenu={(items, value, style) => (
                          <div className="container-properties" style={{ ...style }}>
                            {items.map((i) => i)}
                          </div>
                        )}
                      />
                      <Label>
                        Bairro
                      </Label>
                    </>
                  ) : (
                    <InputFilterLoadingComponent />
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="container-autocomplete">
                  {filter.developers && listDevelopers.state === false ? (
                    <>
                      <ReactAutocomplete
                        inputProps={{
                          onClick: (e) => handleClearField(e, 'DevelopersName'),
                        }}
                        items={filter.developers}
                        name="Developers"
                        value={filter.DevelopersName}
                        id="Developers"
                        getItemValue={(item) => item.Id}
                        onChange={handleDeveloperName}
                        onSelect={(value, item) => { handleDeveloper(null, value, item); }}
                        shouldItemRender={(state, value) => state.Name.toLowerCase()
                          .indexOf(value.toLowerCase()) !== -1}
                        renderItem={(item, isHighlighted) => (
                          <div
                            key={item.Id}
                            style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                            className="autocomplete"
                            id={`Developers-${item.Id}`}
                            name={`Developers-${item.Id}`}
                            data-testid={`Developers-${item.Id}`}
                          >
                            {item.Name}
                          </div>
                        )}
                        renderMenu={(items, value, style) => (
                          <div className="container-properties" style={{ ...style }}>
                            {items.map((i) => i)}
                          </div>
                        )}
                      />
                      <Label>
                        Incorporadora
                      </Label>
                    </>
                  ) : (
                    <InputFilterLoadingComponent />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="text"
                    required="required"
                    value={brlCurrencies(filter.Price || 0)}
                    id="Price"
                    name="Price"
                    onBlur={(e) => handlePrice(e, e.target.value)}
                    onChange={(e) => onChangePrice(e, e.target.value)}
                  />
                  <Label>
                    Preço Máximo
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="select"
                    id="Rooms"
                    name="Rooms"
                    value={filter.Rooms}
                    onChange={handleRooms}
                  >
                    {Quantities && Quantities.map((value) => (
                      <option value={value} key={`OptionRooms-${value}`}>{`${value}+`}</option>
                    ))}
                  </Input>
                  <Label>
                    Quartos
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="select"
                    id="ParkingSpots"
                    name="ParkingSpots"
                    value={filter.ParkingSpots}
                    onChange={handleParkingSpots}
                  >
                    {Quantities && Quantities.map((value) => (
                      <option value={value} key={`OptionRooms-${value}`}>{`${value}+`}</option>
                    ))}
                  </Input>
                  <Label>
                    Vagas
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="text"
                    required="required"
                    value={brlCurrencies(filter.Area || 0)}
                    id="Area"
                    name="Area"
                    data-testid="Area"
                    onBlur={handleArea}
                    onChange={(e) => onChangeArea(e, e.target.value)}
                  />
                  <Label>
                    Área
                  </Label>
                </Col>
              </Row>
            </>
          ) : (
            <>
              {ordination.map((ord) => (
                <Row key={ord.title}>
                  <Col>
                    <Button type="button" onClick={(e) => handleChangeOrdination(e, ord.value)} className={`order-button ${ord.value === ordination.filter((x) => x.value === filter.Ordination)[0].value ? 'btn_web_quatenary w-100 mb-3' : 'btn_web_simple w-100 mb-3'}`}>
                      {ord.title}
                    </Button>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form>
        <Row>
          <Col lg={12} className="col-button-filter">
            <Button
              className="btn_web_primary w-100 mt-3"
              type="submit"
              onClick={handleFilterSubmit}
            >
              {filter.IsOrdination ? 'Ordenar' : 'Filtrar'}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

ModalFilterEnteprise.defaultProps = {
  isOpen: false,
  toggle: null,
  filters: {},
};

ModalFilterEnteprise.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  filters: PropTypes.shape({}),
  handleState: PropTypes.func.isRequired,
  handleChangeOrdination: PropTypes.func.isRequired,
  handleCity: PropTypes.func.isRequired,
  handleDistrict: PropTypes.func.isRequired,
  handleDeveloper: PropTypes.func.isRequired,
  handlePrice: PropTypes.func.isRequired,
  onChangePrice: PropTypes.func.isRequired,
  handleRooms: PropTypes.func.isRequired,
  handleParkingSpots: PropTypes.func.isRequired,
  handleArea: PropTypes.func.isRequired,
  onChangeArea: PropTypes.func.isRequired,
  handleFilterSubmit: PropTypes.func.isRequired,
  handleDeveloperName: PropTypes.func.isRequired,
  handleClearField: PropTypes.func.isRequired,
  Quantities: PropTypes.arrayOf(PropTypes.string).isRequired,
  listState: PropTypes.shape({
    state: PropTypes.bool,
  }).isRequired,
  listCity: PropTypes.shape({
    state: PropTypes.bool,
  }).isRequired,
  listDistrict: PropTypes.shape({
    state: PropTypes.bool,
  }).isRequired,
  listDevelopers: PropTypes.shape({
    state: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);

const mapStateToProps = () => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalFilterEnteprise));
