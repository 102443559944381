import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Form,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { authDataUser } from '../../actions/auth';
import { localSet } from '../../lib/session';
import Config from '../../config/environments';
import Loading from '../utils/loading';
import Model from '../../model/login';
import { AUTH_TOKEN, IS_LOGGED } from '../../actionTypes/auth';
import LOGIN from '../../const/login';

class Login extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { ...Model, Redirected: false };
  }

  handleSubmit(event) {
    event.preventDefault();

    const p = this.props;

    p.authDataUser(this.state);
  }

  render() {
    const { authUser } = this.props;
    const { Redirected, Email, Password } = this.state;

    if (authUser && authUser.state !== false) {
      return <Loading msg={LOGIN.LOADING} />;
    }

    if (authUser && authUser.state === false && Redirected) {
      if (authUser && authUser.data) {
        localSet(AUTH_TOKEN, authUser.data, Config.EXPIRATION_AUTH);
        localSet(IS_LOGGED, 'true', Config.EXPIRATION_AUTH);
        return <Redirect to={{ pathname: '/' }} />;
      }
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Row className="justify-content-center">
              <Col md="8">
                <CardGroup>
                  <Card className="p-4">
                    <CardBody>
                      <h1>{LOGIN.TITLE}</h1>
                      <p className="text-muted">{LOGIN.SUB_TITLE}</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Email"
                          id="Email"
                          name="Email"
                          value={Email}
                          onChange={(e) => this.setState({ Email: e.target.value })}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Senha"
                          id="Password"
                          name="Password"
                          value={Password}
                          onChange={(e) => this.setState({ Password: e.target.value })}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4">
                            {LOGIN.LOGIN_BUTTON}
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0">
                            {LOGIN.FORGOT_BUTTON}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card
                    className="text-white bg-primary py-5 d-md-down-none"
                    style={{ width: `${44}%` }}
                  >
                    <CardBody className="text-center">
                      <div>
                        <h2>Admin</h2>
                      </div>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  authDataUser: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    authDataUser,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  authUser: state.authState.authUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
