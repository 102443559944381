import React from 'react';
import Skeleton from 'react-loading-skeleton';

const postLoading = () => {
  const loading = (
    <div
      className="mr-3 text-left"
      style={{
        width: 277, height: 410, border: 'solid 1px #ddd', borderRadius: '10px',
      }}
    >
      <Skeleton
        count={1}
        height={160}
        style={{
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          paddingTop: 3,
        }}
      />
      <div className="p-3">
        <Skeleton
          className="mb-1"
          count={1}
          width={200}
          height={16}
          style={{
          }}
        />
        <Skeleton
          count={1}
          width={150}
          height={16}
          style={{
          }}
        />
        <div style={{ height: 10 }}>
          <Skeleton
            className="mt-3"
            count={1}
            width={120}
            height={8}
            style={{
            }}
          />
        </div>
        <div style={{ height: 1 }}>
          <Skeleton
            className="mt-3"
            count={1}
            width={250}
            height={1}
            style={{
            }}
          />
        </div>
        <Skeleton
          className="mt-4"
          count={1}
          width={240}
          height={8}
          style={{
          }}
        />
        <Skeleton
          className="mt-0"
          count={1}
          width={240}
          height={8}
          style={{
          }}
        />
        <div style={{ height: 10 }}>
          <Skeleton
            className="mt-0"
            count={1}
            width={120}
            height={8}
            style={{
            }}
          />
        </div>
        <div style={{ height: 1 }}>
          <Skeleton
            className="mb-0"
            count={1}
            width={250}
            height={1}
            style={{
            }}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Skeleton
            className="m-2"
            count={6}
            width={25}
            height={8}
            style={{
            }}
          />
        </div>
        <div className="align-center">
          <Skeleton
            className="mt-0"
            count={1}
            width={200}
            height={50}
            style={{
              borderRadius: 30,
            }}
          />
        </div>

      </div>

    </div>

  );

  return loading;
};

postLoading.propTypes = {
};

export default postLoading;
