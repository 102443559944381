import { axiosApiHomer as axios } from '../lib/axios';
import Config from '../config/environments';
import {
  LIST_STATE, LIST_CITY, LIST_DISTRICT, LIST_DEVELOPERS,
} from '../actionTypes/filter';
import { viewsModelStateToModelsState } from '../mapper/filter';
import updateState from './common/common';

export const getDataState = () => async (dispatch) => {
  const base = {
    type: LIST_STATE,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.POST.DEVELOPMENTS}${Config.LOCATION.URL}${Config.FILTER_NEWCONSTRUTCION.STATE}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data.unshift({ name: 'Todos' });
    base.payload.data = viewsModelStateToModelsState(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const getDataCity = (data) => async (dispatch) => {
  const base = {
    type: LIST_CITY,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.POST.DEVELOPMENTS}${Config.LOCATION.URL}${Config.FILTER_NEWCONSTRUTCION.CITY}${data}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data.unshift({ name: 'Todos' });
    base.payload.data = viewsModelStateToModelsState(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const clearDataCity = () => async (dispatch) => {
  const base = {
    type: LIST_CITY,
    payload: {
      data: null,
      state: false,
    },
    state: true,
    success: true,
  };

  dispatch(base);
};

export const getDataDistrict = (data) => async (dispatch) => {
  const base = {
    type: LIST_DISTRICT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.POST.DEVELOPMENTS}${Config.LOCATION.URL}${Config.FILTER_NEWCONSTRUTCION.DISCTRICT}${data.City}&state=${data.State}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data.unshift({ name: 'Todos' });
    base.payload.data = viewsModelStateToModelsState(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const clearDataDistrict = () => async (dispatch) => {
  const base = {
    type: LIST_DISTRICT,
    payload: {
      data: null,
      state: false,
    },
    state: true,
    success: true,
  };

  dispatch(base);
};

export const getDataDevelopers = () => async (dispatch) => {
  const base = {
    type: LIST_DEVELOPERS,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.POST.REAL_ESTATE}${Config.POST.DEVELOPERS}/q?limit=700&offset=0`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data.results.unshift({ id: '1', name: 'Todos' });
    base.payload.data = viewsModelStateToModelsState(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};
