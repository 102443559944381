import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  CardBody,
  Button,
  Input,
  Label,
  Card,
  Form,
  Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Model from '../../../model/contact';
import { PhoneCompleteMask } from '../../../lib/mask';
import { postDataContactOtherPost, postDataContactAgentPost } from '../../../actions/contact';
import { modelToViewModel } from '../../../mapper/contact';
import { getSourceIdBasedOnPage } from '../../../lib/utils';
import Contact from '../../../const/contactResultSeleto';
import Mixpanel from '../../../mixpanel';
import ModalSuccess from '../modal/modalSuccess';

class ContactResultSeleto extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { ...Model, loading: false, isOpen: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  componentWillUnmount() {
    this.setState({ Requested: false });
  }

  handleModal(event) {
    event.preventDefault();
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  handleSubmit(event) {
    event.preventDefault();

    const p = this.props;
    const {
      location,
      getAgent,
      postId,
      ownerId,
    } = this.props;
    const { pathname } = location;
    const agent = getAgent && getAgent.data;
    const sourceId = getSourceIdBasedOnPage(pathname);
    this.state.PostId = postId;
    const state = modelToViewModel(this.state, sourceId, agent);
    if (state.SiteOwnerUserId !== ownerId) {
      p.postDataContactOtherPost(state);
    } else {
      p.postDataContactAgentPost(state);
    }

    this.setState({
      Requested: true, loading: true, Name: '', Email: '', Phone: '', Message: '',
    });
  }

  render() {
    const {
      statePostContact, location, getAgent,
    } = this.props;
    const {
      Name, Phone, Email, Message, Requested, PostId, loading,
      isOpen,
    } = this.state;

    const agent = getAgent && getAgent.data;
    const { pathname } = location;
    const sourceId = getSourceIdBasedOnPage(pathname);
    const message = (statePostContact && statePostContact.data) || {};
    if (statePostContact && statePostContact.state === false && Requested) {
      if (statePostContact.data) {
        toast.success('Sua mensagem foi enviada! Entraremos em contato o mais breve possível.');
        Mixpanel.track('form_submit', {
          local_message_id: sourceId,
          post_id: PostId || '',
          user_id: agent.UserId,
          site_message_id: message.MessageId,
        });
        this.setState({ Requested: false, loading: false, isOpen: true });
      } else {
        toast.error('Ocorreu um erro! :(');
      }
    }

    return (
      <>
        <ModalSuccess
          isOpen={isOpen}
          toggle={this.handleModal}
        />
        <Card className="card-label-card">
          <Form onSubmit={this.handleSubmit}>
            <CardBody>
              <Label htmlFor="Name">{Contact.NAME}</Label>
              <Input
                type="text"
                required="required"
                maxLength="100"
                minLength="3"
                id="Name"
                name="Name"
                value={Name}
                placeholder="Insira seu nome"
                onChange={(e) => this.setState({ Name: e.target.value.trimStart() })}
              />
              <Label htmlFor="Email">{Contact.EMAIL}</Label>
              <Input
                type="email"
                required="required"
                maxLength="100"
                minLength="3"
                id="Email"
                name="Email"
                value={Email}
                placeholder="Insira seu e-mail"
                onChange={(e) => this.setState({ Email: e.target.value.trimStart() })}
              />
              <Label htmlFor="Phone">{Contact.TELEPHONE}</Label>
              <Input
                type="text"
                required="required"
                minLength="15"
                maxLength="15"
                id="Phone"
                name="Phone"
                value={Phone}
                placeholder="Insira seu telefone"
                onChange={(e) => this.setState({
                  Phone: PhoneCompleteMask(e.target.value.trimStart()),
                })}
              />
              <Label>{Contact.MESSAGE_LABEL}</Label>
              <Input
                type="textarea"
                className="textarea-none"
                required="required"
                maxLength="100"
                id="Message"
                name="Message"
                value={Message}
                placeholder="Olá, fiquei interessado no seu imóvel e gostaria de saber maiores detalhes"
                onChange={(e) => this.setState({ Message: e.target.value.trimStart() })}
              />
              <Button className="btn-secondary-broker" disabled={loading}>
                { loading
                  ? (
                    <>
                      <div>
                        <Spinner animation="border" size="sm" variant="danger" />
                        <Label className="sendEmail">Enviando</Label>
                      </div>
                    </>
                  )
                  : 'Fale com o Homer'}
              </Button>
              <p className="p-span">
                {Contact.ONCLICK}
                <a target="_blank" rel="noreferrer" href="https://www.homer.com.br/termos/termodeuso/" className="a-text-decoration">{Contact.TERMS}</a>
                {Contact.AND}
                <a target="_blank" rel="noreferrer" href="https://www.homer.com.br/termos/politicadeprivacidade/" className="a-text-decoration">{Contact.PRIVACY}</a>
              </p>
            </CardBody>
          </Form>
        </Card>
      </>
    );
  }
}

ContactResultSeleto.defaultProps = {
  ownerId: null,
};

ContactResultSeleto.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  postId: PropTypes.string.isRequired,
  ownerId: PropTypes.number,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
  }).isRequired,
  postDataContactOtherPost: PropTypes.func.isRequired,
  postDataContactAgentPost: PropTypes.func.isRequired,
  getAgent: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  statePostContact: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    postDataContactOtherPost,
    postDataContactAgentPost,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  getAgent: state.agentState.getAgent,
  statePostContact: state.contactState.statePostContact,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactResultSeleto));
