/* eslint-disable no-param-reassign */
import Model from '../model/enterprise';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    const price = view.minimumPrice && view.minimumPrice.display
      ? view.minimumPrice.display : 'R$ 0';
    const development = [view.developmentStatus];

    model.Location = view.location && (view.location.prettyAddress
      ? view.location.prettyAddress : view.location);
    model.Description = view.description;
    model.Latitude = view && view.location && view.location && view.location.latitude;
    model.Longitude = view && view.location && view.location && view.location.longitude;
    model.EnterpriseName = view.name;
    model.DeveloperName = view.developer.name;
    model.Phone = view.developer.phoneNumber;
    model.Relevant = view.relevant;
    model.UpdatedDateTime = view.updatedAt;
    model.CreatedDateTime = view.createdAt;
    model.Features = (view.features && view.features.length > 0
      && view.features.map(({ iconUrl, name }) => ({ Url: iconUrl, Text: name }))) || [];
    model.DevelopmentStatus = (development && development.length > 0
       && development.map(({ iconUrl, name }) => ({ Url: iconUrl, Text: name }))[0]) || [];
    model.UnitInformation = (view.unitsInformation && view.unitsInformation.length > 0
      && view.unitsInformation.map(({ iconUrl, text, name }) => (
        { Url: iconUrl, Text: text || 0, Name: name }))) || [];
    model.Typologies = (view.typologies && view.typologies.length > 0
       && view.typologies.map(({ iconUrl, name }) => ({ Url: iconUrl, Text: name }))) || [];
    model.PostMedia = (view.medias && view.medias.length > 0
      && view.medias.map(({ order, url }) => ({ Order: order, MediaUrl: url }))) || [];
    model.PostId = view.id;
    model.Comission = view.commissionDescription;
    model.MaximumRooms = view.maximumRoomsCount;
    model.MinimumRooms = view.minimumRoomsCount;
    model.MaximumParkingSpots = view.maximumParkingSpacesCount;
    model.MinimumParkingSpots = view.minimumParkingSpacesCount;
    model.MinimumPrice = (view.minimumPrice && view.minimumPrice.ammount) || 0;
    model.MaximumPrice = (view.maximumPrice && view.maximumPrice.ammount) || 0;
    model.PriceLabelText = price;

    return model;
  } catch (error) {
    throw new Error(`EnterpriseMapper - ViewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models) => {
  try {
    if (!models) return null;
    const views = [];

    models.results.forEach(async (model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    const total = models.total || 0;

    const viewsPag = {
      total,
      list: views,
    };

    return viewsPag;
  } catch (error) {
    throw new Error(`EnterpriseMapper - ViewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    view.clientEmail = model.form && model.form.Email;
    view.clientName = model.form && model.form.Name;
    view.clientPhone = model.form && model.form.Telephone ? `${model.form.Telephone.replace(/\D/g, '')}` : '';
    view.messageSourceId = model.sourceId;
    view.userId = model.userDetails && model.userDetails.UserId;

    return view;
  } catch (error) {
    throw new Error(`EnterpriseMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`EnterpriseMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Name = view.Name || '';

    return model;
  } catch (error) {
    throw new Error(`EnterpriseMapper - ViewModelToState = ${error}`);
  }
};

export const modelToFilter = (model) => {
  try {
    if (!model) return null;

    let view = '';

    model.Filter.forEach((filter) => {
      const id = filter.id.toLowerCase();

      if (id.includes('document')) {
        view += `${id}=${encodeURIComponent(filter.value.replace(/\D/g, ''))}&`;
      } else {
        view += `${id}=${encodeURIComponent(filter.value)}&`;
      }
    });

    model.Sort.forEach((sort) => {
      const id = sort.id.toLowerCase();
      view += `sort=${id}&`;
      view += `desc=${sort.desc}&`;
    });

    view += `page=${model.Page + 1}&`;
    view += `quantity=${model.PageSize}`;

    return view;
  } catch (error) {
    throw new Error(`EnterpriseMapper - ModelToFilter = ${error}`);
  }
};
