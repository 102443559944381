import { POST_AGENTDETAILS, LIST_AGENTDETAILS, GET_AGENTDETAILS } from '../actionTypes/agentdetails';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

export const INITIAL_STATE = {
  statePostAgentDetails: BASE,
  listAgentDetails: BASE_PAGINATION,
  getAgentDetails: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_AGENTDETAILS:
      return { ...state, statePostAgentDetails: action.payload };
    case LIST_AGENTDETAILS:
      return { ...state, listAgentDetails: action.payload };
    case GET_AGENTDETAILS:
      return { ...state, getAgentDetails: action.payload };
    default:
      return state;
  }
};
