import { localGet, localRemove } from './session';
import { AUTH_TOKEN, IS_LOGGED } from '../actionTypes/auth';
import Config from '../config/environments';

export default (data) => {
  const config = data;
  const authData = localGet(AUTH_TOKEN);

  if (!authData || !authData.Token) {
    localRemove(AUTH_TOKEN);
    localRemove(IS_LOGGED);
  }

  // if (authData && authData.Token) {
  //  config.headers['X-Token'] = authData.Token;
  // }

  config.headers['X-Token'] = Config.SECURITY.TOKEN;

  return config;
};
