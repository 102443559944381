export default {
  EXPERIENCE: 'Queremos tornar a experiência de compra evenda do imóvel rápida e descomplicada, ao empoderar os corretores de imóveis através de tecnologia e design.',
  SOLUTIOINS: 'Soluções',
  PARTNERSHIPS: 'Homer Parcerias',
  FUNDRAISING: 'Homer Captações',
  CREDIT: 'Crédito Homer',
  ABOUT: 'Sobre Homer',
  WHO: 'Quem somos',
  MEDIA: 'Homer na Mídia',
  BLOG: 'Blog',
  SERVICE: 'Atendimento',
  CONTACT: 'Fale conosco',
  QUESTIONS: 'Perguntas frequentes',
  CONVERSATION: 'Agendar conversa',
  INSTITUTIONAL: 'Institucional',
  TERMS: 'Termos de uso',
  PRIVACY: 'Política de privacidade',
  NETWORKS: 'Homer nas redes',
  COPYRIGHT: 'Site desenvolvido pelo Homer | O Parceiro N.º 1 do Corretor',
  HOMER_CRECI: 'CRECI/SP 036093-J',
  SEE_OTHERS: 'Ver outros →',
  HOMER_COMPANY_NAME: 'Homeflix Serviços Imobiliários LTDA',
};
