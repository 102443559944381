/*
* required polyfills
*/

/** IE9, IE10 and IE11 requires all of the following polyfills. * */
// import 'core-js/features/symbol'
// import 'core-js/features/object'
// import 'core-js/features/function'
// import 'core-js/features/parse-int'
// import 'core-js/features/parse-float'
// import 'core-js/features/number'
// import 'core-js/features/math'
// import 'core-js/features/string'
// import 'core-js/features/date'
import 'core-js/features/array';
// import 'core-js/features/regexp'
import 'core-js/features/map';
// import 'core-js/features/weak-map'
import 'core-js/features/set';
import 'core-js/features/object';

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/features/reflect'

/** Evergreen browsers require these. * */
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
// import 'core-js/es7/reflect'

// CustomEvent() constructor functionality in IE9, IE10, IE11
// eslint-disable-next-line func-names
(function () {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    const paramsUpdated = params || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
      event,
      paramsUpdated.bubbles,
      paramsUpdated.cancelable,
      paramsUpdated.detail,
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
  return true;
}());
