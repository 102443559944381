import Model from '../model/user';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.UserId = view.UserId;
    model.Token = view.Token;

    return model;
  } catch (error) {
    throw new Error(`UserMapper - viewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models, state) => {
  try {
    if (!models) return null;

    const views = [];

    models.result.forEach((model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    const upState = state || {};
    const division = parseInt(models.total / upState.PageSize, 10);
    const remainder = models.total % upState.PageSize;

    const viewsPag = {
      total: division + (remainder <= 0 ? 0 : 1),
      list: views,
    };

    return viewsPag;
  } catch (error) {
    throw new Error(`UserMapper - viewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    view.uuid = model.Id;
    view.email = model.Email;
    view.name = model.Name;
    view.type = model.Type;
    view.password = model.Password;
    view.origin = 'IBEST';

    return view;
  } catch (error) {
    throw new Error(`UserMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`UserMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Id = view.Id || '';
    model.Email = view.Email || '';
    model.Name = view.Name || '';
    model.Type = view.Type || '';
    model.Password = view.Password || '';
    model.Token = view.Token || '';

    return model;
  } catch (error) {
    throw new Error(`UserMapper - viewModelToState = ${error}`);
  }
};

export const modelToAuthViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    view.email = model.Email;
    view.password = model.Password;

    return view;
  } catch (error) {
    throw new Error(`UserMapper - modelToAuthViewModel = ${error}`);
  }
};

export const modelToResetViewModel = (model, token) => {
  try {
    if (!model) return null;

    const view = {};

    view.token = token;
    view.password = model.Password;

    return view;
  } catch (error) {
    throw new Error(`UserMapper - modelToAuthViewModel = ${error}`);
  }
};

export const modelToFilter = (model) => {
  try {
    if (!model) return null;

    let view = '';

    model.Filter.forEach((filter) => {
      const id = filter.id.toLowerCase();

      if (id.includes('document')) {
        view += `${id}=${encodeURIComponent(filter.value.replace(/\D/g, ''))}&`;
      }
      if (id.includes('type') && !filter.value.includes('all')) {
        view += `${id}=${encodeURIComponent(filter.value)}&`;
      }
      if (!id.includes('document') && !id.includes('type')) {
        view += `${id}=${encodeURIComponent(filter.value)}&`;
      }
    });

    model.Sort.forEach((sort) => {
      const id = sort.id.toLowerCase();
      view += `sort=${id}&`;
      view += `desc=${sort.desc}&`;
    });

    view += `page=${model.Page + 1}&`;
    view += `quantity=${model.PageSize}`;

    return view;
  } catch (error) {
    throw new Error(`UserMapper - ModelToFilter = ${error}`);
  }
};
