/* eslint-disable prefer-destructuring */
import removeAccents from 'remove-accents';
import ModelState from '../model/state';
import ModelCity from '../model/city';
import Config from '../config/environments';

export const findStr = (array, str) => {
  try {
    if (!array || !str) return null;

    let val = '';

    array.forEach(async (vet) => {
      if (vet.Name) {
        const str1 = removeAccents(vet.Name.toLowerCase());
        const str2 = removeAccents(str.toLowerCase());

        if (str1 === str2) {
          val = vet.Name;
        }
      }
    });
    return val;
  } catch (error) {
    throw new Error(`Converter String - Not found respective element! = ${error}`);
  }
};

export const viewModelToModelFilter = (view) => {
  try {
    if (!view) return null;

    const model = { ...ModelCity };

    model.State = view.State ? view.State.replace(/[-]/g, ' ') : view.State;
    model.City = view.City ? view.City.replace(/[-]/g, ' ') : view.City;
    model.PropertyTypes = view.PropertyTypes;
    model.NewConstructions = view.NewConstructions;
    model.Price = view.Price ? view.Price.replace(/[.]/g, '') : view.Price;
    model.Districts = view.District ? view.District.replace(/[-]/g, ' ') : view.District;
    model.Page = view.NumberCardsPosts
      ? (view.NumberCardsPosts / 20) - 1 : (view.NumberCardsPosts / 20) - 1;
    model.Rooms = view.Rooms;
    model.ParkingSpots = view.ParkingSpots;

    return model;
  } catch (error) {
    throw new Error(`DisctrictMapper - ViewModelToModel = ${error}`);
  }
};

export const viewModelStateToModelState = (view) => {
  try {
    if (!view) return null;

    const model = { ...ModelState };

    model.Id = view.id;
    model.Name = view.name;

    return model;
  } catch (error) {
    throw new Error(`PropertyTypesMapper - ViewModelToModel = ${error}`);
  }
};

export const viewsModelStateToModelsState = (models, state) => {
  try {
    if (!models) return null;
    const views = [];

    const data = models && models.results ? models.results : models;

    data.forEach(async (model) => {
      const view = viewModelStateToModelState(model);

      views.push(view);
    });

    const upState = state || {};
    const division = parseInt(models.total / upState.PageSize, 10);
    const remainder = models.total % upState.PageSize;

    const viewsPag = {
      total: division + (remainder <= 0 ? 0 : 1),
      list: views,
    };

    return viewsPag;
  } catch (error) {
    throw new Error(`StateMapper - ViewsModelToModels = ${error}`);
  }
};

export const modelToSearch = (state, props) => {
  try {
    let view = '';
    const {
      Location, IsRent, Amount, QttRooms, QttParkings,
      PropertyType, PropertyTypeSearch, QttRoomsSearch,
    } = state;
    const { match, getGeo } = props;
    const { params } = match;
    const { slug, price } = params;
    const geo = getGeo && getGeo.data;
    view += `/${slug}`;
    view += '/search';
    view += `/${IsRent}`;
    view += `/${geo.Latitude}`;
    view += `/${geo.Longitude}`;
    view += `/${PropertyTypeSearch || PropertyType}`;
    const newAmount = Amount ? `/${Amount.replace(/[^0-9]/g, '')}` : '/500000000';
    view += price ? `/${price}` : newAmount;
    view += `/${QttRoomsSearch || QttRooms}`;
    view += `/${QttParkings}`;
    view += `/${encodeURIComponent(Location)}`;
    return view;
  } catch (error) {
    throw new Error(`HomeMapper - ModelToFilter = ${error}`);
  }
};

export const pagination = (model, page, isEmpty, coords) => {
  try {
    if (!model) return null;

    let view = '';

    view += `${Config.POST.OFFSET}${((page || model.NumberCardsPosts) - 20)}`;
    view += `${Config.POST.LIMIT}20`;
    view += model.Ordination ? `${Config.POST.SORT}${model.Ordination}` : '';
    view += model.State && model.State !== 'Todos' && model.State !== '' ? `${Config.POST.STATE}${model.State}` : '';
    view += model.City && model.City !== 'Todos' && model.City !== '' ? `${Config.POST.CITY}${model.City}` : '';
    view += model.Districts && model.Districts !== 'Todos' && model.Districts !== '' ? `${Config.POST.NEIGHBORHOOD}${model.Districts}` : '';
    view += model.Developer && model.Developer !== '1' && model.Developer !== 1 && model.Developer !== '' ? `${Config.POST.DEVELOPER}${model.Developer}` : '';
    view += (model.Price === 0 || model.Price === '0' || !!isEmpty || !model.Price) ? '' : `${Config.POST.MAXIMUM_PRICE}${model && model.Price ? model.Price.replaceAll('.', '') : ''}`;
    view += model && (!model.Rooms || model.Rooms === '0') ? '' : `${Config.POST.MINIMUM_ROOMS}${model && model.Rooms}`;
    view += model && (!model.ParkingSpots || model.ParkingSpots === '0') ? '' : `${Config.POST.MINIMUM_PARKINGS}${model && model.ParkingSpots}`;
    view += model && (model.Area === 0 || model.Area === '0' || !model.Area) ? '' : `${Config.POST.MINIMUM_LIVING_AREA}${model && model.Area}`;
    view += `${Config.POST.EMPTY_CITY}${!!isEmpty}`;
    view += isEmpty ? `${Config.POST.LAT}${coords && coords.latitude ? coords.latitude : Config.DEFAULT_VALUES.LATITUDE}${Config.POST.LNG}${coords && coords.longitude ? coords.longitude : Config.DEFAULT_VALUES.LONGITUDE}` : '';

    return view;
  } catch (error) {
    throw new Error(`PostMapper - pagination = ${error}`);
  }
};

export const paginationUrl = (model, isEmpty) => {
  try {
    if (!model) return null;

    let view = '';

    view += model.Ordination ? `${Config.POST.SORT}${model.Ordination}` : '';
    view += model.State && model.State !== 'Todos' && model.State !== '' ? `${Config.POST.STATE}${model.State}` : '';
    view += model.City && model.City !== 'Todos' && model.City !== '' ? `${Config.POST.CITY}${model.City}` : '';
    view += model.Districts && model.Districts !== 'Todos' && model.Districts !== '' ? `${Config.POST.NEIGHBORHOOD}${model.Districts}` : '';
    view += model.Developer && model.Developer !== '1' && model.Developer !== 1 && model.Developer !== '' ? `${Config.POST.DEVELOPER}${model.Developer}` : '';
    view += (model.Price === 0 || model.Price === '0' || !!isEmpty || !model.Price) ? '' : `${Config.POST.MAXIMUM_PRICE}${model && model.Price ? model.Price.replaceAll('.', '') : ''}`;
    view += model && (!model.Rooms || model.Rooms === '0') ? '' : `${Config.POST.MINIMUM_ROOMS}${model && model.Rooms}`;
    view += model && (!model.ParkingSpots || model.ParkingSpots === '0') ? '' : `${Config.POST.MINIMUM_PARKINGS}${model && model.ParkingSpots}`;
    view += model && (model.Area === 0 || model.Area === '0' || !model.Area) ? '' : `${Config.POST.MINIMUM_LIVING_AREA}${model && model.Area}`;

    return view;
  } catch (error) {
    throw new Error(`PostMapper - pagination = ${error}`);
  }
};

export const paginationToState = (state) => {
  try {
    if (!state) return null;

    const model = { ...ModelCity };

    if (state && state.includes('&')) {
      const filter = state.split('&');

      filter.forEach((x) => {
        const value = x.split('=');

        if (x.includes('sort')) {
          model.Ordination = value[1];
        }

        if (x.includes('state')) {
          model.State = value[1];
        }

        if (x.includes('city')) {
          model.City = value[1];
        }

        if (x.includes('neighborhood')) {
          model.Districts = value[1];
        }

        if (x.includes('developer')) {
          model.Developer = value[1];
        }

        if (x.includes('maximumPrice')) {
          model.Price = value[1];
        }

        if (x.includes('minimumRooms')) {
          model.Rooms = value[1];
        }

        if (x.includes('minimumParkingSpaces')) {
          model.ParkingSpots = value[1];
        }

        if (x.includes('minimumLivingArea')) {
          model.Area = value[1];
        }
      });
    }

    return model;
  } catch (error) {
    throw new Error(`PostMapper - paginationToState = ${error}`);
  }
};
