export default {
  Location: '',
  GooglePlaceId: '',
  IsRent: false,
  Amount: '',
  QttRooms: 0,
  QttParkings: 0,
  RequestedAgent: false,
  RequestedAgentDetails: false,
  RequestedPost: false,
  RequestedProperties: false,
  RequestedPostDetails: false,
  RequestedPostSeleto: false,
  RequestedAgentSeleto: false,
  IsOpen: false,
};
