export default {
  Uuid: '',
  UserId: '',
  PostId: '',
  Area: '',
  IsRent: '',
  Location: '',
  PostMedia: '',
  ParkingSpots: '',
  Price: 0,
  Tags: '',
  Rooms: 0,
  PropertyType: '',
  IsHomerSell: '',
  Description: '',
  CondoFee: 0,
  PropertyTaxes: 0,
};
