import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Contact from '../../../const/contactseleto';
import Model from '../../../model/contact';
import { PhoneCompleteMask } from '../../../lib/mask';
import { postDataMessage } from '../../../actions/contact';
import { modelToViewModelContact } from '../../../mapper/contact';
import { getSourceIdBasedOnPage } from '../../../lib/utils';
import Mixpanel from '../../../mixpanel';
import ModalSuccess from '../modal/modalSuccess';
import {
  homeDoubtEmail, homeDoubtLinkPrivacy, homeDoubtLinkTerms, homeDoubtMessage, homeDoubtName,
  homeDoubtSend, homeDoubtTelephone,
} from '../../../const/mixpanel';

class ContactSeleto extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { ...Model, loading: false, isOpen: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  componentWillUnmount() {
    this.setState({ Requested: false });
  }

  handleModal(event) {
    event.preventDefault();
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  handleSubmit(event) {
    event.preventDefault();

    const p = this.props;
    const {
      location,
      getAgent,
      postId,
    } = this.props;
    const { pathname } = location;
    const agent = getAgent && getAgent.data;
    const sourceId = getSourceIdBasedOnPage(pathname);
    const state = modelToViewModelContact(this.state, sourceId, agent);

    p.postDataMessage(state);
    Mixpanel.trackEvent(homeDoubtSend());

    this.setState({
      Requested: true, loading: true, PostId: postId, Name: '', Email: '', Phone: '', Message: '',
    });
  }

  render() {
    const {
      isSeleto,
      isMobile,
      getAgent,
      statePostContact,
      postId,
      OwnerId,
      location,
    } = this.props;
    const {
      Name, Phone, Email, Message, Requested, PostId, isOpen, loading,
    } = this.state;

    if (!isSeleto) {
      return null;
    }

    const agent = getAgent && getAgent.data;

    const { pathname } = location;
    const sourceId = getSourceIdBasedOnPage(pathname);
    const message = (statePostContact && statePostContact.data) || {};
    if (statePostContact && statePostContact.state === false && Requested) {
      if (statePostContact.data) {
        toast.success('Sua mensagem foi enviada! Entraremos em contato o mais breve possível.');
        Mixpanel.track('form_submit', {
          local_message_id: sourceId,
          post_id: PostId || '',
          user_id: agent.UserId,
          site_message_id: message.MessageId,
        });
        this.setState({
          Requested: false, loading: false, isOpen: true,
        });
      } else {
        toast.error('Ocorreu um erro! :(');
      }
    }

    return (
      <>
        <ModalSuccess
          isOpen={isOpen}
          toggle={this.handleModal}
        />
        <div className="app div-size">
          <Container className="body">
            <Form onSubmit={(e) => this.handleSubmit(e, postId, OwnerId)}>
              {!isMobile ? (
                <Row className="justify-content-center align-center">
                  <Col md={9}>
                    <div className="text-doubt">
                      <h4>{Contact.TITLE}</h4>
                      <h4 className="h4-underline">{`Homer ${Contact.HELPER}`}</h4>
                    </div>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row className="justify-content-center align-center">
                    <Col md={9}>
                      <div className="text-doubt">
                        <h4 className="margin-bottom">{Contact.TITLE}</h4>
                      </div>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-center">
                    <Col md={9}>
                      <div className="text-doubt">
                        <h4 className="h4-underline padding-top">{`Homer ${Contact.HELPER}`}</h4>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="justify-content-center">
                <Col md={5}>
                  <div className="input-group">
                    <Label htmlFor="Name">Nome:</Label>
                    <Input
                      type="text"
                      required="required"
                      maxLength="100"
                      minLength="3"
                      id="Name"
                      name="Name"
                      value={Name}
                      placeholder="Insira seu nome"
                      onChange={(e) => {
                        this.setState({ Name: e.target.value.trimStart() });
                        Mixpanel.trackEvent(homeDoubtName());
                      }}
                    />
                    <Label htmlFor="Email">E-mail:</Label>
                    <Input
                      type="email"
                      required="required"
                      maxLength="100"
                      minLength="3"
                      id="Email"
                      name="Email"
                      value={Email}
                      placeholder="Insira seu e-mail"
                      onChange={(e) => {
                        this.setState({ Email: e.target.value.trimStart() });
                        Mixpanel.trackEvent(homeDoubtEmail());
                      }}
                    />
                    <Label htmlFor="Phone">Telefone:</Label>
                    <Input
                      type="text"
                      required="required"
                      minLength="15"
                      maxLength="15"
                      id="Phone"
                      name="Phone"
                      value={Phone}
                      placeholder="Insira seu telefone"
                      onChange={(e) => {
                        this.setState({
                          Phone:
                          PhoneCompleteMask(e.target.value.trimStart()),
                        });
                        Mixpanel.trackEvent(homeDoubtTelephone());
                      }}
                    />
                  </div>
                </Col>
                <Col md={5}>
                  <div className="input-group">
                    <Label htmlFor="Message">Mensagem:</Label>
                    <Input
                      type="textarea"
                      style={{ resize: 'none' }}
                      required="required"
                      maxLength="100"
                      id="Message"
                      name="Message"
                      value={Message}
                      placeholder="Olá, quero tirar dúvidas."
                      onChange={(e) => {
                        this.setState({ Message: e.target.value.trimStart() });
                        Mixpanel.trackEvent(homeDoubtMessage());
                      }}
                    />
                    <p>
                      {Contact.ONCLICK}
                      <a href="https://www.homer.com.br/termos/termodeuso/" onClick={() => { Mixpanel.trackEvent(homeDoubtLinkTerms()); }} target="_blank" rel="noreferrer" className="blue-text">{Contact.TERMS}</a>
                      {Contact.AND}
                      <a href="https://www.homer.com.br/termos/politicadeprivacidade/" onClick={() => { Mixpanel.trackEvent(homeDoubtLinkPrivacy()); }} target="_blank" rel="noreferrer" className="blue-text">{Contact.PRIVACY}</a>
                    </p>
                    <Button disabled={loading}>
                      {loading
                        ? (
                          <>
                            <Spinner animation="border" size="sm" variant="danger" className="loading-red" />
                            <Label className="sendEmail">Enviando</Label>
                          </>
                        )
                        : Contact.SUBMIT}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </>
    );
  }
}

ContactSeleto.defaultProps = {
  isSeleto: false,
  isMobile: false,
};

ContactSeleto.propTypes = {
  isSeleto: PropTypes.bool,
  isMobile: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  postId: PropTypes.string.isRequired,
  OwnerId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
  }).isRequired,
  agentDetails: PropTypes.shape({
    FullName: PropTypes.string,
    FirstName: PropTypes.string,
    AvatarImagePath: PropTypes.string,
  }).isRequired,
  postDataMessage: PropTypes.func.isRequired,
  getAgent: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  statePostContact: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    postDataMessage,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  getAgent: state.agentState.getAgent,
  statePostContact: state.contactState.statePostContact,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactSeleto));
