import { Component } from 'react';
import PropTypes from 'prop-types';
import { FILTER_LIST_ENTERPRISE_PERSONAL } from '../../actionTypes/filter';
import { localRemove } from '../../lib/session';

class Base extends Component {
  constructor(props, context) {
    super(props, context);
    const { match } = props;
    const { path } = match;

    if (!(path.includes('empreendimento'))) {
      localRemove(FILTER_LIST_ENTERPRISE_PERSONAL);
    }
  }

  render() {
    return ('');
  }
}

Base.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
      postId: PropTypes.string,
      userId: PropTypes.string,
      postType: PropTypes.string,
    }),
    path: PropTypes.string,
  }).isRequired,
};

export default Base;
