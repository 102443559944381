import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Col,
  Modal,
  Row,
  Form,
  Button,
  Input,
  Label,
  FormGroup,
  Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Model from '../../../model/contact';
import { PhoneCompleteMask } from '../../../lib/mask';
import {
  setStatusPost,
  postDataMessage,
} from '../../../actions/contact';
import { modelToViewModelContact } from '../../../mapper/contact';
import { getSourceIdBasedOnPage } from '../../../lib/utils';
import Contact from '../../../const/contact';
import Mixpanel from '../../../mixpanel';
// import ModalSuccess from '../modal/modalSuccess';
import IconCheck from '../../../assets/icons/icon_check.svg';
import Close from '../../../assets/icons/close_button.svg';
import {
  enterpriseDetailsModalClose, enterpriseDetailsModalEmail, enterpriseDetailsModalLinkPrivacy,
  enterpriseDetailsModalLinkTerms, enterpriseDetailsModalMessage, enterpriseDetailsModalName,
  enterpriseDetailsModalTelephone, enterpriseModalClose, enterpriseModalEmail,
  enterpriseModalLinkPrivacy, enterpriseModalLinkTerms, enterpriseModalMessage,
  enterpriseModalName, enterpriseModalTalkWith, enterpriseModalTelephone,
  searchModalClose, searchModalEmail, searchModalLinkPrivacy, searchModalLinkTerms,
  searchModalMessage, searchModalName, searchModalTalkWith, searchModalTelephone,
  enterpriseDetailsModalTalkWith,
} from '../../../const/mixpanel';

class ContactModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ...Model,
      Requested: false,
      postId: props.PostId,
      ownerId: props.OwnerId,
      success: false,
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleModal = this.handleModal.bind(this);
    props.setStatusPost();
  }

  componentWillUnmount() {
    const p = this.props;
    p.setStatusPost();
    this.setState({ Requested: false });
  }

  handleSubmit(event) {
    event.preventDefault();
    const p = this.props;
    const {
      location,
      getAgent,
      PostId,
      OwnerId,
      Incorp,
      isSearch,
      isEnterprise,
      isEnterpriseDetail,
    } = this.props;
    const { pathname } = location;
    const agent = getAgent && getAgent.data;
    const sourceId = getSourceIdBasedOnPage(pathname);
    const state = modelToViewModelContact(this.state, sourceId, agent, PostId);

    if (isSearch) {
      Mixpanel.trackEvent(searchModalTalkWith());
    }
    if (isEnterprise) {
      Mixpanel.trackEvent(enterpriseModalTalkWith());
    }
    if (isEnterpriseDetail) {
      Mixpanel.trackEvent(enterpriseDetailsModalTalkWith());
    }

    if (Incorp) {
      const stateIncorp = modelToViewModelContact(this.state, 5, agent, PostId);
      p.postDataMessage(stateIncorp);
    } else {
      p.postDataMessage(state);
    }

    this.setState({
      Requested: true, postId: PostId, ownerId: OwnerId, loading: true,
    });
  }

  handleToggle(event, redirect) {
    event.preventDefault();
    const p = this.props;
    this.setState({ Requested: false, success: false });
    p.setStatusPost();
    p.toggle(event, redirect);
  }

  handleModal(event) {
    event.preventDefault();
    const { success } = this.state;
    this.setState({ success: !success });
  }

  render() {
    const {
      getAgentDetails, isOpen, OwnerId, statePostContact, location,
      isSearch, isEnterprise, isEnterpriseDetail, isVisit, isHomer,
    } = this.props;
    const p = this.props;
    const {
      Name, Phone, Email, Message, Requested, postId, success, loading,
    } = this.state;

    const { pathname } = location;
    const sourceId = getSourceIdBasedOnPage(pathname);
    const agentDetails = (getAgentDetails && getAgentDetails.data) || {};
    const message = (statePostContact && statePostContact.data) || {};
    if (statePostContact && statePostContact.state === false && Requested) {
      if (statePostContact.data) {
        Mixpanel.track('form_submit', {
          local_message_id: sourceId,
          post_id: postId || '',
          user_id: agentDetails.UserId,
          site_message_id: message.MessageId,
        });
        if (isHomer) {
          p.toggle(null, isHomer, isVisit);
        } else {
          this.setState({
            Requested: false,
            success: true,
            Name: '',
            Email: '',
            Phone: '',
            Message: '',
            loading: false,
          });
        }
      } else {
        toast.error('Ocorreu um erro! :(');
      }
    }

    return (
      <>
        <Modal isOpen={isOpen} toggle={this.handleToggle} className="modal-body">
          <Col md={12} className="container-close">
            <Button
              onClick={(e) => {
                if (isSearch) {
                  Mixpanel.trackEvent(searchModalClose());
                }
                if (isEnterprise) {
                  Mixpanel.trackEvent(enterpriseModalClose());
                }
                if (isEnterpriseDetail) {
                  Mixpanel.trackEvent(enterpriseDetailsModalClose());
                }
                this.handleToggle(e);
              }}
              className="buttonClose"
              id="close"
              name="close"
            >
              <img loading="lazy" src={Close} alt="Fechar modal" />
            </Button>
          </Col>
          <Form onSubmit={(e) => this.handleSubmit(e, postId, OwnerId)}>
            {success ? (
              <>
                <Row>
                  <Col md={12} className="content-modal-success">
                    <img loading="lazy" src={IconCheck} alt="Check" />
                    <Label className="label-success">Sucesso!</Label>
                    <Label className="label-success">Mensagem recebida.</Label>
                    <br />
                    <Label className="label-description">Em breve, o corretor responsável pelo site entrará em contato com você.</Label>
                    <br />
                    <Button className="button-back" onClick={this.handleToggle}>Voltar e buscar mais</Button>
                  </Col>
                </Row>
              </>
            )
              : (
                <Row className="justify-content-center">
                  <Col md={11} className="padding-modal">
                    <FormGroup>
                      <Label htmlFor="Name">{Contact.NAME}</Label>
                      <Input
                        type="text"
                        required="required"
                        maxLength="100"
                        minLength="3"
                        id="Name"
                        name="Name"
                        value={Name}
                        onChange={(e) => {
                          this.setState({ Name: e.target.value.trimStart() });
                          if (isSearch) {
                            Mixpanel.trackEvent(searchModalName());
                          }
                          if (isEnterprise) {
                            Mixpanel.trackEvent(enterpriseModalName());
                          }
                          if (isEnterpriseDetail) {
                            Mixpanel.trackEvent(enterpriseDetailsModalName());
                          }
                        }}
                      />
                      <Label htmlFor="Phone">{Contact.TELEPHONE}</Label>
                      <Input
                        type="text"
                        required="required"
                        minLength="15"
                        maxLength="15"
                        id="Phone"
                        name="Phone"
                        value={Phone}
                        onChange={(e) => {
                          this.setState({
                            Phone:
                            PhoneCompleteMask(e.target.value.trimStart()),
                          });
                          if (isSearch) {
                            Mixpanel.trackEvent(searchModalTelephone());
                          }
                          if (isEnterprise) {
                            Mixpanel.trackEvent(enterpriseModalTelephone());
                          }
                          if (isEnterpriseDetail) {
                            Mixpanel.trackEvent(enterpriseDetailsModalTelephone());
                          }
                        }}
                      />
                      <Label htmlFor="Email">{Contact.EMAIL}</Label>
                      <Input
                        type="email"
                        required="required"
                        maxLength="100"
                        minLength="3"
                        id="Email"
                        name="Email"
                        value={Email}
                        onChange={(e) => {
                          if (isSearch) {
                            Mixpanel.trackEvent(searchModalEmail());
                          }
                          if (isEnterprise) {
                            Mixpanel.trackEvent(enterpriseModalEmail());
                          }
                          if (isEnterpriseDetail) {
                            Mixpanel.trackEvent(enterpriseDetailsModalEmail());
                          }
                          this.setState({ Email: e.target.value.trimStart() });
                        }}
                      />
                      <Label htmlFor="Message">{Contact.MESSAGE_LABEL}</Label>
                      <Input
                        type="textarea"
                        style={{ resize: 'none' }}
                        required="required"
                        maxLength="100"
                        id="Message"
                        name="Message"
                        value={Message}
                        onChange={(e) => {
                          if (isSearch) {
                            Mixpanel.trackEvent(searchModalMessage());
                          }
                          if (isEnterprise) {
                            Mixpanel.trackEvent(enterpriseModalMessage());
                          }
                          if (isEnterpriseDetail) {
                            Mixpanel.trackEvent(enterpriseDetailsModalMessage());
                          }
                          this.setState({ Message: e.target.value.trimStart() });
                        }}
                      />
                      <Button
                        disabled={loading}
                        type="submit"
                      // onClick={(e) => this.handleSubmit(e, PostId, OwnerId)}
                      >
                        {loading
                          ? (
                            <>
                              <Row className="justify-content-center">
                                <Spinner animation="border" size="sm" variant="danger" className="loading-red" />
                                <Label className="sendEmail">Enviando</Label>
                              </Row>
                            </>
                          )
                          : `${Contact.TALK_TO}${agentDetails && agentDetails.FirstName !== 'Juliana' ? agentDetails.FirstName : 'o Homer'}`}
                      </Button>
                      <Row className="row-modal">
                        <Col md={9}>
                          <p>
                            {`${Contact.ONCLICK}${agentDetails && agentDetails.FirstName !== 'Juliana' ? agentDetails.FirstName : 'o Homer'}",
                         ${Contact.CONFIRM}`}
                            <a
                              href="https://www.homer.com.br/termos/termodeuso/"
                              onClick={() => {
                                if (isSearch) {
                                  Mixpanel.trackEvent(searchModalLinkTerms());
                                }
                                if (isEnterprise) {
                                  Mixpanel.trackEvent(enterpriseModalLinkTerms());
                                }
                                if (isEnterpriseDetail) {
                                  Mixpanel.trackEvent(enterpriseDetailsModalLinkTerms());
                                }
                              }}
                            >
                              {Contact.TERMS}

                            </a>
                            {Contact.AND}
                            <a
                              href="https://www.homer.com.br/termos/politicadeprivacidade/"
                              onClick={() => {
                                if (isSearch) {
                                  Mixpanel.trackEvent(searchModalLinkPrivacy());
                                }
                                if (isEnterprise) {
                                  Mixpanel.trackEvent(enterpriseModalLinkPrivacy());
                                }
                                if (isEnterpriseDetail) {
                                  Mixpanel.trackEvent(enterpriseDetailsModalLinkPrivacy());
                                }
                              }}
                            >
                              {Contact.PRIVACY}
                            </a>
                          </p>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
              )}
          </Form>
        </Modal>
      </>
    );
  }
}

ContactModal.defaultProps = {
  Incorp: false,
  isVisit: false,
  isHomer: false,
};

ContactModal.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSearch: PropTypes.bool.isRequired,
  isEnterprise: PropTypes.bool.isRequired,
  isEnterpriseDetail: PropTypes.bool.isRequired,
  PostId: PropTypes.string.isRequired,
  OwnerId: PropTypes.string.isRequired,
  Incorp: PropTypes.bool,
  isVisit: PropTypes.bool,
  isHomer: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  setStatusPost: PropTypes.func.isRequired,
  postDataMessage: PropTypes.func.isRequired,
  getAgent: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  statePostContact: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getAgentDetails: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getPostDetails: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setStatusPost,
    postDataMessage,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  getAgent: state.agentState.getAgent,
  getAgentDetails: state.agentdetailsState.getAgentDetails,
  statePostContact: state.contactState.statePostContact,
  getPostDetails: state.postdetailsState.getPostDetails,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactModal));
