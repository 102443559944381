import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppNavbarBrand } from '@coreui/react';
import {
  Button, Nav, Form, Row,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactAutocomplete from 'react-autocomplete';
import Mixpanel from '../mixpanel/index';
import {
  enterpriseDetailsHeaderLogo, enterpriseHeaderLogo, enterpriseHeaderToBrokers,
  homeHeaderLogo, searchHeaderLogo, searchHeaderStreet,
} from '../const/mixpanel';
import { setStatusAuth } from '../actions/auth';
import Contact from '../const/header';
import Arrow from '../assets/icons/arrow.svg';
import LogoRed from '../assets/imgs/logo-red.svg';
import CalendarIcon from '../assets/icons/calendarIcon.svg';
import { getDataLocation } from '../actions/location';
import { modelToSearch } from '../mapper/home';
import { getDataGeo } from '../actions/geo';
import { modelToViewModel as modelToViewModelLocation } from '../mapper/location';
import searchBlack from '../assets/icons/searchBlack.svg';

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.handleHome = this.handleHome.bind(this);
    this.handleLocation = this.handleLocation.bind(this);
    this.handleLocationMap = this.handleLocationMap.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleFilterMap = this.handleFilterMap.bind(this);
    this.handleLocationSelected = this.handleLocationSelected.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      Location: '', QttRooms: 0, PropertyTypeSearch: 'Apartamento', QttParkings: 0,
    };
  }

  handleHome(event) {
    event.preventDefault();

    const { match, history } = this.props;
    const { params } = match;
    const { slug } = params;
    history.push({ pathname: `/${slug}` });
  }

  handleLocation(event, location) {
    event.preventDefault();

    if (location.length >= 3 && location !== undefined) {
      const p = this.props;
      const state = modelToViewModelLocation(location, p.coords);
      p.getDataLocation(state);
    }

    this.setState({ Location: location });
    Mixpanel.trackEvent(searchHeaderStreet());
  }

  handleLocationMap(location) {
    if (location.length >= 3 && location !== undefined) {
      const p = this.props;
      const state = modelToViewModelLocation(location, p.coords);
      p.getDataLocation(state);
    }

    this.setState({ Location: location });
  }

  handleLocationSelected(event, location) {
    event.preventDefault();

    if (location.length >= 3) {
      const p = this.props;
      const state = modelToViewModelLocation(location, p.coords);
      p.getDataLocation(state);
    }

    this.setState({ Location: location });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { Location } = this.state;
    if (Location !== undefined && Location !== '') {
      const { history } = this.props;
      const state = modelToSearch(this.state, this.props);
      history.push({ pathname: state });
      this.setState({
        Location: '',
      });
      window.location.reload();
    }
  }

  handleFilter(event) {
    event.preventDefault();

    const { Location, GooglePlaceId } = this.state;
    if (Location !== undefined && Location !== '' && GooglePlaceId !== undefined && GooglePlaceId !== '') {
      const { history } = this.props;
      const state = modelToSearch(this.state, this.props);
      history.push({ pathname: state });
      this.setState({
        Location: '',
      });
      window.location.reload();
    }
  }

  handleFilterMap(Location) {
    if (Location !== undefined && Location !== '') {
      const { history } = this.props;
      const state = modelToSearch(this.state, this.props);
      history.push({ pathname: state });
      this.setState({
        Location: '',
      });
      window.location.reload();
    }
  }

  render() {
    const p = this.props;
    const {
      getAgentDetails, isMobile, isSeleto, listLocation, disableVisit,
      isHome, match, isSearch, isDetails, isEnterpriseDetail, handleModal,
      isEnterprise,
    } = this.props;
    const { params } = match;
    const { slug } = params;

    const locations = (listLocation && listLocation.data && listLocation.data.list) || [];

    const agentDetails = (getAgentDetails && getAgentDetails.data) || {};

    const { Location } = this.state;
    if (p.getGeo.data) {
      this.handleFilterMap(Location);
    }

    return (
      <>
        {((isMobile && (isHome || isEnterpriseDetail)) || (!isMobile)) && (
          <AppNavbarBrand
            onClick={() => {
              if (isHome && !isEnterprise && !isEnterpriseDetail) {
                Mixpanel.trackEvent(homeHeaderLogo());
              }
              if (isSearch) {
                Mixpanel.trackEvent(searchHeaderLogo());
              }
              if (isEnterpriseDetail) {
                Mixpanel.trackEvent(enterpriseDetailsHeaderLogo());
              }
              if (isEnterprise && isHome) {
                Mixpanel.trackEvent(enterpriseHeaderLogo());
              }
            }}
            href={isSeleto ? '/' : `/${slug}`}
            full={{
              src: LogoRed,
              width: 92.4,
              height: 35,
              alt: 'Logo',
            }}
            minimized={{
              src: LogoRed,
              width: 92.4,
              height: 35,
              alt: 'Logo',
            }}
            style={{ marginLeft: '1%', cursor: 'pointer' }}
          />
        )}
        {isMobile && !(isHome || isEnterpriseDetail) && (
          <img
            loading="lazy"
            src={Arrow}
            alt="Voltar"
            onClick={this.handleHome}
            onKeyPress={this.handleHome}
            role="presentation"
            className="back-header"
          />
        )}
        {!(isHome || isEnterpriseDetail) && (
          <Form className="search">
            <Row>
              <ReactAutocomplete
                inputProps={{
                  placeholder: 'Buscar Rua ou Bairro',
                  required: 'required',
                  className: 'location',
                }}
                menuStyle={{ zIndex: 99, position: 'fixed', left: isMobile ? 50 : 190 }}
                required="required"
                items={locations}
                value={Location}
                getItemValue={(item) => item.Name}
                onSelect={(value, locale) => {
                  const data = {
                    GooglePlaceId: locale.Id,
                    Location: value,
                  };
                  p.getDataGeo(data);
                  return this.setState(data);
                }}
                onKeyPress={(e) => this.handleFilter(e)}
                onChange={(e) => {
                  this.handleLocation(e, e.target.value);
                }}
                renderItem={(item, isHighlighted) => (
                  <div
                    key={item.Name}
                    style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                    className="autocomplete"
                  >
                    {item.Name}
                  </div>
                )}
              />
              <Button
                className="icon"
                type="button"
                onClick={(e) => this.handleFilter(e)}
              >
                <img loading="lazy" src={searchBlack} alt="buscar" />
              </Button>
            </Row>
          </Form>
        )}
        {
          !isMobile && !(isSeleto || isEnterpriseDetail) && (
            <div className="info-broker">
              <p><h5 className="description-site">{Contact.BROKER_SITE}</h5></p>
              <p><h5 className="broker-name">{agentDetails.FullName}</h5></p>
            </div>
          )
        }
        <Nav className="ml-auto" navbar />
        {isEnterpriseDetail && isMobile && (
          <Button
            id="ButtonHeaderVisit"
            name="ButtonHeaderVisit"
            className="btn_web_secondary button-visit"
            onClick={(e) => handleModal(e, false, true)}
            disabled={disableVisit}
          >
            <img loading="lazy" src={CalendarIcon} alt="Ícone de Casa" className="mb-1 mr-2" />
            {Contact.VISIT}
          </Button>
        )}
        {
          agentDetails.FullName && (
            !isMobile && !(isSeleto || isEnterpriseDetail) && (
              <>
                <img loading="lazy" className="avatar" src={agentDetails.AvatarImagePath} alt={agentDetails.Fullname} />
                <div className="div-size-header">
                  <h6 className="bold">{agentDetails.FullName}</h6>
                  <p>{`CRECI-${agentDetails.CreciUF} F${agentDetails.CreciNumber}`}</p>
                </div>
              </>
            )
          )
        }
        {isSeleto && !isMobile && (isHome || isSearch || isDetails) && (
          <>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    if (isEnterprise) {
                      Mixpanel.trackEvent(enterpriseHeaderToBrokers());
                    }
                  }}
                  href="https://www.homer.com.br/?utm_source=web&utm_medium=menu&utm_campaign=homer&utm_tag=corretores"
                >
                  {Contact.TO_BROKERS}
                </a>
              </li>
            </ul>
          </>
        )}
      </>
    );
  }
}

DefaultHeader.defaultProps = {
  isHome: false,
  // handleShowFilter: null,
  // showSearch: false,
  isSearch: false,
  isDetails: false,
  isEnterpriseDetail: false,
  isEnterprise: false,
  disableVisit: false,
  coords: {},
  handleModal: () => { },
};

DefaultHeader.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isSeleto: PropTypes.bool.isRequired,
  isSearch: PropTypes.bool,
  isDetails: PropTypes.bool,
  disableVisit: PropTypes.bool,
  isEnterpriseDetail: PropTypes.bool,
  isEnterprise: PropTypes.bool,
  isHome: PropTypes.bool,
  handleModal: PropTypes.func,
  getDataLocation: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  }).isRequired,
  getAgentDetails: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listLocation: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getDataGeo: PropTypes.func.isRequired,
  // handleShowFilter: PropTypes.func,
  // showSearch: PropTypes.bool,
  coords: PropTypes.shape({
    coords: PropTypes.shape({}),
    isGeolocationAvailable: PropTypes.bool,
    isGeolocationEnabled: PropTypes.bool,
  }),
  getGeo: PropTypes.shape({
    data: PropTypes.shape({ Latitude: PropTypes.number, Longitude: PropTypes.number }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setStatusAuth,
    getDataLocation,
    getDataGeo,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  getAgentDetails: state.agentdetailsState.getAgentDetails,
  listLocation: state.locationState.listLocation,
  getGeo: state.geoState.getGeo,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultHeader));
