export default {
  AgentId: '',
  PostId: '',
  Name: '',
  Phone: '',
  Email: '',
  Message: '',
  SourceId: '',
  MessageId: '',
};
