import React, { Component } from 'react';
import { AppHeader } from '@coreui/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container, Row, Col, Button,
} from 'reactstrap';
import DefaultHeader from '../../containers/defaultHeader';
import Contact from '../../const/500';

class Page500 extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.state = {
    };
  }

  handleRedirect(event) {
    event.preventDefault();

    const { history } = this.props;

    history.push({ pathname: '/' });
  }

  render() {
    return (
      <div className="app">
        <AppHeader className="header">
          <DefaultHeader />
        </AppHeader>
        <div className="app-404">
          <Container>
            <Row>
              <Col>
                <h1
                  id="Error"
                  name="Error"
                  data-testid="Error"
                >
                  {Contact.ERROR}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4
                  id="Problem"
                  name="Problem"
                  data-testid="Problem"
                >
                  {Contact.PROBLEM}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="btn_web_primary"
                  id="BackHomePage"
                  name="BackHomePage"
                  data-testid="BackHomePage"
                  type="button"
                  onClick={this.handleRedirect}
                >
                  {Contact.BACK_HOME}
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

Page500.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Page500);
