export default {
  LIST: {
    LOADING: 'Carregando Posts...',
    TITLE: 'Lista de Posts',
    ADD_BUTTON: 'Adicionar',
    TABLE_HEADER: {
      UUID: 'Uuid',
      USERID: 'ID do Usuário',
      POSTID: 'ID do Post',
      AREA: 'Área',
      ISRENT: 'É aluguel',
      LOCATION: 'Localização',
      POSTMEDIA: 'Post Media',
      PARKINGSPOTS: 'Vagas Estacionamento',
      PRICE: 'Preço',
      TAGS: 'Tags',
      ROOMS: 'Quartos',
      PROPERTYTYPE: 'Tipo Propriedade',
      ISHOMERSELL: 'É Homer Sell',
      DESCRIPTION: 'Descrição',

    },
  },
  FORM: {
    LOADING: 'Aguardando...',
    NOTIFY_SUCCESS_CAD: 'Post cadastrado com sucesso!',
    NOTIFY_SUCCESS_UPD: 'Post atualizado com sucesso!',
    SAVE_BUTTON: 'Salvar',
    BACK_BUTTON: 'Voltar',
    TITLE: 'Detalhes do Post',
    UUID_FIELD: 'Uuid',
    USERID_FIELD: 'ID do Usuário',
    POSTID_FIELD: 'ID do Post',
    AREA_FIELD: 'Área',
    ISRENT_FIELD: 'É aluguel',
    LOCATION_FIELD: 'Localização',
    POSTMEDIA_FIELD: 'Post Media',
    PARKINGSPOTS_FIELD: 'Vagas Estacionamento',
    PRICE_FIELD: 'Preço',
    TAGS_FIELD: 'Tags',
    ROOMS_FIELD: 'Quartos',
    PROPERTYTYPE_FIELD: 'Tipo Propriedade',
    ISHOMERSELL_FIELD: 'É Homer Sell',
    DESCRIPTION_FIELD: 'Descrição',

  },
  ADRESS: 'Endereço',
  FEATURE: 'Características',
  TALK_TO: 'Fale com o Homer',
  TALK: 'Fale com ',
  ONCLICK: 'Ao clicar no botao "Fale com ',
  CONFIRM: 'você afirma ter mais de 18 anos e aceita nossos ',
  TERMS: ' termos de uso',
  AND: ' e as ',
  PRIVACY: 'políticas de privacidade',
};
