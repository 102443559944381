import React from 'react';
import {
  Container,
  Row,
  Col,
  CardImg,
  FormGroup,
  CardText,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AppleStore from '../assets/imgs/apple-store.svg';
import PlayStore from '../assets/imgs/play-store.svg';
import Logo from '../assets/imgs/logo-dark.svg';
import Linkedin from '../assets/icons/icon-linkedin.svg';
import Instagram from '../assets/icons/icon-instagram.svg';
import Facebook from '../assets/icons/icon-facebook.svg';
import Youtube from '../assets/icons/icon-youtube.svg';
import Contact from '../const/footer';
import { homeFooterAppleStore, homeFooterGooglePlay, homeFooterSeeOthers } from '../const/mixpanel';
import Mixpanel from '../mixpanel';

function DefaultFooter(props) {
  const { isSeleto, isHome } = props;

  if (isSeleto) {
    return (
      <>
        <div className="appfoot" style={{ width: '100%', minHeight: '80px', marginBottom: '30px' }}>
          <Container className="post-details border-tp">
            <Row>
              <Col md={3}>
                <FormGroup className="imagecard">
                  <Row>
                    <Col className="h-assets-imgs"><CardImg className="h-imgs" src={Logo} alt="Card image cap" /></Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={5} className="margin-bottom-footer">
                <CardText className="card-text-brokers">{Contact.COPYRIGHT}</CardText>
              </Col>
              <Col md={4} className="style-ios-playstore">
                <FormGroup>
                  <CardText className="card-text-download-app">
                    Baixe nosso app
                  </CardText>
                  <Row className="margin-row-image">
                    <Col className="imgs-ios">
                      <a href="https://apps.apple.com/br/app/homer-parcerias-imobili%C3%A1rias/id739773776" target="_blank" rel="noreferrer" alt="Apple Store" onClick={() => { if (isHome) { Mixpanel.trackEvent(homeFooterAppleStore()); } }}>
                        <CardImg className="ios" src={AppleStore} />
                      </a>
                    </Col>
                    <Col className="imgs-playstore">
                      <a href="https://play.google.com/store/apps/details?id=com.corretorvip.app.br&hl=pt_BR&gl=US" target="_blank" rel="noreferrer" alt="Play Store" onClick={() => { if (isHome) { Mixpanel.trackEvent(homeFooterGooglePlay()); } }}>
                        <CardImg className="playstore" src={PlayStore} />
                      </a>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="creci-section">
                <div className="homer-company-name">{Contact.HOMER_COMPANY_NAME}</div>
                <div className="homer-creci">
                  <Row className="m-0">
                    {Contact.HOMER_CRECI}
                    <a href="https://www.homer.com.br/creci-homer/" target="_blank" rel="noreferrer" className="ml-3" onClick={() => { if (isHome) { Mixpanel.trackEvent(homeFooterSeeOthers()); } }}><strong>{Contact.SEE_OTHERS}</strong></a>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
  if (!isSeleto) {
    return (
      <>
        <div className="appfoot" style={{ width: '100%', minHeight: '40vh', marginBottom: '30px' }}>
          <Container className="post-details border-tp">
            <Row className="row-tittle-footer">
              <Col md={3}>
                <FormGroup className="imagecard">
                  <Row>
                    <Col className="h-assets-imgs"><CardImg className="h-imgs" src={Logo} alt="Card image cap" /></Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={5} className="margin-bottom-footer">
                <CardText className="card-text-brokers">{Contact.EXPERIENCE}</CardText>
              </Col>
              <Col md={4} className="style-ios-playstore">
                <FormGroup>
                  <CardText className="card-text-download-app">
                    Baixe nosso App
                  </CardText>
                  <Row className="margin-row-image">
                    <Col className="imgs-ios">
                      <a href="https://apps.apple.com/br/app/homer-parcerias-imobili%C3%A1rias/id739773776" target="_blank" rel="noreferrer" alt="Apple Store" onClick={() => { if (isHome) { Mixpanel.trackEvent(homeFooterAppleStore()); } }}>
                        <CardImg className="ios" src={AppleStore} />
                      </a>
                    </Col>
                    <Col className="imgs-playstore">
                      <a href="https://play.google.com/store/apps/details?id=com.corretorvip.app.br&hl=pt_BR&gl=US" target="_blank" rel="noreferrer" alt="Play Store" onClick={() => { if (isHome) { Mixpanel.trackEvent(homeFooterGooglePlay()); } }}>
                        <CardImg className="playstore" src={PlayStore} />
                      </a>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="creci-section">
                <div className="homer-company-name">{Contact.HOMER_COMPANY_NAME}</div>
                <div className="homer-creci">
                  <Row className="m-0">
                    {Contact.HOMER_CRECI}
                    <a href="https://www.homer.com.br/creci-homer/" target="_blank" rel="noreferrer" className="ml-3" onClick={() => { if (isHome) { Mixpanel.trackEvent(homeFooterSeeOthers()); } }}><strong>{Contact.SEE_OTHERS}</strong></a>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2} className="solutions">
                <Nav className="margin-bt-auto">
                  <NavItem>
                    <CardText className="text">{Contact.SOLUTIOINS}</CardText>
                    <NavLink className="a-href" target="_blank" rel="noreferrer" href="https://www.homer.com.br/parcerias/">{Contact.PARTNERSHIPS}</NavLink>
                    <NavLink className="a-href" target="_blank" rel="noreferrer" href="https://www.homer.com.br/creditoimobiliario/financiamentoimobiliario/">{Contact.CREDIT}</NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md={2} className="solutions">
                <Nav className="margin-bt-auto">
                  <NavItem>
                    <CardText className="text">{Contact.ABOUT}</CardText>
                    <NavLink className="a-href" target="_blank" rel="noreferrer" href="https://www.homer.com.br/parcerias/o-que-falam-da-gente.html">{Contact.MEDIA}</NavLink>
                    <NavLink className="a-href" target="_blank" rel="noreferrer" href="https://www.homer.com.br/blog/">{Contact.BLOG}</NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md={2} className="solutions">
                <Nav className="margin-bt-auto">
                  <NavItem>
                    <CardText className="text">{Contact.SERVICE}</CardText>
                    <NavLink className="a-href" target="_blank" rel="noreferrer" href="https://www.homer.com.br/parcerias/fale-conosco.html">{Contact.CONTACT}</NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md={3} className="solutions">
                <Nav className="margin-bt-auto">
                  <NavItem className="solutions">
                    <CardText className="text">{Contact.INSTITUTIONAL}</CardText>
                    <NavLink className="a-href" target="_blank" rel="noreferrer" href="https://www.homer.com.br/termos/termodeuso/">{Contact.TERMS}</NavLink>
                    <NavLink className="a-href" target="_blank" rel="noreferrer" href="https://www.homer.com.br/termos/politicadeprivacidade/">{Contact.PRIVACY}</NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md={3} className="homer-networks solutions">
                <CardText className="text">{Contact.NETWORKS}</CardText>
                <Row className="row-footer">
                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/school/homerparcerias/">
                    <img loading="lazy" className="icons-linkedin-imgs" src={Linkedin} alt="Linkedin icon" />
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/homerparcerias/">
                    <img loading="lazy" className="icons-instagram-imgs" src={Instagram} alt="Instagram icon" />
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/homerparcerias">
                    <img loading="lazy" className="icons-facebook-imgs" src={Facebook} alt="Facebook icon" />
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://www.youtube.com/usehomer">
                    <img loading="lazy" className="icons-youtube-imgs" src={Youtube} alt="Youtube icon" />
                  </a>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

DefaultFooter.propTypes = {
  isSeleto: PropTypes.bool.isRequired,
  isHome: PropTypes.bool.isRequired,
};

export default DefaultFooter;
