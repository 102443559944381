import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppHeader } from '@coreui/react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button, Label, Card, Container,
} from 'reactstrap';
import { Carousel } from 'react-responsive-carousel';
import GoogleMapReact from 'google-map-react';
import { Helmet } from 'react-helmet';
import BaseComponent from './base';
import ImageViewer from '../utils/reactCarouselImageViewer';
import DefaultHeader from '../../containers/defaultHeader';
import Config from '../../config/environments';
import { getDataAgent } from '../../actions/agent';
import { getDataAgentDetails } from '../../actions/agentdetails';
import { getDataEnterpriseDetail } from '../../actions/enterprise';
import { modelToViewModel } from '../../mapper/agentdetails';
import { groupArr } from '../../lib/utils';
import { ContactModalComponent } from '../component/contact';
import Contact from '../../const/enterpriseDetails';
import NoImage from '../../assets/imgs/no-image.png';
import IconSeePictures from '../../assets/icons/iconSeePictures.svg';
import IconArrowNext from '../../assets/icons/arrow_right.svg';
import IconArrowPrev from '../../assets/icons/arrow_left.svg';
import IconMoney from '../../assets/icons/iconMoney.svg';
import CalendarIcon from '../../assets/icons/calendarIcon.svg';
import Pin from '../../assets/icons/pinred.svg';
import IconHomer from '../../assets/icons/homerIcon.svg';
import Mixpanel from '../../mixpanel';
import {
  enterpriseDetailsCardChat, enterpriseDetailsCardScheduleVisit, enterpriseDetailsMap,
  enterpriseDetailsNext, enterpriseDetailsOpen, enterpriseDetailsPhotoClose,
  enterpriseDetailsPrevious, enterpriseDetailsScheduleVisit, enterpriseDetailsSeePhotos,
} from '../../const/mixpanel';

class EnterpriseDetails extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      IsViewerOpen: false,
      IsOpen: false,
      Requested: true,
      RequestMessage: true,
      IsVisit: true,
      CurrentImage: 0,
      Width: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
    this.openImageViewer = this.openImageViewer.bind(this);
    this.closeImageViewer = this.closeImageViewer.bind(this);
    this.handleModal = this.handleModal.bind(this);
    const { match } = props;
    const { params } = match;
    const { postId } = params;
    props.getDataEnterpriseDetail(postId);
    props.getDataAgent(params);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { Requested } = nextState;
    const { getAgent, history } = nextProps;
    const p = this.props;

    if (getAgent && getAgent.state === false && Requested) {
      if (getAgent && getAgent.data) {
        const agent = getAgent && getAgent.data;
        const state = modelToViewModel(agent);

        this.setState({ Requested: false });
        p.getDataAgentDetails(state);
      } else {
        this.setState({ Requested: false });
        history.push({ pathname: '/404' });
      }
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ Width: window.innerWidth });
  }

  handleModal(event, redirect, isVisit) {
    if (event) {
      event.preventDefault();
    }

    const { IsOpen } = this.state;
    const { match, getEnterpriseDetail } = this.props;
    const { params } = match;
    const { slug } = params;
    const isHomer = Config.SECURITY.SELETO === slug || false;

    if (redirect && isHomer && IsOpen) {
      const post = (getEnterpriseDetail && getEnterpriseDetail.data) || {};

      if (isVisit) {
        const message = `Olá tenho interesse no empreendimento ${post.EnterpriseName}${post.DeveloperName ? ` (${post.DeveloperName}) ` : ''} e gostaria de agendar uma visita.`;

        const encoded = encodeURI(message);

        const url = `https://api.whatsapp.com/send?1=pt_BR&phone=${post.Phone}&text=${encoded}`;

        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        a.click();
      } else {
        const message = `Olá tenho interesse no empreendimento ${post.EnterpriseName}${post.DeveloperName ? ` (${post.DeveloperName}) ` : ''} e gostaria de tirar algumas dúvidas.`;

        const encoded = encodeURI(message);

        const url = `https://api.whatsapp.com/send?1=pt_BR&phone=${post.Phone}&text=${encoded}`;

        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        a.click();
      }
    }

    this.setState({ IsOpen: !IsOpen, IsVisit: !!isVisit });
  }

  // eslint-disable-next-line class-methods-use-this
  onImgLoad(event) {
    const { naturalHeight, naturalWidth, classList } = event.target;

    let isImgContains = true;

    if (naturalHeight >= naturalWidth) {
      isImgContains = true;
    }
    if (naturalHeight <= naturalWidth) {
      isImgContains = false;
    }

    classList.add(`${isImgContains ? 'img-contains' : 'img-cover'}`);
  }

  openImageViewer(event, idx) {
    event.preventDefault();

    this.setState({ CurrentImage: idx || 0, IsViewerOpen: true });
  }

  closeImageViewer() {
    Mixpanel.trackEvent(enterpriseDetailsPhotoClose());

    this.setState({ CurrentImage: 0, IsViewerOpen: false });
  }

  render() {
    const {
      getEnterpriseDetail, getAgentDetails, getAgent, history, match,
    } = this.props;
    const {
      IsViewerOpen, Width, CurrentImage, IsOpen, IsVisit,
    } = this.state;
    const post = (getEnterpriseDetail && getEnterpriseDetail.data) || {};
    const userDetails = (getAgentDetails && getAgentDetails.data) || {};
    const isMobile = Width <= 999;
    const defaultProps = {
      center: {
        lat: post.Latitude,
        lng: post.Longitude,
      },
      zoom: 15,
    };
    const markerStyle = {
      position: 'absolute',
      with: '20px',
      height: '20px',
      top: '100%',
      left: '50%',
      color: 'red',
      transform: 'translate(-50%, -100%)',
    };

    const mockImgs = [{ MediaUrl: NoImage, Order: 1 },
      { MediaUrl: NoImage, Order: 2 }, { MediaUrl: NoImage, Order: 3 }];

    const mockFeatures = [{ text: 'test', id: 1 }];

    const qttImages = post && post.PostMedia && post.PostMedia.length > 0
      ? post.PostMedia : mockImgs;

    const qttFeatures = post && post.Features && post.Features.length > 0
      ? post.Features : mockFeatures;

    const groupImages = groupArr(qttImages, isMobile ? 1 : 3);
    const groupFeatures = groupArr(qttFeatures, isMobile ? 2 : 4);

    const { params } = match;
    const { slug, postId } = params;
    const isHomer = Config.SECURITY.SELETO === slug || false;

    const images = [];

    groupImages.forEach((groupMedia) => (
      groupMedia.forEach((media) => (
        images.push(media.MediaUrl)
      ))));

    const now = new Date();
    const updatedDate = new Date(
      post.UpdatedDateTime ? post.UpdatedDateTime : post.CreatedDateTime,
    );
    const diff = Math.abs(now.getTime() - updatedDate.getTime());
    let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    let type = 'day';

    if (days > 28) {
      days /= 30;
      type = 'month';
    } else if (days > 7) {
      days /= 7;
      type = 'week';
    }

    if (getAgent.success === true && getAgent.data === null) {
      history.push({ pathname: '/404' });
    }

    const idxImages = (idx, idxs) => {
      if (isMobile) {
        return idxs;
      }

      return idxs > 0 ? (idxs * 3 + idx) : idxs + idx;
    };
    const typologies = post.Typologies && post.Typologies.length > 0 && post.Typologies.map((typology, idx) => `${typology.Text}${post.Typologies.length - 1 === idx ? '' : ' | '}`);
    const title = `Detalhes do empreendimento: ${post.EnterpriseName}  ${post.DeveloperName ? `- ${post.DeveloperName}` : ''}`;
    const description = `Endereço: ${post.Location} - Tipologia: ${post.Typologies && post.Typologies.length > 0 && typologies && typologies.length > 0 ? typologies.join().replaceAll(',', '') : ''} - Unidades a partir de ${post.PriceLabelText}`;

    return (
      <>
        <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={`${images[0]}`} />
        </Helmet>
        {
          IsViewerOpen && (
            <ImageViewer
              src={images}
              currentIndex={CurrentImage}
              onClose={this.closeImageViewer}
              disableScroll
              backgroundStyle={{
                backgroundColor: 'rgba(0,0,0,0.9)',
                zIndex: 999,
              }}
              closeOnClickOutside
              isEnterpriseDetail
            />
          )
        }
        {IsOpen && (
          <ContactModalComponent
            PostId={postId}
            isOpen={IsOpen}
            isVisit={IsVisit}
            isHomer={isHomer}
            Incorp
            toggle={this.handleModal}
            isEnterpriseDetail
          />
        )}
        <div className="app enterprise-details">
          <AppHeader className={isMobile ? 'header-mobile' : 'header'}>
            <DefaultHeader
              isMobile={isMobile}
              isSeleto={isHomer}
              isEnterpriseDetail
              isHome={!isMobile}
              handleModal={this.handleModal}
              disableVisit={false}
            />
          </AppHeader>
          <div
            className="app-enterprise-details"
            id="ContainerBodyPostdetails"
            name="ContainerBodyPostdetails"
            data-testid="ContainerBodyPostdetails"
          >
            <Container>
              <Row className="row-image">
                {groupImages && (
                  <div className="container-image">
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      showIndicators={false}
                      centerMode
                      centerSlidePercentage={100}
                      renderArrowNext={(clickHandler, hasNext) => {
                        if (!hasNext || IsViewerOpen) return null;
                        return (
                          <div
                            className={(IsViewerOpen) ? null : 'arrow-next-post-detail'}
                            onClick={() => {
                              clickHandler();
                              Mixpanel.trackEvent(enterpriseDetailsNext());
                            }}
                            role="button"
                            tabIndex={0}
                            aria-hidden="true"
                            id={`ArrowNext${post.PostId}`}
                            name={`ArrowNext${post.PostId}`}
                            data-testid={`ArrowNext${post.PostId}`}
                          >
                            <img loading="lazy" src={IconArrowNext} alt="Próxima Imagem" />
                          </div>
                        );
                      }}
                      renderArrowPrev={(clickHandler, hasPrev) => {
                        if (!hasPrev || IsViewerOpen) return null;
                        return (
                          <div
                            className={(IsViewerOpen) ? null : 'arrow-prev-post-detail'}
                            onClick={() => {
                              clickHandler();
                              Mixpanel.trackEvent(enterpriseDetailsPrevious());
                            }}
                            role="button"
                            tabIndex={0}
                            aria-hidden="true"
                            id={`ArrowPrev${post.PostId}`}
                            name={`ArrowPrev${post.PostId}`}
                            data-testid={`ArrowPrev${post.PostId}`}
                          >
                            <img loading="lazy" src={IconArrowPrev} alt="Imagem Anterior" />
                          </div>
                        );
                      }}
                    >
                      {
                        groupImages.map((groupImage, idxs) => (
                          <Row className="carousel-container" key={`Row-${idxs + 1}`}>
                            {
                              groupImage.map((media, idx) => (
                                <Col
                                  className="container-image"
                                  xs={isMobile ? 12 : 4}
                                  key={`ContainerImage${idx + 1}`}
                                  id={`ContainerImage${idx + 1}`}
                                  name={`ContainerImage${idx + 1}`}
                                  data-testid={`ContainerImage${idx + 1}`}
                                  onClick={
                                    (e) => {
                                      this.openImageViewer(
                                        e, idxImages(idx, idxs),
                                      );
                                      Mixpanel.trackEvent(enterpriseDetailsOpen());
                                    }
                                  }
                                  onKeyDown={
                                    (e) => {
                                      this.openImageViewer(
                                        e, idxs > 0 ? (idxs * 3 + idx) : idxs + idx,
                                      );
                                      Mixpanel.trackEvent(enterpriseDetailsOpen());
                                    }
                                  }
                                >
                                  <img
                                    loading="lazy"
                                    className="img-carousel"
                                    key={media.Order}
                                    src={media.MediaUrl ? `${media.MediaUrl}` : `${NoImage}`}
                                    alt="Fotos"
                                    onError={(e) => {
                                      e.target.onerror = null; e.target.src = NoImage;
                                    }}
                                    onLoad={this.onImgLoad}
                                    id={`Image${media.Order}`}
                                    name={`Image${media.Order}`}
                                    data-testid={`Image${media.Order}`}
                                  />
                                </Col>
                              ))
                            }
                          </Row>
                        ))
                      }
                    </Carousel>
                  </div>
                )}
              </Row>
              <Row>
                <Col xs={isMobile ? 12 : 8} className="container-texts">
                  <Row className="row-button-postdetail">
                    <Button
                      type="button"
                      id="ButtonSeePhotos"
                      name="ButtonSeePhotos"
                      className="btn_web_secondary mr-2"
                      onClick={(e) => {
                        this.openImageViewer(e);
                        Mixpanel.trackEvent(enterpriseDetailsSeePhotos());
                      }}
                    >
                      <img loading="lazy" src={IconSeePictures} alt="Ícone ver fotos" />
                      {Contact.SEE_PHOTOS}
                    </Button>
                    {!isMobile && (
                      <Button
                        id="ButtonHeaderVisit"
                        name="ButtonHeaderVisit"
                        className="btn_web_secondary"
                        onClick={(e) => {
                          this.handleModal(e, false, true);
                          Mixpanel.trackEvent(enterpriseDetailsScheduleVisit());
                        }}
                      >
                        <img loading="lazy" src={CalendarIcon} alt="Ícone de Casa" />
                        {Contact.VISIT}
                      </Button>
                    )}
                  </Row>
                  <Row>
                    <p className="enterprise">{`${post.EnterpriseName}  ${post.DeveloperName ? `- ${post.DeveloperName}` : ''}`}</p>
                  </Row>
                  <Row>
                    <p className="location">{post.Location}</p>
                  </Row>
                  {isMobile && (
                    <>
                      <Row>
                        <p className="unity-price">
                          {Contact.UNIT_PRICE}
                        </p>
                      </Row>
                      <Row>
                        <p className="price">
                          {post && post.PriceLabelText}
                        </p>
                      </Row>
                    </>
                  )}
                  {!isMobile && (
                    <Row>
                      <p className="updated-at">{`Atualizado há ${days.toFixed(0)} ${type === 'day' ? 'dia' : ''}${type === 'week' ? 'semana' : ''}${type === 'month' && days.toFixed(0) === 1 ? 'mês' : ''}${type === 'month' && days.toFixed(0) > 1 ? 'meses' : ''}${days.toFixed(0) > 1 && type !== 'month' ? 's' : ''}`}</p>
                    </Row>
                  )}
                  <Row>
                    <h3>
                      {Contact.UNIT_INFORMATION}
                    </h3>
                  </Row>
                  <Row>
                    {post && post.UnitInformation && post.UnitInformation.length > 0
                      && post.UnitInformation.slice(0, 3).map((x, idx) => (
                        <Col
                          xs={isMobile ? 6 : 2}
                          key={`CointerInfos${idx + 1}`}
                          name="CointerInfos"
                          id="CointerInfos"
                          data-testid="CointerInfos"
                          className="container-info"
                        >
                          <img loading="lazy" src={x.Url} alt="Ícone Quartos" />
                          <p>{x.Text}</p>
                        </Col>
                      ))}
                    {!isMobile && (
                      <Col
                        xs={5}
                        name="ContainerValue"
                        id="ContainerValue"
                        data-testid="ContainerValue"
                        className="container-info"
                      >
                        <img loading="lazy" src={IconMoney} alt="Ícone Vagas" />
                        <p>{`A partir de ${post && post.PriceLabelText}`}</p>
                      </Col>
                    )}
                  </Row>
                  {isMobile && (
                    <Row>
                      <Col
                        xs={9}
                        name="ContainerValue"
                        id="ContainerValue"
                        data-testid="ContainerValue"
                        className="container-info"
                      >
                        <img loading="lazy" src={IconMoney} alt="Ícone Vagas" />
                        <p>{`A partir de ${post && post.PriceLabelText}`}</p>
                      </Col>
                    </Row>
                  )}
                  {post && post.DevelopmentStatus && (
                    <>
                      <Row>
                        <h3>
                          {Contact.UNIT_STAGE}
                        </h3>
                      </Row>
                      <Row key="RowPropertyFeatures" className="last-row">
                        <Col xs={isMobile ? 6 : 3} className="container-info-immobile">
                          <img loading="lazy" src={post.DevelopmentStatus.Url} alt="Ícone de estágio da obra" />
                          <p>{post.DevelopmentStatus.Text}</p>
                        </Col>
                      </Row>
                    </>
                  )}
                  {post && post.Typologies && post.Typologies.length > 0 && (
                    <>
                      <Row>
                        <h3>
                          {Contact.TYPOLOGIES}
                        </h3>
                      </Row>
                      <Row key="RowPropertyFeatures" className="last-row">
                        {post.Typologies.map((typology) => (
                          <Col xs={isMobile ? 6 : 3} key={typology.Text} className="container-info-immobile">
                            <img loading="lazy" src={typology.Url} alt="Ícone de tipologia" />
                            <p>{typology.Text}</p>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                  {post && post.Features && post.Features.length > 0 && (
                    <>
                      <Row>
                        <h3>{`Características do ${isMobile ? 'imóvel' : 'empreendimento'}`}</h3>
                      </Row>
                      {groupFeatures.map((groupFeature, idxs) => (
                        <Row key={`RowCommun-${idxs + 1}`} className="last-row">
                          {groupFeature.map((features, idx) => (
                            <Col xs={isMobile ? 6 : 3} key={groupFeature[idx].Text} className="container-info-immobile">
                              <img loading="lazy" src={features.Url} alt="Ícone da preferência do cliente" />
                              <p>{features.Text}</p>
                            </Col>
                          ))}
                        </Row>
                      ))}
                    </>
                  )}
                  {post.Latitude && post.Longitude ? (
                    <>
                      <Row>
                        <h3>
                          {Contact.LOCATION}
                        </h3>
                      </Row>
                      <Row className="location-map">
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: Config.SECURITY.GOOGLE_API_KEY }}
                          defaultCenter={defaultProps.center}
                          defaultZoom={defaultProps.zoom}
                          yesIWantToUseGoogleMapApiInternals
                          onClick={() => {
                            Mixpanel.trackEvent(enterpriseDetailsMap());
                          }}
                        >
                          <div
                            key={post.id}
                            lat={post.Latitude}
                            lng={post.Longitude}
                          >
                            <img loading="lazy" style={markerStyle} src={Pin} alt="pin" />
                          </div>

                        </GoogleMapReact>
                      </Row>
                    </>
                  ) : (<></>)}
                  {post.Description && (
                    <>
                      <Row>
                        <h3>
                          {Contact.DESCRIPTION}
                        </h3>
                      </Row>
                      <Row classNmae="row-description">
                        <p dangerouslySetInnerHTML={{ __html: post.Description }} />
                      </Row>
                    </>
                  )}
                  {post.Relevant && (
                    <Row className="row-information">
                      <p>
                        {post.Relevant}
                      </p>
                    </Row>
                  )}
                </Col>
                {!isMobile && (
                  <Col xs={4} className="col-card">
                    <Card>
                      <Row>
                        <Label>
                          {Contact.UNIT_PRICE_DOTS}
                        </Label>
                      </Row>
                      <Row>
                        <h5>
                          {post.PriceLabelText}
                        </h5>
                      </Row>
                      <Row className="dashed-line" />
                      <Row className="user-information">
                        <div className="container-img">
                          <img loading="lazy" src={isHomer ? IconHomer : userDetails.AvatarImagePath} alt="Imagem do corretor" />
                        </div>
                        <Col>
                          <Row>
                            <span>{isHomer ? Contact.RESPONSIBLE : Contact.BROKER}</span>
                          </Row>
                          <Row>
                            <p>{userDetails.FullName}</p>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Button
                          type="button"
                          id="ButtonCardVisit"
                          name="ButtonCardVisit"
                          className="btn_web_primary w-100 mb-4"
                          onClick={(e) => {
                            this.handleModal(e, false, true);
                            Mixpanel.trackEvent(enterpriseDetailsCardScheduleVisit());
                          }}
                        >
                          {Contact.VISIT}
                        </Button>
                      </Row>
                      <Row className="dashed-line" />
                      <Row className="container-button">
                        <p>{Contact.ANY_DOUBT}</p>
                        <Button
                          type="button"
                          id="ButtonCardVisit"
                          name="ButtonCardVisit"
                          className="btn_web_secondary"
                          onClick={(e) => {
                            this.handleModal(e, false, false);
                            Mixpanel.trackEvent(enterpriseDetailsCardChat());
                          }}
                        >
                          {Contact.WANT_TALK}
                        </Button>
                      </Row>
                    </Card>
                  </Col>
                )}
              </Row>
              {isMobile && (
                <Row className="floating-button">
                  <Col className="p-0">
                    <Row className="container-button">
                      <p>{Contact.ANY_DOUBT}</p>
                      <Button
                        type="button"
                        id="ButtonCardVisit"
                        name="ButtonCardVisit"
                        className="btn_web_secondary"
                        onClick={(e) => {
                          this.handleModal(e);
                          Mixpanel.trackEvent(enterpriseDetailsCardChat());
                        }}
                      >
                        {Contact.WANT_TALK}
                      </Button>
                    </Row>
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        </div>
      </>
    );
  }
}

EnterpriseDetails.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
      slug: PropTypes.string,
      postId: PropTypes.string,
      userId: PropTypes.string,
    }),
  }).isRequired,
  getDataEnterpriseDetail: PropTypes.func.isRequired,
  getEnterpriseDetail: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getDataAgent: PropTypes.func.isRequired,
  getAgent: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getDataAgentDetails: PropTypes.func.isRequired,
  getAgentDetails: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getDataEnterpriseDetail,
    getDataAgent,
    getDataAgentDetails,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  getEnterpriseDetail: state.enterpriseState.getEnterpriseDetail,
  getAgent: state.agentState.getAgent,
  getAgentDetails: state.agentdetailsState.getAgentDetails,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnterpriseDetails);
