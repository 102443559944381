import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { geolocated } from 'react-geolocated';
import BaseComponent from './base';
import { modelToViewModel as modelToViewModelLocation } from '../../mapper/location';
import { getDataLocationBreadcrumbs } from '../../actions/location';
import { getDataGeoBreadcrumbs } from '../../actions/geo';
import ResultsComponent from './results';

class PreResult extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.state = { Requested: false, RequestedGeo: false };
    const { match } = props;
    const { params } = match;
    const { googlePlaceName, lat, long } = params;
    if (lat === '0' && long === '0') {
      const state = modelToViewModelLocation(googlePlaceName, props.coords);
      props.getDataLocationBreadcrumbs(state);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { Requested } = nextState;
    const { listLocationBreadcrumbs, match } = nextProps;
    const p = this.props;
    const { params } = match;
    const { googlePlaceName, lat, long } = params;
    const locations = (listLocationBreadcrumbs && listLocationBreadcrumbs.data
       && listLocationBreadcrumbs.data.list) || [];

    if (locations.length > 0 && !Requested) {
      nextProps.getDataGeoBreadcrumbs(locations[0]);
      this.setState({ Requested: true, RequestedGeo: true });
      return false;
    }

    if (JSON.stringify(params) !== JSON.stringify(p.match.params)) {
      if (lat === '0' && long === '0') {
        const state = modelToViewModelLocation(googlePlaceName, nextProps.coords);
        p.getDataLocationBreadcrumbs(state);
        return false;
      }
    }

    return true;
  }

  render() {
    const { RequestedGeo } = this.state;
    const { match, getGeoBreadcrumbs, listLocationBreadcrumbs } = this.props;
    const { params } = match;
    const { lat, long } = params;
    const geo = getGeoBreadcrumbs && getGeoBreadcrumbs.data;
    const locations = (listLocationBreadcrumbs && listLocationBreadcrumbs.data
       && listLocationBreadcrumbs.data.list) || [];

    if (locations.length > 0 && RequestedGeo && geo) {
      return <ResultsComponent geo={geo} />;
    }

    return (
      <>
        {lat !== '0' && long !== '0' && <ResultsComponent />}
      </>
    );
  }
}

PreResult.defaultProps = {
  coords: {},
};

PreResult.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      googlePlaceName: PropTypes.string.isRequired,
      lat: PropTypes.string.isRequired,
      long: PropTypes.string.isRequired,
    }),
  }).isRequired,
  getDataLocationBreadcrumbs: PropTypes.func.isRequired,
  getDataGeoBreadcrumbs: PropTypes.func.isRequired,
  listLocationBreadcrumbs: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  coords: PropTypes.shape({ latitude: PropTypes.number, longitude: PropTypes.number }),
  getGeoBreadcrumbs: PropTypes.shape({
    data: PropTypes.shape({ Latitude: PropTypes.number, Longitude: PropTypes.number }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getDataLocationBreadcrumbs,
    getDataGeoBreadcrumbs,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  listLocationBreadcrumbs: state.locationState.listLocationBreadcrumbs,
  getGeoBreadcrumbs: state.geoState.getGeoBreadcrumbs,
});

const PreResultGeoloc = geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(PreResult);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreResultGeoloc));
