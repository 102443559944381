import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { modelToSearchBread } from '../../../mapper/home';
import {
  searchBreadcrumbCity, searchBreadcrumbCountry, searchBreadcrumbNeighborhood,
  searchBreadcrumbStart, searchBreadcrumbState, searchBreadcrumbStreet,
} from '../../../const/mixpanel';
import Mixpanel from '../../../mixpanel';

class Breadcrumbs extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleHome = this.handleHome.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleHome(event) {
    event.preventDefault();

    const { match, history, isSearch } = this.props;
    const { params } = match;
    const { slug } = params;

    if (isSearch) {
      Mixpanel.trackEvent(searchBreadcrumbStart());
    }

    history.push({ pathname: `/${slug}` });
  }

  handleSearch(event, idx) {
    event.preventDefault();

    const { history, address, isSearch } = this.props;
    const addressSanitized = decodeURIComponent(address) || '';
    const paths = addressSanitized.replaceAll('-', ',').split(',').reverse();

    let location = '';
    let currentIdx = idx;

    while (currentIdx !== -1) {
      const path = paths[currentIdx];
      location += path.trim();
      currentIdx -= 1;
      location += currentIdx !== -1 ? ', ' : '';
    }

    if (isSearch) {
      switch (idx) {
        case 0:
          Mixpanel.trackEvent(searchBreadcrumbCountry());
          break;
        case 1:
          Mixpanel.trackEvent(searchBreadcrumbState());
          break;
        case 2:
          Mixpanel.trackEvent(searchBreadcrumbCity());
          break;
        case 3:
          Mixpanel.trackEvent(searchBreadcrumbNeighborhood());
          break;
        case 4:
          Mixpanel.trackEvent(searchBreadcrumbStreet());
          break;
        default:
          Mixpanel.trackEvent(searchBreadcrumbStreet());
          break;
      }
    }

    const state = modelToSearchBread(location, this.props);

    history.push({ pathname: state });
  }

  render() {
    const { address, postId } = this.props;

    const addressSanitized = decodeURIComponent(address) || '';
    const paths = addressSanitized.replaceAll('-', ',').split(',');

    return (
      <Row className="justify-content-row">
        <Col className="home-town">
          <p>
            <span
              onClick={this.handleHome}
              onKeyPress={this.handleHome}
              role="button"
              tabIndex="0"
            >
              Início &gt;
            </span>
            {
              paths.reverse().map((path, idx) => (
                <span
                  key={path}
                  onClick={(e) => this.handleSearch(e, idx)}
                  onKeyPress={(e) => this.handleSearch(e, idx)}
                  role="button"
                  tabIndex="0"
                >
                  {` ${path.trim()}${(idx + 1) !== paths.length ? ' >' : ''}`}
                </span>
              ))
            }
            {
              postId && (
                <span className="property">
                  {` > Imóvel ${postId}`}
                </span>
              )
            }
          </p>
        </Col>
      </Row>
    );
  }
}

Breadcrumbs.defaultProps = {
  postId: null,
  address: '',
};

Breadcrumbs.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  }).isRequired,
  address: PropTypes.string,
  postId: PropTypes.string,
  isSearch: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {

  },
  dispatch,
);

const mapStateToProps = () => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs));
