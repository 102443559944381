import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import iconCar from '../../../assets/icons/car.svg';
import iconRuler from '../../../assets/icons/ruler.svg';
import iconBed from '../../../assets/icons/bed.svg';
import Contact from '../../../const/post';
import NoImage from '../../../assets/imgs/no-image.png';
import TagSeleto from '../../../assets/icons/tagHomerSeleto.svg';
import Mixpanel from '../../../mixpanel';
import { searchCard } from '../../../const/mixpanel';

class Post extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  handleSubmit(event, postId) {
    event.preventDefault();

    const { match, isSearch } = this.props;
    const { params } = match;
    const { slug } = params;

    if (isSearch) {
      Mixpanel.trackEvent(searchCard());
    }

    window.open(`${window.location.origin}/${slug}/post/${postId}`);
  }

  // eslint-disable-next-line class-methods-use-this
  onImgLoad(event) {
    const { naturalHeight, naturalWidth, classList } = event.target;

    let isImgContains = true;

    if (naturalHeight >= naturalWidth) {
      isImgContains = true;
    }
    if (naturalHeight <= naturalWidth) {
      isImgContains = false;
    }

    classList.add(`${isImgContains ? 'img-contains' : 'img-cover'}`);
  }

  render() {
    const {
      getAgentDetails, toggle, post, match, postDetails,
    } = this.props;

    const { params } = match;
    const slug = params;
    const agentDetails = getAgentDetails && getAgentDetails.data;

    const showBroker = slug.slug !== 'buscar-imoveis';
    const totalPrice = post !== undefined ? (post.CondoFee || 0)
      + (post.PropertyTaxes || 0) + (post.Price || 0) : 0;
    const propertyType = typeof post.PropertyType === 'string'
      ? post.PropertyType
      : post.PropertyType.shortLabelText;

    return (
      <>
        <Card key={post.PostId}>
          <CardBody onClick={(e) => this.handleSubmit(e, post.PostId)}>
            {post.IsHomerSell
              && (
                <img
                  loading="lazy"
                  src={TagSeleto}
                  alt="Tag Homer Seleto"
                  className={postDetails ? 'TagSeletoCard' : 'TagSeleto'}
                />
              )}
            <img
              loading="lazy"
              width="100%"
              src={((post.PostMedia && post.PostMedia.length > 0)
                && (post.PostMedia[0].MediaUrl !== undefined && post.PostMedia[0].MediaUrl))
                || ((post.PostMedia && post.PostMedia.length > 0)
                  && (post.PostMedia[0].url !== undefined && post.PostMedia[0].url))
                || post.Media || NoImage}
              alt="Imagens"
              className="card-img-top"
              onError={(e) => { e.target.onerror = null; e.target.src = NoImage; }}
              onLoad={this.onImgLoad}
            />
            <div className="inline">
              <CardTitle tag="h5">{propertyType}</CardTitle>
              <CardTitle tag="h5">
                &nbsp;
                <font color="#27B062">{post.IsRent ? 'aluguel ' : 'à venda '}</font>
              </CardTitle>
            </div>
            <CardTitle tag="h5">
              {post.IsRent ? `${totalPrice.BRLCurrency()}` : `${post.MaximumPrice ? post.MaximumPrice.BRLCurrency() : post.Price.BRLCurrency()}`}
            </CardTitle>
            <CardSubtitle tag="h6" className="mb-2 text-muted">{Contact.ADRESS}</CardSubtitle>
            <CardText numberOfLines={1} ellipsizeMode="head" className="location">{post.Location}</CardText>
            <CardSubtitle tag="h6" className="mb-2 text-muted">{Contact.FEATURE}</CardSubtitle>
            <Row className="justify-content-center">
              <CardText>
                {!!post.Rooms && (
                  <>
                    <img loading="lazy" src={iconBed} alt="Quartos" className="icons" />
                    <span className="margin-right">
                      {`${post.Rooms} quarto${post.Rooms > 1 ? 's' : ''}`}
                    </span>
                  </>
                )}
              </CardText>
              <CardText>
                {!!post.MaximumArea && (
                  <>
                    <img loading="lazy" src={iconRuler} alt="Área" className="icons" />
                    <span className="margin-right">
                      {`${post.MaximumArea} m²`}
                    </span>
                  </>
                )}
              </CardText>
              <CardText>
                {!!post.ParkingSpots && (
                  <>
                    <img loading="lazy" src={iconCar} alt="Vagas" className="icons" />
                    <span className="margin-right">
                      {`${post.ParkingSpots} vaga${post.ParkingSpots > 1 ? 's' : ''}`}
                    </span>
                  </>
                )}
              </CardText>
            </Row>
          </CardBody>
          <Row className="justify-content-center">
            {!showBroker ? (
              <Button
                type="button"
                className="btn-white-card"
                onClick={(e) => toggle(e, post.PostId)}
              >
                {Contact.TALK_TO}
              </Button>
            )
              : (
                <Button
                  type="button"
                  className="btn-white-card"
                  onClick={(e) => toggle(e, post.PostId, post.UserId)}
                >
                  {`${Contact.TALK}${agentDetails ? agentDetails.FirstName : ''}`}
                </Button>
              )}
          </Row>
        </Card>
      </>
    );
  }
}

Post.defaultProps = {
  // isSeleto: false,
  postDetails: false,
};

Post.propTypes = {
  // isSeleto: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  }).isRequired,
  getAgentDetails: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
  post: PropTypes.shape({
    ParkingSpots: PropTypes.number,
    Area: PropTypes.number,
    Rooms: PropTypes.number,
    PostMedia: PropTypes.arrayOf(PropTypes.object),
    Location: PropTypes.string.isRequired,
    PropertyType: PropTypes.string.isRequired,
    Price: PropTypes.number.isRequired,
    MaximumPrice: PropTypes.number.isRequired,
    MaximumArea: PropTypes.number.isRequired,
    IsRent: PropTypes.bool.isRequired,
    PostId: PropTypes.number.isRequired,
    Media: PropTypes.string,
    IsHomerSell: PropTypes.bool,
    CondoFee: PropTypes.number,
    PropertyTaxes: PropTypes.number,
    UserId: PropTypes.number.isRequired,
  }).isRequired,
  postDetails: PropTypes.bool,
  isSearch: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {

  },
  dispatch,
);

const mapStateToProps = (state) => ({
  getAgentDetails: state.agentdetailsState.getAgentDetails,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Post));
