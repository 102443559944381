import {
  POST_AGENT, LIST_AGENT, GET_AGENT, GET_AGENT_SELETO,
} from '../actionTypes/agent';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

export const INITIAL_STATE = {
  statePostAgent: BASE,
  listAgent: BASE_PAGINATION,
  getAgent: BASE,
  getAgentSeleto: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_AGENT:
      return { ...state, statePostAgent: action.payload };
    case LIST_AGENT:
      return { ...state, listAgent: action.payload };
    case GET_AGENT:
      return { ...state, getAgent: action.payload };
    case GET_AGENT_SELETO:
      return { ...state, getAgentSeleto: action.payload };
    default:
      return state;
  }
};
