export function minMaxRange(numberValue, min, max) {
  if (numberValue > max) {
    return max;
  } if (numberValue < min) {
    return min;
  }
  return numberValue;
}

export function groupArr(data, n) {
  const group = [];
  for (let i = 0, j = 0; i < data.length; i += 1) {
    if (i >= n && i % n === 0) j += 1;
    group[j] = group[j] || [];
    group[j].push(data[i]);
  }
  return group;
}

export function getSourceIdBasedOnPage(path) {
  const pathFormatted = path.toLowerCase();
  if (pathFormatted.includes('home')) {
    return 1;
  }
  if (pathFormatted.includes('header')) {
    return 2;
  }
  if (pathFormatted.includes('post')) {
    return 3;
  }
  if (pathFormatted.includes('postcard')) {
    return 4;
  }
  if (pathFormatted.includes('empreendimento') || pathFormatted.includes('empreendimentos')) {
    return 6;
  }

  return 1;
}
