import { AUTH_USER } from '../actionTypes/auth';

const BASE = {
  state: false,
  data: null,
};

export const INITIAL_STATE = {
  authUser: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, authUser: action.payload };
    default:
      return state;
  }
};
