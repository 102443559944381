import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

const SkeletonLoading = ({
  lines, columns, width, height,
}) => {
  const loadings = [];
  for (let index = 0; index < lines; index += 1) {
    loadings.push(
      <Skeleton
        key={index}
        count={columns}
        width={width}
        height={height / lines}
        className="mr-3 mb-1"
        style={{ borderRadius: '10px' }}
      />,
    );
  }

  return loadings;
};

SkeletonLoading.propTypes = {
  lines: PropTypes.number.isRequired,
  columns: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default SkeletonLoading;
