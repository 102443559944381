export default {
  BREADCRUMBS: {
    INITIAL: 'Seus imóveis',
    MIDDLE: 'Imóveis de Incorporadoras',
    END: 'Empreendimento',
  },
  SEE_PHOTOS: 'Ver fotos',
  DOWNLOAD: 'Baixar material',
  SHARE: 'Compartilhar',
  UNIT_PRICE: 'Unidades a partir de',
  UNIT_PRICE_DOTS: 'Unidades a partir de',
  UNIT_INFORMATION: 'Informações das unidades',
  UNIT_STAGE: 'Estágio da obra',
  TYPOLOGIES: 'Tipologias',
  NEED_MORE_INFO: 'Precisa de mais informações?',
  TALK_SPECIALIST: 'Fale agora com um dos nossos especialistas',
  BUTTON_TALK_SPECIALIST: 'Falar com especialista',
  LOCATION: 'Localização',
  DESCRIPTION: 'Descrição',
  IMPORTANT: 'IMPORTANTE: Os valores exibidos nesta página são os menores de cada tipologia, extraídos da tabela vigente do mês, e poderão não estar mais em vigor. A construtora ou incorporadora pode alterar os preços das unidades, ou qualquer outra informação, inclusive comissionamento, a qualquer momento sem prévio aviso.',
  VISIT: 'Agendar visita',
  ANY_DOUBT: 'Ainda tem dúvidas?',
  WANT_TALK: 'Quero conversar',
  BROKER: 'Corretor Responsável',
  RESPONSIBLE: 'Responsável',
};
