import { axiosInstance as axios } from '../lib/axios';
import Config from '../config/environments';
import { POST_MAP, LIST_MAP } from '../actionTypes/map';
import { viewsModelToModels } from '../mapper/map';
import updateState from './common/common';

export const getDataMap = (data, state) => async (dispatch) => {
  const base = {
    type: LIST_MAP,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.MAP.URL}`;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewsModelToModels(base.payload.data, state);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const getDataMapWithoutPagination = () => async (dispatch) => {
  const base = {
    type: LIST_MAP,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.MAP.URL}?page=1&quantity=100`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewsModelToModels(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const postDataMap = (data) => async (dispatch) => {
  const base = {
    type: POST_MAP,
    payload: {},
    state: true,
    success: true,
  };

  const url = Config.MAP.URL;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const putDataMap = (data) => async (dispatch) => {
  const base = {
    type: POST_MAP,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.MAP.URL}/${data.uuid}`;

  axios.put(url, data).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};
