import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { Row, Col } from 'reactstrap';
import { groupArr } from '../../../lib/utils';
import { PostEnterpriseLoadingComponent, PostEnterpriseComponent } from '.';
import Next from '../../../assets/icons/arrowNext.svg';
import Prev from '../../../assets/icons/arrowPrev.svg';
import PrevDisabled from '../../../assets/icons/arrowPrevDisabled.svg';
import NextDisabled from '../../../assets/icons/arrowNextDisabled.svg';
import Mixpanel from '../../../mixpanel';
import { homeSearchNextProperties, homeSearchPreviousProperties } from '../../../const/mixpanel';

class PostsEnterprise extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {
      isMobile, posts, isLoading, isHomer,
    } = this.props;

    const groupPosts = groupArr(posts, isMobile ? 1 : 3);

    if (isLoading && posts.length === 0) {
      return (
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          centerMode
          centerSlidePercentage={100}
          renderArrowNext={(clickHandler, hasNext) => {
            <div className="arrow-next" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
              <img loading="lazy" src={hasNext ? Next : NextDisabled} alt="Prev" />
            </div>;
          }}
          renderArrowPrev={(clickHandler, hasPrev) => (
            <div className="arrow-prev" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
              <img loading="lazy" src={hasPrev ? Prev : PrevDisabled} alt="Prev" />
            </div>
          )}
        >
          {isMobile ? (
            <Row className="carousel-container">
              <div>
                <PostEnterpriseLoadingComponent isMobile={isMobile} />
              </div>
            </Row>
          ) : (
            <Row className="carousel-container">
              <Col xs={4} className="p-0">
                <PostEnterpriseLoadingComponent isMobile={isMobile} />
              </Col>
              <Col xs={4} className="p-0">
                <PostEnterpriseLoadingComponent isMobile={isMobile} />
              </Col>
              <Col xs={4} className="p-0">
                <PostEnterpriseLoadingComponent isMobile={isMobile} />
              </Col>
            </Row>
          )}
        </Carousel>
      );
    }

    return (
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        centerMode
        centerSlidePercentage={100}
        renderArrowNext={(clickHandler, hasNext) => (
          <div className="arrow-next" onClick={() => { clickHandler(); Mixpanel.trackEvent(homeSearchNextProperties()); }} role="button" tabIndex={0} aria-hidden="true">
            <img loading="lazy" src={hasNext ? Next : NextDisabled} alt="Prev" />
          </div>
        )}
        renderArrowPrev={(clickHandler, hasPrev) => (
          <div className="arrow-prev" onClick={() => { clickHandler(); Mixpanel.trackEvent(homeSearchPreviousProperties()); }} role="button" tabIndex={0} aria-hidden="true">
            <img loading="lazy" src={hasPrev ? Prev : PrevDisabled} alt="Prev" />
          </div>
        )}
      >
        {groupPosts.map((groupPost) => (
          <Row className="carousel-container" key={`Group-${groupPost[0].PostId}`}>
            {
              groupPost.map((post, idx) => (
                <>
                  {isMobile ? (
                    <div>
                      <PostEnterpriseComponent
                        isMobile={isMobile}
                        key={groupPost[idx].PostId}
                        post={post}
                        isHomer={isHomer}
                        isHome
                      />
                    </div>
                  ) : (
                    <Col xs={groupPost.length < 3 ? 6 : 4} className="p-0">
                      <PostEnterpriseComponent
                        isMobile={isMobile}
                        key={groupPost[idx].PostId}
                        post={post}
                        isHomer={isHomer}
                        isHome
                      />
                    </Col>
                  )}
                </>
              ))
            }
          </Row>
        ))}
      </Carousel>
    );
  }
}

PostsEnterprise.defaultProps = {
  isMobile: false,
  isLoading: true,
};

PostsEnterprise.propTypes = {
  isMobile: PropTypes.bool,
  isLoading: PropTypes.bool,
  isHomer: PropTypes.bool.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  }).isRequired,
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {

  },
  dispatch,
);

const mapStateToProps = () => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostsEnterprise));
