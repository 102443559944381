import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { groupArr } from '../../../lib/utils';
import PostLoading from './postLoading';
import PostComponent from './post';
import Next from '../../../assets/icons/arrowNext.svg';
import Prev from '../../../assets/icons/arrowPrev.svg';
import PrevDisabled from '../../../assets/icons/arrowPrevDisabled.svg';
import NextDisabled from '../../../assets/icons/arrowNextDisabled.svg';

class Posts extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, postId) {
    event.preventDefault();

    const { match } = this.props;
    const { params } = match;
    const { slug } = params;

    window.open(`${window.location.origin}/${slug}/post/${postId}`);
  }

  render() {
    const {
      isSeleto, isMobile, posts, toggle, isLoading, postDetails,
    } = this.props;

    const groupPosts = groupArr(posts, isMobile ? 1 : 3);

    if (isLoading && posts.length === 0) {
      return (
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          centerMode
          centerSlidePercentage={100}
          renderArrowNext={(clickHandler, hasNext) => {
            if (!hasNext) {
              return (
                <div className="arrow-next" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
                  <img loading="lazy" src={NextDisabled} alt="Prev" />
                </div>
              );
            }
            return (
              <div className="arrow-next" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
                <img loading="lazy" src={Next} alt="Next" />
              </div>
            );
          }}
          renderArrowPrev={(clickHandler, hasNext) => {
            if (!hasNext) {
              return (
                <div className="arrow-prev" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
                  <img loading="lazy" src={PrevDisabled} alt="Prev" />
                </div>
              );
            }
            return (
              <div className="arrow-prev" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
                <img loading="lazy" src={Prev} alt="Prev" disabled="true" />
              </div>
            );
          }}
        >
          <div className="carousel-container">
            <PostLoading />
            <PostLoading />
            <PostLoading />
          </div>
        </Carousel>
      );
    }

    return (
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        centerMode
        centerSlidePercentage={100}
        renderArrowNext={(clickHandler, hasNext) => {
          if (!hasNext) {
            return (
              <div className="arrow-next" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
                <img loading="lazy" src={NextDisabled} alt="Prev" />
              </div>
            );
          }
          return (
            <div className="arrow-next" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
              <img loading="lazy" src={Next} alt="Next" />
            </div>
          );
        }}
        renderArrowPrev={(clickHandler, hasNext) => {
          if (!hasNext) {
            return (
              <div className="arrow-prev" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
                <img loading="lazy" src={PrevDisabled} alt="Prev" />
              </div>
            );
          }
          return (
            <div className="arrow-prev" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
              <img loading="lazy" src={Prev} alt="Prev" disabled="true" />
            </div>
          );
        }}
      >
        {groupPosts.map((groupPost) => (
          <div className="carousel-container" key={`Group-${groupPost[0].PostId}`}>
            {
              groupPost.map((post, idx) => (
                <PostComponent
                  postDetails={postDetails}
                  key={groupPost[idx].PostId}
                  post={post}
                  isSeleto={isSeleto}
                  toggle={toggle}
                />
              ))
            }
          </div>
        ))}
      </Carousel>
    );
  }
}

Posts.defaultProps = {
  isSeleto: false,
  isMobile: false,
  isLoading: true,
  postDetails: false,
};

Posts.propTypes = {
  isSeleto: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLoading: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  }).isRequired,
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggle: PropTypes.func.isRequired,
  postDetails: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {

  },
  dispatch,
);

const mapStateToProps = () => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Posts));
