import {
  POST_GEO, LIST_GEO, GET_GEO, GET_GEO_BREADCRUMBS,
} from '../actionTypes/geo';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

export const INITIAL_STATE = {
  statePostGeo: BASE,
  listGeo: BASE_PAGINATION,
  getGeo: BASE,
  getGeoBreadcrumbs: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_GEO:
      return { ...state, statePostGeo: action.payload };
    case LIST_GEO:
      return { ...state, listGeo: action.payload };
    case GET_GEO:
      return { ...state, getGeo: action.payload };
    case GET_GEO_BREADCRUMBS:
      return { ...state, getGeoBreadcrumbs: action.payload };
    default:
      return state;
  }
};
