import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row,
  Button,
  Card,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Config from '../../../config/environments';
import Contact from '../../../const/fakePost';
import FakePostImage from '../../../assets/imgs/post-fake.svg';

class PostFake extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  handleSubmit(event) {
    event.preventDefault();

    window.open(`${Config.ACCOUNT.URL}${Config.ACCOUNT.REAL_ESTATE}${Config.ACCOUNT.CALCULATOR}${Config.ACCOUNT.UTM_SOURCE}${Config.ACCOUNT.UTM_MEDIUM}${Config.ACCOUNT.UTM_CAMPAIGN}${Config.ACCOUNT.UTM_TAG}`);
  }

  render() {
    return (
      <>
        <Card className="fake-post">
          <Row>
            <Col>
              <img loading="lazy" src={FakePostImage} alt="Casa" className="fake-image" />
            </Col>
          </Row>
          <Row>
            <Col className="col-title-fake">
              <h3 className="title">{Contact.TITLE}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-fake">
                {Contact.INFO_TEXT}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="button-fake" onClick={this.handleSubmit}>{Contact.LABEL_BUTTON}</Button>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}

PostFake.defaultProps = {
};

PostFake.propTypes = {
  // isSeleto: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  }).isRequired,
  getAgentDetails: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  post: PropTypes.shape({
    ParkingSpots: PropTypes.number,
    Area: PropTypes.number,
    Rooms: PropTypes.number,
    PostMedia: PropTypes.arrayOf(PropTypes.object),
    Location: PropTypes.string.isRequired,
    PropertyType: PropTypes.string.isRequired,
    Price: PropTypes.number.isRequired,
    IsRent: PropTypes.bool.isRequired,
    PostId: PropTypes.number.isRequired,
    Media: PropTypes.string,
    IsHomerSell: PropTypes.bool,
    CondoFee: PropTypes.number,
    PropertyTaxes: PropTypes.number,
    UserId: PropTypes.number.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {

  },
  dispatch,
);

const mapStateToProps = (state) => ({
  getAgentDetails: state.agentdetailsState.getAgentDetails,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostFake));
