import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Home from './home';
import Config from '../../config/environments';
import MetaDecorator from '../component/Meta';

function HomerSeleto(props) {
  const { match } = props;
  const { params } = match;
  const { slug } = params;
  let isSeleto = false;

  if (!slug || slug === 'buscar-imoveis') {
    params.slug = Config.SECURITY.SELETO;
    isSeleto = true;
  }

  return (
    <>
      <MetaDecorator
        title="Homer Imóveis - Apartamentos e Casas à Venda no Rio de Janeiro, São Paulo e outros estados"
        description="O Homer te ajuda a encontrar o Apartamento ideal em todos os estados do Brasil com um processo de Compra Especial."
      />
      {
        params.slug === Config.SECURITY.SELETO ? (
          <div className="homer-seleto">
            <Home match={match} isSeleto={isSeleto} />
          </div>
        )
          : <Redirect to={{ pathname: `${slug}/home` }} />
        }
    </>
  );
}

HomerSeleto.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  }).isRequired,
};

export default HomerSeleto;
