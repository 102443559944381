export default {
  IMMOBILE: 'O imóvel que você procura está aqui.',
  IMMOBILE_MOBILE: 'O imóvel que você deseja comprar está aqui.',
  MAKE_COMFORTABLE: 'Entre e fique à vontade.',
  HOME: 'Sua casa nova está aqui',
  BUY: 'Comprar',
  RENT: 'Alugar',
  ADRESS: 'Endereço',
  TYPE: 'Tipo do imóvel',
  PRICE: 'Imóvel até',
  BEDROOMS: 'Quartos',
  VACANCIES: 'Vagas',
  SEE_IMMOBILE: 'Ver imóveis na região',
  FIND: 'Encontrar imóveis',
  ADVERTISE: 'Anuncie seu imóvel grátis >',
  RECOMMENDED: 'Recomendados para você',
  RECENTLY: 'Imóveis adicionados recentemente',
  SELECTED: 'Imóveis escolhidos a dedo',
  SEARCH_PROPERTY: 'Buscar imóveis',
  DESCRIPTION_SELETO: 'Curadoria de imóveis com preço de mercado e a certeza de uma compra com segurança e qualidade.',
  ADVERTISE_SELETO: 'Anuncie no Homer Seleto >',
  SEE_ENTERPRISE: 'Ver todos os imóveis de incorporadora',
};
