import { POST_LOCATION, LIST_LOCATION, LIST_LOCATION_BREADCRUMBS } from '../actionTypes/location';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

export const INITIAL_STATE = {
  statePostLocation: BASE,
  listLocation: BASE_PAGINATION,
  listLocationBreadcrumbs: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_LOCATION:
      return { ...state, statePostLocation: action.payload };
    case LIST_LOCATION:
      return { ...state, listLocation: action.payload };
    case LIST_LOCATION_BREADCRUMBS:
      return { ...state, listLocationBreadcrumbs: action.payload };
    default:
      return state;
  }
};
