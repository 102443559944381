/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Col, Card, Row,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Config from '../../../config/environments';
import ImgCamboriu from '../../../assets/imgs/imgCamboriu.svg';
import { enterpriseCardBalneario } from '../../../const/mixpanel';
import Mixpanel from '../../../mixpanel';

class CardInfo extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { isMobile } = this.props;
    const isIos = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const target = isIos && isMobile ? '_self' : '_blank';

    return (
      <>
        <Col lg={3} className="container-card-enterprise">
          <Card className="card-info">
            <img src={ImgCamboriu} alt="Imagem Camboriu" />
            <div className="content">
              <Row />
              <Row>
                <Col>
                  <h4>
                    O Homer também trabalha com
                    {' '}
                    <strong>Imóveis novos</strong>
                    {' '}
                    em
                    {' '}
                    <strong>Balneário Camboriú</strong>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <a href={`${Config.LP_HOMER.URL}${Config.LP_HOMER.BALNEARIO}`} onClick={() => { Mixpanel.trackEvent(enterpriseCardBalneario()); }} target={target} rel="noreferrer" className="btn_web_primary">
                    Confira
                  </a>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </>
    );
  }
}

CardInfo.defaultProps = {
  isMobile: false,
};

CardInfo.propTypes = {
  isMobile: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);

const mapStateToProps = () => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardInfo));
