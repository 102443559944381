export default {
  TITLE: 'Ainda tem dúvidas? Nossa especialista',
  HELPER: 'te ajuda.',
  NAME: 'Nome:',
  EMAIL: 'E-mail:',
  TELEPHONE: 'Telefone:',
  MESSAGE: 'Mensagem:',
  ONCLICK: 'Ao clicar no botao "Enviar" você afirma ter mais de 18 anos e aceita nossos',
  TERMS: ' termos de uso',
  AND: ' e as ',
  PRIVACY: 'políticas de privacidade',
  SUBMIT: 'Enviar',
  STEP_TITLE: 'Passo a passo para ',
  STEP01: 'Avaliação',
  INDICATE: 'Indique',
  STEP01_TEXT: ' seu imóvel. Após ele ser cadastrado iniciamos a avaliação.',
  STEP02: 'Início da parceria',
  STEP02_TEXT: 'Após aprovação assinamos um termo de parceria com corretor e proprietário.',
  STEP03: 'Divulgação',
  STEP03_TEXT: 'Mantemos você atualizado sobre a divulgação e marketing.',
  STEP04: 'Negócio fechado! 🤝',
  STEP04_TEXT: 'Só alegria! Não deixe de nos avaliar e indicar para amigos.',
  MEET: 'Quero entender melhor',
  SELL: 'Quero vender',
};
