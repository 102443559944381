export default {
  PostId: 0,
  UserId: 0,
  PropertyType: 'Apartamento',
  Location: '',
  Rooms: 0,
  Area: 0,
  Price: 0,
  ParkingSpots: 0,
  Description: '',
  Tags: [],
  IsRent: false,
  PageTotalMatches: 0,
  HasExtraInfo: false,
  ShowHomerSellsOffer: false,
  UserCreciVerifiedIdStatus: 0,
  IsHomerSell: false,
  CondoFee: 0,
  PropertyTaxes: 0,
  Latitude: 0.0,
  Longitude: 0.0,
  InstallmentPrice: 0,
};
