import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import ImageViewer from 'react-simple-image-viewer';
import { groupArr } from '../../../lib/utils';
import SkeletonLoading from '../../utils/skeletonLoading';
import ArrowNext from '../../../assets/icons/arrowNext.svg';
import ArrowPrev from '../../../assets/icons/arrowPrev.svg';
import PrevDisabled from '../../../assets/icons/arrowPrevDisabled.svg';
import NextDisabled from '../../../assets/icons/arrowNextDisabled.svg';
import TagSeleto from '../../../assets/icons/tagHomerSeleto.svg';

class Images extends Component {
  constructor(props, context) {
    super(props, context);

    this.onImgLoad = this.onImgLoad.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  onImgLoad(event) {
    const { naturalHeight, naturalWidth, classList } = event.target;

    let isImgContains = true;

    if (naturalHeight >= naturalWidth) {
      isImgContains = true;
    }
    if (naturalHeight <= naturalWidth) {
      isImgContains = false;
    }

    classList.add(`${isImgContains ? 'img-contains' : 'img-cover'}`);
  }

  render() {
    const {
      isMobile, postDetails, isLoading, isSeleto, openImage, openImageViewer,
      closeImageViewer, currentImage,
    } = this.props;

    const columns = isMobile ? 1 : 3;

    if (isLoading && !postDetails.PostMedia) {
      return (
        <SkeletonLoading lines={1} columns={columns} width={350} height={260} />
      );
    }

    const mockImgs = [{
      MediaUrl: 'no-image',
      PostMediaId: 1,
    },
    {
      MediaUrl: '',
      PostMediaId: 2,
    },
    {
      MediaUrl: '',
      PostMediaId: 3,
    }];

    const qttImages = postDetails.PostMedia && postDetails.PostMedia.length > 0
      ? postDetails.PostMedia : mockImgs;

    const groupImages = groupArr(qttImages, isMobile ? 1 : 3);

    const images = [];
    groupImages.map((groupMedia) => (
      groupMedia.map((media) => (
        images.push(media.MediaUrl)
      ))));

    return (
      <>
        {
          (openImage) && (
            <ImageViewer
              toggle={openImageViewer}
              openImage={openImageViewer}
              src={images}
              currentIndex={currentImage}
              onClose={closeImageViewer}
              disableScroll
              backgroundStyle={{
                backgroundColor: 'rgba(0,0,0,0.9)',
              }}
              closeOnClickOutside
            />
          )
        }
        <Row className="homer-seleto-mobile">
          {
          isSeleto && (
          <>
            <Col md={1}>
              <img
                loading="lazy"
                src={TagSeleto}
                alt="Tag Homer Seleto"
                className="homer-seleto-tag"
              />
            </Col>
          </>
          )
          }
        </Row>
        <Carousel
          className="carousel-imgs"
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          centerMode
          centerSlidePercentage={100}
          renderArrowNext={(clickHandler, hasNext) => {
            if (!hasNext) {
              return (
                <div className="arrow-next" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
                  <img loading="lazy" src={NextDisabled} alt="Prev" />
                </div>
              );
            }
            return (
              <div className="arrow-next" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
                <img loading="lazy" src={ArrowNext} alt="Next" />
              </div>
            );
          }}
          renderArrowPrev={(clickHandler, hasNext) => {
            if (!hasNext) {
              return (
                <div className="arrow-prev" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
                  <img loading="lazy" src={PrevDisabled} alt="Prev" />
                </div>
              );
            }
            return (
              <div className="arrow-prev" onClick={clickHandler} role="button" tabIndex={0} aria-hidden="true">
                <img loading="lazy" src={ArrowPrev} alt="Prev" disabled="true" />
              </div>
            );
          }}
        >

          {groupImages.map((groupMedia) => (
            <div className="carousel-container" key={`Group-${groupMedia[0].PostMediaId}`}>
              {
                groupMedia.map((media, idx) => (
                  <Col
                    md={4}
                    key={groupMedia[idx].PostMediaId}
                    onClick={(e) => openImageViewer(e, images.indexOf(media.MediaUrl))}
                    onKeyDown={(e) => openImageViewer(e, images.indexOf(media.MediaUrl))}
                  >
                    <img
                      loading="lazy"
                      src={media.MediaUrl.replace('http://', 'https://')}
                      alt="Imagens"
                      className="room-img"
                      onError={(e) => { e.target.onerror = null; e.target.src = '../../assets/imgs/placeholder.png'; }}
                      onLoad={this.onImgLoad}
                    />

                  </Col>
                ))
              }
            </div>
          ))}
        </Carousel>
      </>
    );
  }
}

Images.defaultProps = {
  isLoading: false,
  isMobile: false,
  isSeleto: true,
  openImage: false,
  currentImage: 0,
};

Images.propTypes = {
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isSeleto: PropTypes.bool,
  openImage: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  openImageViewer: PropTypes.func.isRequired,
  closeImageViewer: PropTypes.func.isRequired,
  currentImage: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  }).isRequired,
  postDetails: PropTypes.shape({ PostMedia: PropTypes.arrayOf(PropTypes.object) }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {

  },
  dispatch,
);

const mapStateToProps = () => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Images));
