import './middlewares/polyfill';
import { Provider } from 'react-redux';
import React from 'react';
import { ReactTableDefaults } from 'react-table-6';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { unregister } from './middlewares/registerServiceWorker';
import createStore from './store/store';
import TableDefaults from './config/table/table';
import Meta from './lib/meta';
import packageJson from '../package.json';
import BrowserRouter from './routes/browserRouter';
import 'moment/locale/pt-br';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/style.scss';

// eslint-disable-next-line no-underscore-dangle
const store = createStore(window.__INITIAL_STATE__);

Object.assign(ReactTableDefaults, TableDefaults);

if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE.trim() !== 'PROD') {
  Meta();
}

Sentry.init({
  dsn: 'https://c605c57898e141929309dde90638f286@o967773.ingest.us.sentry.io/5919161',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: `${packageJson.name}@${packageJson.version}`,
  environment: process.env.REACT_APP_STAGE.trim(),
  autoSessionTracking: true,
});

const APP = (
  <Provider store={store}>
    <div>
      <BrowserRouter />
      <span className="version">
        v
        {packageJson.version}
      </span>
    </div>
  </Provider>
);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(APP, rootElement);
} else {
  ReactDOM.render(APP, rootElement);
}

unregister();
