import { POST_MAP, LIST_MAP } from '../actionTypes/map';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

export const INITIAL_STATE = {
  statePostMap: BASE,
  listMap: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_MAP:
      return { ...state, statePostMap: action.payload };
    case LIST_MAP: {
      const maps = JSON.parse(JSON.stringify(state.listMap));
      if (action.payload && action.payload.data) {
        maps.data.list = [...action.payload.data.list];
        maps.data.total += action.payload.data.total;
        maps.data.success = action.payload.success;
        maps.state = false;
      } else {
        maps.state = true;
      }
      return { ...state, listMap: maps };
    }
    default:
      return state;
  }
};
