export default {
  Location: '',
  Description: '',
  Latitude: 0,
  Longitude: 0,
  EnterpriseName: '',
  DeveloperName: '',
  UpdatedDateTime: '',
  CreatedDateTime: '',
  Features: [],
  DevelopmentStatus: [],
  UnitInformation: [],
  Typologies: [],
  PostMedia: [],
  PriceLabelText: '',
  disabledCity: true,
  disabledDistrict: true,
  Price: 0,
  Ordination: '-date',
  IsOrdination: false,
  IsViewerOpen: false,
  NewsConstructions: [],
  Images: [],
  Requested: true,
  IsLoading: false,
  Scrolled: false,
  IsOpenFilter: false,
  Initializated: true,
  UpdateDeveloperName: true,
  PreviousState: '',
  CurrentImage: 0,
  NumberCardsPosts: 20,
  PreviousPrice: 0,
  PreviousCity: '',
  PreviousDistricts: '',
  PreviousDeveloper: '',
  PreviousOrdination: '-date',
  City: 'Todos',
  State: 'Todos',
  Districts: 'Todos',
  Developer: 1,
  DevelopersName: '',
  RequestedAgentDetails: false,
  RequestedAgent: false,
  Area: '0',
  Rooms: '0',
  ParkingSpots: '0',
  PreviousArea: '',
  PreviousRooms: '',
  PreviousParkingSpots: '',
  Quantities: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
};
