export default {
  State: '',
  City: '',
  Ordination: '',
  Districts: '',
  Price: '0',
  Rooms: '0',
  ParkingSpots: '0',
  Area: '0',
};
