import {
  LIST_STATE, LIST_CITY, LIST_DISTRICT, LIST_DEVELOPERS,
} from '../actionTypes/filter';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  loading: true,
  data: {
    total: 0,
    list: [],
  },
};

export const INITIAL_STATE = {
  stateGalleryState: BASE,
  listState: BASE_PAGINATION,
  listCity: BASE_PAGINATION,
  listDistrict: BASE_PAGINATION,
  listGallery: BASE_PAGINATION,
  listDevelopers: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_STATE:
      return { ...state, listState: action.payload };
    case LIST_CITY:
      return { ...state, listCity: action.payload };
    case LIST_DISTRICT:
      return { ...state, listDistrict: action.payload };
    case LIST_DEVELOPERS:
      return { ...state, listDevelopers: action.payload };
    default:
      return state;
  }
};
