import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Col,
  Row,
  Input,
  Button,
  Container,
  Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { AppHeader } from '@coreui/react';
import { withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import Supercluster from 'supercluster';
import ReactAutocomplete from 'react-autocomplete';
import { geolocated } from 'react-geolocated';
import BaseComponent from './base';
import { getDataAgent } from '../../actions/agent';
import { getDataAgentDetails } from '../../actions/agentdetails';
import { getDataPost } from '../../actions/post';
import { getDataPropertyTypes } from '../../actions/propertytypes';
import { getDataLocation } from '../../actions/location';
import { getDataMap } from '../../actions/map';
import Model from '../../model/result';
import searchBlack from '../../assets/icons/searchBlack.svg';
import BreadcrumbsComponent from '../component/breadcrumbs';
import Map from '../../assets/icons/map.svg';
import List from '../../assets/icons/list.svg';
import { modelToViewModel as modelToViewModelAgent } from '../../mapper/agent';
import { modelToViewModel as modelToViewModelAgentDetails } from '../../mapper/agentdetails';
import { modelToViewModel as modelToViewModelMap, viewModelToState } from '../../mapper/map';
import { modelToSearch } from '../../mapper/home';
import { modelToViewModel as modelToViewModelLocation } from '../../mapper/location';
import Config from '../../config/environments';
import { PostComponent } from '../component/post';
import DefaultHeader from '../../containers/defaultHeader';
import Contact from '../../const/results';
import { ContactModalComponent } from '../component/contact';
import DefaultFooter from '../../containers/defaultFooter';
import { getDataGeo } from '../../actions/geo';
import MetaDecorator from '../component/Meta';
import HomerOg from '../../assets/imgs/HomerOG.png';
import Mixpanel from '../../mixpanel';
import {
  searchMapImmobiles, searchOrdination, searchOrdinationHigherCost, searchOrdinationLowerCost,
  searchOrdinationMostRecent, searchOrdinationMostRevelant, searchTalkWith,
} from '../../const/mixpanel';

class Results extends BaseComponent {
  constructor(props, context) {
    super(props, context);
    this.handleResize = this.handleResize.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleLocation = this.handleLocation.bind(this);
    this.handleLocationSelected = this.handleLocationSelected.bind(this);
    this.handleShowFilter = this.handleShowFilter.bind(this);
    this.state = {
      ...Model, Width: window.innerWidth, ShowMap: true, ShowSearch: false, Filter: false, PostId: '', OwnerId: '',
    };
    const { match } = props;
    const { params } = match;
    const { slug } = params;
    const state = modelToViewModelAgent(slug);
    props.getDataAgent(state);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
    const state = viewModelToState(this.props);

    this.setState(state);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      RequestedAgentDetails, RequestedProperties, RequestedMap, MapNE, ZoomLevel, Filter,
      LatCenter, LngCenter,
    } = nextState;
    const { getAgent, match } = nextProps;
    const agent = getAgent && getAgent.data;
    const currentState = this.state;
    const p = this.props;
    const { params } = match;

    if (agent && !RequestedAgentDetails) {
      const state = modelToViewModelAgentDetails(agent);
      nextProps.getDataAgentDetails(state);
      this.setState({ RequestedAgentDetails: true });
      return false;
    }

    if (!RequestedProperties) {
      nextProps.getDataPropertyTypes();
      this.setState({ RequestedProperties: true });
      return false;
    }

    if (agent && MapNE && !RequestedMap) {
      const state = modelToViewModelMap(nextState, this.props, agent);
      nextProps.getDataMap(state);
      this.setState({ RequestedMap: true, SelectedClusterId: 0 });
    }

    if (agent && MapNE && (ZoomLevel !== currentState.ZoomLevel
      || LatCenter !== currentState.LatCenter || LngCenter !== currentState.LngCenter)) {
      const state = modelToViewModelMap(nextState, this.props, agent);
      nextProps.getDataMap(state);
      this.setState({ SelectedClusterId: 0 });
    }

    if (JSON.stringify(params) !== JSON.stringify(p.match.params)) {
      const state = modelToViewModelMap(nextState, nextProps, agent);
      nextProps.getDataMap(state);
      this.setState({ RequestedMap: true, SelectedClusterId: 0 });
    }

    if (Filter) {
      const state = modelToViewModelMap(nextState, nextProps, agent);
      nextProps.getDataMap(state);
      this.setState({ RequestedMap: true, SelectedClusterId: 0, Filter: false });
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    this.setState({ Width: window.innerWidth });
  }

  handleBack(event) {
    event.preventDefault();

    const { match, history } = this.props;
    const { params } = match;
    const { slug } = params;
    history.push({ pathname: `/${slug}` });
  }

  handleModal(event, postId, ownerId) {
    event.preventDefault();
    const { IsOpen } = this.state;

    if (!IsOpen) {
      Mixpanel.trackEvent(searchTalkWith());
    }

    this.setState({ IsOpen: !IsOpen, PostId: postId, OwnerId: ownerId });
  }

  handleShowFilter(event) {
    event.preventDefault();
    const { ShowSearch } = this.state;
    this.setState({ ShowSearch: !ShowSearch });
  }

  handleFilter(event) {
    event.preventDefault();

    const { history } = this.props;
    const state = modelToSearch(this.state, this.props);
    history.push({ pathname: state });
    this.setState({
      PropertyTypeSearch: '', QttRoomsSearch: '', Location: '', Filter: true,
    });
    window.location.reload();
  }

  handleChange(data) {
    const { center, zoom, bounds } = data;
    const ne = { lat: bounds.ne.lat, lng: bounds.ne.lng };
    const sw = { lat: bounds.sw.lat, lng: bounds.sw.lng };

    this.setState({
      MapNE: ne,
      MapSW: sw,
      LatCenter: center.lat,
      LngCenter: center.lng,
      ZoomLevel: zoom,
      LocationBoundaries: [
        bounds.nw.lng,
        bounds.se.lat,
        bounds.se.lng,
        bounds.nw.lat,
      ],
    });
    const { getAgent } = this.props;
    const agent = getAgent && getAgent.data;
    const p = this.props;
    if (agent) {
      const state = modelToViewModelMap(this.state, this.props, agent);
      p.getDataMap(state);
      this.setState({ RequestedMap: true, SelectedClusterId: 0, Filter: false });
    }
  }

  handleLocation(event, location) {
    event.preventDefault();

    if (location.length >= 3 && location !== undefined) {
      const p = this.props;
      const state = modelToViewModelLocation(location, p.coords);
      p.getDataLocation(state);
    }

    this.setState({ Location: location });
  }

  handleLocationSelected(event, location) {
    event.preventDefault();

    if (location.length >= 3) {
      const p = this.props;
      const state = modelToViewModelLocation(location, p.coords);
      p.getDataLocation(state);
    }

    this.setState({ Location: location });
  }

  render() {
    const p = this.props;
    const {
      location, match, listPropertyTypes, listMap, geo, listLocation,
    } = this.props;
    const { params } = match;
    const {
      googlePlaceName, slug, lat, long, propertyType,
    } = params;
    const {
      Width, PropertyTypeSearch, QttRoomsSearch,
      ZoomLevel, LatCenter, LngCenter, MaxZoomLevel, OrderBy,
      LocationBoundaries, SelectedClusterId, SelectedProperties,
      RequestedFilter, Location, ShowMap, IsOpen, ShowSearch, PostId, OwnerId,
    } = this.state;
    const latitude = LatCenter || parseFloat(lat) || geo.Latitude;
    const longitude = LngCenter || parseFloat(long) || geo.Longitude;

    const locations = (listLocation && listLocation.data && listLocation.data.list) || [];
    const properties = (listPropertyTypes && listPropertyTypes.data
      && listPropertyTypes.data.list) || [];
    const defaultPosition = (listMap && listMap.data) || {};
    const defaultPosts = (listMap && listMap.data && listMap.data.list) || [];
    let posts = JSON.parse(JSON.stringify(defaultPosts));

    if (OrderBy === 'MostRelevant') {
      posts = posts.sort((x) => (x.Media ? -1 : 1));
      posts = posts.sort((x) => {
        if (x.IsHomerSell && x.Media) {
          return -2;
        }
        if (x.IsHomerSell && !x.Media) {
          return -1;
        }
        if (!x.IsHomerSell && !x.Media) {
          return 1;
        }
        return 2;
      });
    }
    if (OrderBy === 'MostRecent') {
      posts = defaultPosts;
    }
    if (OrderBy === 'LowestCost') {
      posts = posts.sort((a, b) => ((a.Price >= b.Price) ? 1 : -1));
    }
    if (OrderBy === 'HigherCost') {
      posts = posts.sort((a, b) => ((a.Price <= b.Price) ? 1 : -1));
    }
    if (RequestedFilter && Location) {
      posts = posts.filter((x) => x.Location.toLowerCase().includes(Location.toLowerCase()));
    }

    const points = posts.map((post) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        id: post.PostId,
        longitude: post.Longitude,
        latitude: post.Latitude,
      },
      geometry: {
        type: 'Point',
        coordinates: [
          parseFloat(post.Longitude),
          parseFloat(post.Latitude),
        ],
      },
    }));

    const supercluster = new Supercluster({
      radius: 40, maxZoom: MaxZoomLevel,
    });
    supercluster.load(points);

    let clusters = [];

    if (LocationBoundaries) {
      clusters = supercluster.getClusters(LocationBoundaries, MaxZoomLevel);
      if (SelectedClusterId !== 0) {
        if (SelectedProperties.clusterId) {
          const filterPostsIds = supercluster
            .getChildren(SelectedClusterId)
            .map((x) => x.properties.id);

          posts = posts.filter((s) => filterPostsIds.some((postId) => s.PostId === postId));
        } else {
          posts = posts.filter((s) => s.PostId === SelectedClusterId);
        }
      }
    }

    const isMobile = Width <= 961;
    const isSeleto = slug === Config.SECURITY.SELETO;
    const ClusterShape = ({ children }) => children;
    const countPosts = posts.length;
    const showBroker = Config.SECURITY.SELETO === slug || false;

    return (
      <>
        <MetaDecorator
          title={`${propertyType} à Venda ${googlePlaceName}`}
          description="O Homer te ajuda a encontrar o Apartamento ideal em todos os estados do Brasil com um processo de Compra Especial."
          imageUrl={HomerOg}
        />
        <div className="app">
          <AppHeader className="header">
            <DefaultHeader
              isMobile={isMobile}
              isSeleto={isSeleto}
              handleShowFilter={this.handleShowFilter}
              showSearch={ShowSearch}
              toggle={this.handleModal}
              isSearch
            />
          </AppHeader>
          <ContactModalComponent
            isMobile={isMobile}
            isSeleto={isSeleto}
            isOpen={IsOpen}
            PostId={PostId}
            OwnerId={OwnerId}
            toggle={this.handleModal}
            isSearch
          />
          <div className="results justify-content-center">
            <Container className="no-limit">
              <div className="justify-content-center">
                {!isMobile && ShowSearch && (
                  <Form onSubmit={this.handleFilter}>
                    <Row className="back">
                      <Col md={12}>
                        <Row className="row-flex-initial">
                          <ReactAutocomplete
                            inputProps={{
                              placeholder: 'Endereço, Bairro, Condomínio',
                              required: 'required',
                              className: 'location',
                            }}
                            menuStyle={{ zIndex: 99, position: 'fixed', left: 40 }}
                            required="required"
                            items={locations}
                            value={Location}
                            getItemValue={(item) => item.Name}
                            onSelect={(value, locale) => {
                              const data = {
                                GooglePlaceId: locale.Id,
                                Location: value,
                              };
                              p.getDataGeo(data);
                              return this.setState(data);
                            }}
                            onChange={(e) => {
                              this.handleLocation(e, e.target.value);
                            }}
                            renderItem={(item, isHighlighted) => (
                              <div
                                key={item.Name}
                                style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                                className="autocomplete"
                              >
                                {item.Name}
                              </div>
                            )}
                          />
                          <Button
                            className="icon"
                            type="button"
                          >
                            <img loading="lazy" src={searchBlack} alt="buscar" />
                          </Button>
                          <Input
                            type="select"
                            id="PropertyType"
                            name="PropertyType"
                            value={PropertyTypeSearch}
                            className="type"
                            onChange={(e) => this.setState({
                              PropertyTypeSearch:
                                e.target.value,
                            })}
                          >
                            <option value="" disabled defaultValue hidden>Tipo do imóvel</option>
                            {
                              properties.map((property) => (
                                <option
                                  key={property.Name}
                                >
                                  {property.Name}
                                </option>
                              ))
                            }
                          </Input>
                          <Input
                            type="select"
                            className="bedrooms"
                            id="QttRooms"
                            name="QttRooms"
                            value={QttRoomsSearch}
                            onChange={(e) => this.setState({ QttRoomsSearch: e.target.value })}
                          >
                            <option value="" disabled defaultValue hidden>Quartos</option>
                            <option value="0">0+</option>
                            <option value="1">1+</option>
                            <option value="2">2+</option>
                            <option value="3">3+</option>
                            <option value="4">4+</option>
                            <option value="5">5+</option>
                            <option value="6">6+</option>
                            <option value="7">7+</option>
                            <option value="8">8+</option>
                            <option value="9">9+</option>
                          </Input>
                          <Button
                            type="submit"
                            id="FilterButton"
                            name="FilterButton"
                            disabled={!Location || !PropertyTypeSearch || !QttRoomsSearch}
                          >
                            {Contact.SEARCH}
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                )}
                <Row className="no-margin">
                  {(!isMobile || (ShowMap && isMobile)) && (
                    <div className={ShowSearch ? 'col-map' : 'col-maps-no-search'}>
                      <div className="maps">
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: Config.SECURITY.GOOGLE_API_KEY }}
                          center={[latitude, longitude]}
                          zoom={ZoomLevel}
                          yesIWantToUseGoogleMapApiInternals
                          onChange={this.handleChange}
                          onChildClick={(key, property) => {
                            if (property.clusterId && property.clusterSize > 30) {
                              const expansionZoom = Math.min(
                                supercluster.getClusterExpansionZoom(parseInt(key, 10)),
                                20,
                              );
                              this.setState({
                                ZoomLevel: expansionZoom,
                                LatCenter: property.lat,
                                LngCenter: property.lng,
                              });
                            }

                            this.setState({
                              SelectedClusterId: parseInt(key, 10),
                              SelectedProperties: property,
                            });
                          }}
                          options={{
                            minZoom: MaxZoomLevel,
                            fullscreenControl: false,
                          }}
                        >
                          {
                            clusters.length > 0 && clusters.map((point) => {
                              const [currentLong, currentLat] = point.geometry.coordinates;
                              const clusterSize = Math.max(point.properties.point_count || 1, 1);
                              const ballSize = 30 + 5 * Math.floor(Math.log10(clusterSize));

                              return (
                                <ClusterShape
                                  key={point.id || point.properties.id}
                                  lat={currentLat}
                                  lng={currentLong}
                                  clusterId={point.id}
                                  postId={point.properties.id}
                                  clusterSize={clusterSize}
                                  clusterLat1={defaultPosition.lat1}
                                  clusterLat2={defaultPosition.lat2}
                                  clusterLng1={defaultPosition.lng1}
                                  clusterLng2={defaultPosition.lng2}
                                >
                                  <div>
                                    <Button
                                      className="pointers"
                                      onClick={() => {
                                        Mixpanel.trackEvent(searchMapImmobiles());
                                        this.setState({ ShowMap: !ShowMap });
                                      }}
                                      style={{
                                        width: `${ballSize}px`,
                                        height: `${ballSize}px`,
                                        left: `${-ballSize / 2}px`,
                                        top: `${-ballSize / 2}px`,
                                      }}
                                    >
                                      {clusterSize}
                                    </Button>
                                  </div>
                                </ClusterShape>
                              );
                            })
                          }
                        </GoogleMapReact>
                      </div>
                    </div>
                  )}
                  {(!isMobile || (!ShowMap && isMobile)) && (
                    <div className={ShowSearch ? 'posts-search' : 'posts'}>
                      <Row className="card-title">
                        {!isMobile
                          && (
                            <Col md="12">
                              <BreadcrumbsComponent
                                location={location}
                                address={googlePlaceName}
                                isSearch
                              />
                            </Col>
                          )}
                        {(!isMobile || (!ShowMap && isMobile)) && (
                          <Col className="align-self">
                            <Row className={isMobile ? 'more' : null}>
                              {!isMobile && countPosts === 0
                                && (<h3>{Contact.NONE}</h3>)}
                              {!isMobile && countPosts === 1
                                && (<h3>{`${countPosts}${Contact.ONE}`}</h3>)}
                              {!isMobile && countPosts > 1
                                && (<h3>{`${countPosts}${Contact.A_LOT}`}</h3>)}
                              <div className="float-revealing">
                                <Input
                                  type="select"
                                  id="OrderBy"
                                  name="OrderBy"
                                  value={OrderBy}
                                  placeholder="Mais revelantes"
                                  onClick={() => {
                                    Mixpanel.trackEvent(searchOrdination());
                                  }}
                                  onChange={(e) => {
                                    switch (e.target.value) {
                                      case 'MostRelevant':
                                        Mixpanel.trackEvent(searchOrdinationMostRevelant());
                                        break;
                                      case 'MostRecent':
                                        Mixpanel.trackEvent(searchOrdinationMostRecent());
                                        break;
                                      case 'LowestCost':
                                        Mixpanel.trackEvent(searchOrdinationLowerCost());
                                        break;
                                      case 'HigherCost':
                                        Mixpanel.trackEvent(searchOrdinationHigherCost());
                                        break;
                                      default:
                                        Mixpanel.trackEvent(searchOrdination());
                                        break;
                                    }
                                    this.setState({ OrderBy: e.target.value });
                                  }}
                                >
                                  <option value="MostRelevant">{Contact.REVEALING}</option>
                                  <option value="MostRecent">{Contact.RECENT}</option>
                                  <option value="LowestCost">{Contact.LOWEST_COST}</option>
                                  <option value="HigherCost">{Contact.MORE_COST}</option>
                                </Input>
                              </div>
                            </Row>
                          </Col>
                        )}
                      </Row>
                      <Row className={ShowSearch ? 'center-cards-search' : 'center-cards'}>
                        <div className="container-margin-top" />
                        {posts.map((post) => (
                          <Col
                            md={5}
                            key={post.PostId}
                            className="post card-center"
                          >
                            <PostComponent
                              md={6}
                              isSeleto={isSeleto}
                              post={post}
                              toggle={this.handleModal}
                              isSearch
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                </Row>
                {ShowMap && isMobile && (
                  <Row className={isMobile ? 'load-more align-center' : 'load-more'}>
                    <Col md={6}>
                      <Button onClick={() => this.setState({ ShowMap: !ShowMap })}>
                        <Row className="more">
                          <img loading="lazy" src={List} alt="Lista" />
                          {Contact.LIST}
                        </Row>
                      </Button>
                    </Col>
                  </Row>
                )}
                {!ShowMap && isMobile && (
                  <Row className={isMobile ? 'load-more align-center' : 'load-more'}>
                    <Col md={6}>
                      <Button onClick={() => this.setState({ ShowMap: !ShowMap })}>
                        <Row className="more">
                          <img loading="lazy" src={Map} alt="Mapa" />
                          {Contact.MAP}
                        </Row>
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </Container>
            {showBroker && (
              <DefaultFooter
                isMobile={isMobile}
                isSeleto={showBroker}
              />
            )}
          </div>
        </div>

      </>
    );
  }
}

Results.defaultProps = {
  coords: {},
  geo: {},
};

Results.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      googlePlaceName: PropTypes.string.isRequired,
      lat: PropTypes.string.isRequired,
      long: PropTypes.string.isRequired,
      propertyType: PropTypes.string.isRequired,
    }),
  }).isRequired,
  getDataAgent: PropTypes.func.isRequired,
  getDataAgentDetails: PropTypes.func.isRequired,
  getDataPropertyTypes: PropTypes.func.isRequired,
  getDataMap: PropTypes.func.isRequired,
  getDataGeo: PropTypes.func.isRequired,
  getDataLocation: PropTypes.func.isRequired,
  getAgent: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listPropertyTypes: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listLocation: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listMap: PropTypes.shape({
    data: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.object) }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  coords: PropTypes.shape({
    coords: PropTypes.shape({}),
    isGeolocationAvailable: PropTypes.bool,
    isGeolocationEnabled: PropTypes.bool,
  }),
  geo: PropTypes.shape({
    Latitude: PropTypes.number,
    Longitude: PropTypes.number,
  }),
  getGeo: PropTypes.shape({
    data: PropTypes.shape({ Latitude: PropTypes.number, Longitude: PropTypes.number }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getDataAgent,
    getDataAgentDetails,
    getDataPost,
    getDataPropertyTypes,
    getDataLocation,
    getDataMap,
    getDataGeo,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  getAgent: state.agentState.getAgent,
  listPropertyTypes: state.propertytypesState.listPropertyTypes,
  listMap: state.mapState.listMap,
  listLocation: state.locationState.listLocation,
  getGeo: state.geoState.getGeo,
});

const ResultsGeoloc = geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Results);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultsGeoloc));
