import { POST_USER, LIST_USER } from '../actionTypes/user';

const BASE = {
  state: false,
  data: null,
};

export const INITIAL_STATE = {
  statePostUser: BASE,
  listUser: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_USER:
      return { ...state, statePostUser: action.payload };
    case LIST_USER:
      return { ...state, listUser: action.payload };
    default:
      return state;
  }
};
