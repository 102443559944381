export default {
  LIST: {
    LOADING: 'Carregando Contacts...',
    TITLE: 'Lista de Contacts',
    ADD_BUTTON: 'Adicionar',
    TABLE_HEADER: {
      AGENTID: 'ID do Agente',
      POSTID: 'ID do Post',
      NAME: 'Nome',
      PHONE: 'Telefone',
      EMAIL: 'E-Mail',
      MESSAGE: 'Mensagem',
      SOURCEID: 'ID de Origem',

    },
  },
  FORM: {
    LOADING: 'Aguardando...',
    NOTIFY_SUCCESS_CAD: 'Contact cadastrado com sucesso!',
    NOTIFY_SUCCESS_UPD: 'Contact atualizado com sucesso!',
    SAVE_BUTTON: 'Salvar',
    BACK_BUTTON: 'Voltar',
    TITLE: 'Detalhes do Contact',
    AGENTID_FIELD: 'ID do Agente',
    POSTID_FIELD: 'ID do Post',
    NAME_FIELD: 'Nome',
    PHONE_FIELD: 'Telefone',
    EMAIL_FIELD: 'E-Mail',
    MESSAGE_FIELD: 'Mensagem',
    SOURCEID_FIELD: 'ID de Origem',

  },
  HELP: 'Quer ajuda de ',
  MESSAGE: 'Deixe uma mensagem',
  NAME: 'Nome:',
  TELEPHONE: 'Telefone:',
  EMAIL: 'E-mail:',
  MESSAGE_LABEL: 'Mensagem:',
  ONCLICK: 'Ao clicar no botão "Fale com ',
  CONFIRM: 'você afirma ter mais de 18 anos e aceita nossos ',
  TERMS: ' termos de uso',
  AND: ' e as ',
  PRIVACY: 'política de privacidade.',
  TALK_TO: 'Fale com ',
};
