import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row, Col, Card, Button, CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { postDataMessage } from '../../../actions/contact';
import { ContactModalComponent } from '../contact';
import Config from '../../../config/environments';
import NoImage from '../../../assets/imgs/no-image.png';
import IconCar from '../../../assets/icons/carIconBlack.svg';
import IconBed from '../../../assets/icons/bedIconBlack.svg';
import {
  enterpriseCard, enterpriseCardDetails, enterpriseCardTalkWith, homeSearchCard,
  homeSearchPropertyDetails, homeSearchTalkWith,
} from '../../../const/mixpanel';
import Mixpanel from '../../../mixpanel';

class PostEnterprise extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      IsOpen: false,
    };
    this.onImgLoad = this.onImgLoad.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  handleModal(event, redirect) {
    if (event) {
      event.preventDefault();
    }

    const { IsOpen } = this.state;
    const { isHomer, post } = this.props;

    if (redirect && isHomer && IsOpen) {
      const message = `Olá tenho interesse no empreendimento ${post.EnterpriseName}${post.DeveloperName ? ` (${post.DeveloperName}) ` : ''} e gostaria de tirar algumas dúvidas.`;

      const encoded = encodeURI(message);

      const url = `https://api.whatsapp.com/send?1=pt_BR&phone=${post.Phone}&text=${encoded}`;

      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('target', '_blank');
      a.click();
    }

    this.setState({ IsOpen: !IsOpen });
  }

  handleRedirect(event) {
    event.preventDefault();

    const { post, match } = this.props;
    const { params } = match;
    const { slug } = params;

    const url = `${window.location.origin}/${slug}${Config.ENTERPRISE.ENTERPRISE_DETAILS}/${post.PostId}`;

    window.open(url, '_self');
  }

  // eslint-disable-next-line class-methods-use-this
  onImgLoad(event) {
    const { naturalHeight, naturalWidth, classList } = event.target;

    let isImgContains = true;

    if (naturalHeight >= naturalWidth) {
      isImgContains = true;
    }
    if (naturalHeight <= naturalWidth) {
      isImgContains = false;
    }

    classList.add(`${isImgContains ? 'img-contains' : 'img-cover'}`);
  }

  render() {
    const {
      post, isHomer, getAgentDetails, isHome, isEnterprise,
    } = this.props;
    const { IsOpen } = this.state;
    const zeroRooms = post.MaximumRooms === 0;
    const zeroParkingSpots = post.MaximumParkingSpots === 0;
    const mockImgs = post.PostMedia[0] || { MediaUrl: NoImage, Order: 1 };
    const userDetails = (getAgentDetails && getAgentDetails.data) || {};
    const username = userDetails && userDetails.FullName ? userDetails.FullName.split(' ')[0] : '';

    return (
      <>
        {IsOpen && (
          <ContactModalComponent
            PostId={post.PostId}
            isOpen={IsOpen}
            isHomer={isHomer}
            isEnterprise={isEnterprise}
            Incorp
            toggle={this.handleModal}
          />
        )}
        <Col lg={isHome ? 12 : 3} className="container-card-enterprise">
          <Card
            className="card-enterprise"
            onClick={(e) => {
              this.handleRedirect(e);
              if (isEnterprise) {
                Mixpanel.trackEvent(enterpriseCard());
              } else {
                Mixpanel.trackEvent(homeSearchCard());
              }
            }}
          >
            <div
              className="container-image"
              name={`ImageContainer${post.PostId}`}
              id={`ImageContainer${post.PostId}`}
              data-testid={`ImageContainer${post.PostId}`}
            >
              <img
                loading="lazy"
                className="img-carousel"
                src={mockImgs.MediaUrl}
                onLoad={this.onImgLoad}
                alt="Fotos"
                name={`Image${post.PostId}`}
                id={`Image${post.PostId}`}
                data-testid={`Image${post.PostId}`}
                onError={(e) => { e.target.onerror = null; e.target.src = NoImage; }}
              />
            </div>
            <CardBody>
              <Row>
                <Col className="container-name-broker">
                  <p>Empreendimento</p>
                  <h5>{`${post.EnterpriseName}  ${post.DeveloperName ? `- ${post.DeveloperName}` : ''}`}</h5>
                </Col>
              </Row>
              <Row className="container-immobile-location">
                <p>{post.Location}</p>
              </Row>
              <Row className="row-value">
                <span>A partir de</span>
                <p
                  className="text-value"
                  id={`Price${post.PostId}`}
                  name={`Price${post.PostId}`}
                  data-testid={`Price${post.PostId}`}
                >
                  {post && (post.PriceLabelText || 0)}
                </p>
              </Row>
              <Row className="container-comission">
                <p>Maior comissão de startup do mercado</p>
              </Row>
              <Row className="container-icons">
                <div
                  name={`CardContainerBedrooms${post.PostId}`}
                  id={`CardContainerBedrooms${post.PostId}`}
                  data-testid={`CardContainerBedrooms${post.PostId}`}
                >
                  {!zeroRooms ? (
                    <img loading="lazy" src={IconBed} alt="Ícone Quartos" />
                  ) : (<></>)}
                  <p>{post.MinimumRooms !== post.MaximumRooms ? `${post.MinimumRooms} a ${post.MaximumRooms}` : `${!zeroRooms ? (post.MaximumRooms) : 'Sem quartos'}`}</p>
                </div>
                <div
                  name={`CardContainerParkingSpots${post.PostId}`}
                  id={`CardContainerParkingSpots${post.PostId}`}
                  data-testid={`CardContainerParkingSpots${post.PostId}`}
                >
                  {!zeroParkingSpots ? (
                    <img loading="lazy" src={IconCar} alt="Ícone Vagas" />
                  ) : (<></>)}
                  <p>{post.MinimumParkingSpots !== post.MaximumParkingSpots ? `${post.MinimumParkingSpots} a ${post.MaximumParkingSpots}` : `${!zeroParkingSpots ? post.MaximumParkingSpots : 'Sem vagas'}`}</p>
                </div>
              </Row>
              <Row className="row-button">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleModal(e);
                    if (isEnterprise) {
                      Mixpanel.trackEvent(enterpriseCardTalkWith());
                    } else {
                      Mixpanel.trackEvent(homeSearchTalkWith());
                    }
                  }}
                  type="button"
                  className="btn_web_secondary w-100"
                  id="buttonShareCard"
                  name="buttonShareCard"
                  data-testid={`buttonShareCard${post.PostId}`}
                >
                  {`Falar com ${isHomer ? 'o Homer' : username}`}
                </Button>
              </Row>
              <Row className="row-button">
                <Button
                  onClick={(e) => {
                    this.handleRedirect(e, true);
                    e.stopPropagation();
                    if (isEnterprise) {
                      Mixpanel.trackEvent(enterpriseCardDetails());
                    } else {
                      Mixpanel.trackEvent(homeSearchPropertyDetails());
                    }
                  }}
                  type="button"
                  className="btn_web_secondary w-100"
                  id="ButtonSeeMoreDetails"
                  name="ButtonSeeMoreDetails"
                  data-testid={`ButtonSeeMoreDetails-${post.PostId}`}
                >
                  Ver mais detalhes
                </Button>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

PostEnterprise.defaultProps = {
  post: {},
};

PostEnterprise.propTypes = {
  post: PropTypes.shape({
    PostMedia: PropTypes.arrayOf(PropTypes.object),
    EnterpriseName: PropTypes.string,
    DeveloperName: PropTypes.string,
    Location: PropTypes.string,
    Comission: PropTypes.string,
    Rooms: PropTypes.number,
    Area: PropTypes.number,
    ParkingSpots: PropTypes.number,
    UserFullname: PropTypes.string,
    AreaLabelText: PropTypes.string,
    Description: PropTypes.string,
    PriceLabelText: PropTypes.string,
    MinimumPrice: PropTypes.number,
    MinimumRooms: PropTypes.number,
    MaximumRooms: PropTypes.number,
    MinimumParkingSpots: PropTypes.number,
    MaximumParkingSpots: PropTypes.number,
    PropertyDeveloperLogoUrl: PropTypes.string,
    PropertyDeveloperName: PropTypes.string,
    ConstructionAreaLabelText: PropTypes.string,
    UpdatedDateTime: PropTypes.string,
    PropertyTaxes: PropTypes.string,
    Phone: PropTypes.string,
    PropertyTypeHasArea: PropTypes.bool,
    PropertyTypeHasRooms: PropTypes.bool,
    DevelopmentStatus: PropTypes.shape({}),
    PostId: PropTypes.number,
    FinancingMaximumInstallmentPrice: PropTypes.number,
    UserId: PropTypes.number,
    Price: PropTypes.number,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
      postId: PropTypes.string,
      userId: PropTypes.string,
      slug: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
  }).isRequired,
  getAgentDetails: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  isHomer: PropTypes.bool.isRequired,
  isHome: PropTypes.bool.isRequired,
  isEnterprise: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    postDataMessage,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  getAgentDetails: state.agentdetailsState.getAgentDetails,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostEnterprise));
