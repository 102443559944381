import { localGet, localSet, localRemove } from '../lib/session';
import Config from '../config/environments';
import { AUTH_TOKEN, IS_LOGGED } from '../actionTypes/auth';

const MAX_IDLE_TIME = process.env.MAX_IDLE_TIME
  ? Number.parseInt(process.env.MAX_IDLE_TIME, 10)
  : Config.EXPIRATION_AUTH;

const logged = () => (localGet(IS_LOGGED) ? localGet(IS_LOGGED).value : '0');

const isLoggedIn = () => logged === 'true';

export const lastAPICallTime = () => Number.parseInt(logged, 10);

const isIdle = () => (new Date().getTime() - lastAPICallTime()) > MAX_IDLE_TIME;

export const logout = async () => {
  localSet(IS_LOGGED, 'false');
  localRemove(AUTH_TOKEN);
};

export const checkIdle = async () => {
  if (isIdle() && isLoggedIn()) {
    logout();
  }

  return Promise.resolve(true);
};
