export default {
  Location: '',
  GooglePlaceId: '',
  IsRent: false,
  Amount: '',
  PropertyType: 'Apartamento',
  QttRooms: 0,
  QttParkings: 0,
  RequestedAgent: false,
  RequestedAgentSeleto: false,
  RequestedAgentDetails: false,
  RequestedPost: false,
  RequestedPostSeleto: false,
  RequestedPostDetails: false,
  RequestAgentSeleto: false,
  IsOpen: false,
  Banner: {},
  GetBanner: true,
};
