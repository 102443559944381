import Model from '../model/post';
import Config from '../config/environments';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.PostId = view.PostId;
    model.UserId = view.UserId;
    model.UserFullname = view.UserFullname;
    model.PostType = view.PostType;
    model.PropertyType = typeof view.PropertyType === 'string'
      ? view.PropertyType
      : view.PropertyType.shortLabelText;
    model.Location = view.Location;
    model.Latitude = view.Latitude;
    model.Longitude = view.Longitude;
    model.CreatedDateTime = view.CreatedDateTime;
    model.UpdatedDateTime = view.UpdatedDateTime;
    model.Rooms = view.Rooms;
    model.Area = view.Area;
    model.Price = parseInt(`${view.Price}00`, 10);
    model.ParkingSpots = view.ParkingSpots;
    model.Description = view.Description;
    model.AvatarUrl = view.AvatarUrl;
    model.WktPolygon = view.WktPolygon;
    model.IsActive = view.IsActive;
    model.MinimumPrice = parseInt(`${view.MinimumPrice}00`, 10);
    model.MaximumPrice = parseInt(`${view.MaximumPrice}00`, 10);
    model.MinimumArea = view.MinimumArea;
    model.MaximumArea = view.MaximumArea;
    model.IsRent = view.IsRent;
    model.PageTotalMatches = view.PageTotalMatches;
    model.HasExtraInfo = view.HasExtraInfo;
    model.OriginalPrice = view.OriginalPrice;
    model.StaticMapUrl = view.StaticMapUrl;
    model.ShowHomerSellsOffer = view.ShowHomerSellsOffer;
    model.UserCreciNumber = view.UserCreciNumber;
    model.UserCreciVerifiedIdStatus = view.UserCreciVerifiedIdStatus;
    model.UserCreciUF = view.UserCreciUF;
    model.PostMedia = view.PostMedia || [];
    model.IsHomerSell = view.IsHomerSell;
    model.CondoFee = parseInt(`${view.CondoFee}`, 10);
    model.PropertyTaxes = parseInt(`${view.PropertyTaxes}`, 10);

    model.PostMedia.forEach((media) => {
      const mediaProxy = media;
      const mediaProxyContains = mediaProxy && mediaProxy.MediaUrl && mediaProxy.MediaUrl.indexOf('homer-posts');
      if (mediaProxyContains > 0) {
        mediaProxy.MediaUrl = `${media.MediaUrl}`;
      } else {
        mediaProxy.MediaUrl = `${Config.END_POINT_API}${Config.POST.INSERT}${Config.POST.MEDIAS}${Config.POST.CONTENT}${Config.POST.URL_MEDIA}${media.MediaUrl}`;
      }
    });

    return model;
  } catch (error) {
    throw new Error(`PostMapper - ViewModelToModel = ${error}`);
  }
};

export const viewModelToModelApi = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.PostId = view.id;
    model.UserId = view.userId;
    model.UserFullname = view.userFullname;
    model.PostType = view.postType;
    model.PropertyType = view.propertyType;
    model.Location = view.location;
    model.Latitude = view.latitude;
    model.Longitude = view.longitude;
    model.CreatedDateTime = view.createdDateTime;
    model.UpdatedDateTime = view.updatedDateTime;
    model.Rooms = view.numRooms;
    model.Area = view.area;
    model.Price = parseInt(`${view.price}00`, 10);
    model.ParkingSpots = view.parkingSpots;
    model.Description = view.description;
    model.AvatarUrl = view.avatarUrl;
    model.WktPolygon = view.wktPolygon;
    model.IsActive = view.isActive;
    model.MinimumPrice = view.minimumPrice;
    model.MaximumPrice = view.maximumPrice;
    model.MinimumArea = view.minimumArea;
    model.MaximumArea = view.maximumArea;
    model.IsRent = view.isRent;
    model.PageTotalMatches = view.pageTotalMatches;
    model.HasExtraInfo = view.hasExtraInfo;
    model.OriginalPrice = view.originalPrice;
    model.StaticMapUrl = view.staticMapUrl;
    model.ShowHomerSellsOffer = view.showHomerSellsOffer;
    model.UserCreciNumber = view.userCreciNumber;
    model.UserCreciVerifiedIdStatus = view.userCreciVerifiedIdStatus;
    model.UserCreciUF = view.userCreciUF;
    model.PostMedia = view.medias || [];
    model.IsHomerSell = view.isHomerSell;
    model.CondoFee = parseInt(`${view.condoFee}`, 10);
    model.PropertyTaxes = parseInt(`${view.propertyTaxes}`, 10);

    model.PostMedia.forEach((media) => {
      const mediaProxy = media;
      const mediaProxyContains = mediaProxy && mediaProxy.MediaUrl && mediaProxy.MediaUrl.indexOf('homer-posts');
      if (mediaProxyContains > 0) {
        mediaProxy.MediaUrl = `${media.MediaUrl}`;
      } else {
        mediaProxy.MediaUrl = `${Config.END_POINT_API}${Config.POST.INSERT}${Config.POST.MEDIAS}${Config.POST.CONTENT}${Config.POST.URL_MEDIA}${media.MediaUrl}`;
      }
    });

    return model;
  } catch (error) {
    throw new Error(`PostMapper - ViewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models, state) => {
  try {
    if (!models) return null;
    const views = [];

    models.forEach(async (model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    const upState = state || {};
    const division = parseInt(models.total / upState.PageSize, 10);
    const remainder = models.total % upState.PageSize;

    const viewsPag = {
      total: division + (remainder <= 0 ? 0 : 1),
      list: views,
    };

    return viewsPag;
  } catch (error) {
    throw new Error(`PostMapper - ViewsModelToModels = ${error}`);
  }
};

export const viewsModelToModelsApi = (models, state) => {
  try {
    if (!models) return null;
    const views = [];

    models.forEach(async (model) => {
      const view = viewModelToModelApi(model);

      views.push(view);
    });

    const upState = state || {};
    const division = parseInt(models.total / upState.PageSize, 10);
    const remainder = models.total % upState.PageSize;

    const viewsPag = {
      total: division + (remainder <= 0 ? 0 : 1),
      list: views,
    };

    return viewsPag;
  } catch (error) {
    throw new Error(`PostMapper - ViewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    view.userId = model.UserId;

    return view;
  } catch (error) {
    throw new Error(`PostMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`PostMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Uuid = view.Uuid || '';
    model.UserId = view.UserId || '';
    model.PostId = view.PostId || '';
    model.Area = view.Area || '';
    model.IsRent = view.IsRent || '';
    model.Location = view.Location || '';
    model.PostMedia = view.PostMedia || '';
    model.ParkingSpots = view.ParkingSpots || '';
    model.Price = view.Price || '';
    model.Tags = view.Tags || '';
    model.Rooms = view.Rooms || '';
    model.PropertyType = view.PropertyType || '';
    model.IsHomerSell = view.IsHomerSell || '';
    model.Description = view.Description || '';

    model.PostMedia.forEach((media) => {
      const mediaProxy = media;
      mediaProxy.MediaUrl = `${Config.END_POINT_API}${Config.POST.INSERT}${Config.POST.MEDIAS}${Config.POST.CONTENT}${Config.POST.URL_MEDIA}${media.MediaUrl}`;
    });

    return model;
  } catch (error) {
    throw new Error(`PostMapper - ViewModelToState = ${error}`);
  }
};

export const modelToFilter = (model) => {
  try {
    if (!model) return null;

    let view = '';

    model.Filter.forEach((filter) => {
      const id = filter.id.toLowerCase();

      if (id.includes('document')) {
        view += `${id}=${encodeURIComponent(filter.value.replace(/\D/g, ''))}&`;
      } else {
        view += `${id}=${encodeURIComponent(filter.value)}&`;
      }
    });

    model.Sort.forEach((sort) => {
      const id = sort.id.toLowerCase();
      view += `sort=${id}&`;
      view += `desc=${sort.desc}&`;
    });

    view += `page=${model.Page + 1}&`;
    view += `quantity=${model.PageSize}`;

    return view;
  } catch (error) {
    throw new Error(`PostMapper - ModelToFilter = ${error}`);
  }
};
