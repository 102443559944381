import axios from 'axios';
import Config from '../config/environments';
import validation from './validation';
import authorization from './authorization';
import { checkIdle } from '../service/user';

const baseURL = Config.END_POINT;
const baseURLAuth = Config.END_POINT_AUTH;
const baseURLAPIHomer = Config.END_POINT_API;
const baseURLAPI = Config.LIST_SELETO.URL;

const baseRequest = {
  baseURL,
  withCredentials: false,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

const baseRequestApiHomer = {
  baseURL: baseURLAPIHomer,
  withCredentials: false,
  timeout: 0,
  headers: {
    'Content-Type': 'text/json',
  },
};

const baseRequestLogin = {
  baseURL: baseURLAuth,
  withCredentials: false,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

const baseRequestApi = {
  baseURL: baseURLAPI,
  withCredentials: true,
  timeout: 0,
  headers: {
    'Content-Type': 'text/json',
    // 'Access-Control-Allow-Origin': 'Origin, X-Requested-With, Content-Type, Accept',
  },
};

const axiosInstance = axios.create(baseRequest);
const axiosLogin = axios.create(baseRequestLogin);
const axiosApi = axios.create(baseRequestApi);
const axiosApiHomer = axios.create(baseRequestApiHomer);

const checkingInterceptor = (config) => {
  checkIdle();
  validation(config.response);
  return config;
};

axiosInstance.interceptors.response.use(validation, checkingInterceptor);
axiosInstance.interceptors.request.use(authorization);
axiosLogin.interceptors.response.use(validation, validation);
axiosApiHomer.interceptors.response.use(validation, checkingInterceptor);
// axiosApi.interceptors.response.eject();
// axiosApi.interceptors.request.eject();

export {
  axiosInstance, axiosLogin, axiosApi, axiosApiHomer,
};
