import Model from '../model/postdetails';
import Config from '../config/environments';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;
    const model = { ...Model };
    let text = '';
    let textLink = '';
    let description = '';
    if (view.description && view.description !== undefined && view.description.toUpperCase().includes('CORRETOR RESPONSÁVEL')) {
      const findText = view.description.substring(view.description.toUpperCase().indexOf('CORRETOR RESPONSÁVEL'));
      if (findText.length === view.description.length) {
        text = view.description.substring(view.description.toUpperCase().indexOf('CORRETORA RESPONSÁVEL'), view.description.length);
        if (text.indexOf(text.indexOf('\n\n') + 2) > 1) {
          description = view.description.replace(text, '');
        } else {
          text = text.substring(text.toUpperCase().indexOf('CORRETORA RESPONSÁVEL'), text.indexOf('\n\n') + 2);
          description = view.description.replace(text, '');
        }
      } else {
        text = view.description.substring(view.description.toUpperCase().indexOf('CORRETOR RESPONSÁVEL'), view.description.length);
        text = text.substring(text.toUpperCase().indexOf('CORRETOR RESPONSÁVEL'), text.indexOf('\n\n') + 2);
      }
      textLink = view.description.substring(view.description.toUpperCase().indexOf('LINK PARA AS FOTOS PROFISSIONAIS'), view.description.length);
      description = view.description.replace(text, '');
      description = description.replace(textLink, '');
    } else {
      description = view.description;
    }
    const mock = { text: '' };
    const price = view.features && view.features.filter((x) => x.name === 'Preco por metro quadrado')[0];
    const priceFormated = price || mock;
    const pricePerMeter = priceFormated && (priceFormated.text && priceFormated.text.includes('R$')) ? priceFormated.text : priceFormated.text.replace('$', 'R$ ').replace(',', '.');
    const propertyFeature = view.features && view.features.filter((x) => x.category === 'PropertyFeature');
    const commonArea = view.features && view.features.filter((x) => x.category === 'CommonArea');

    const financingFormated = view.financing && view.financing.maximumInstallmentPrice
      && parseInt(`${view.financing.maximumInstallmentPrice.amount}00`, 10);

    model.PostId = view.id;
    model.InstallmentPrice = financingFormated;
    model.UserId = view.user.id;
    model.PropertyFeature = propertyFeature;
    model.CommonArea = commonArea;
    model.PostType = view.postType && view.postType === 'Buy' ? 1 : 2;
    model.CondoFee = view.price && view.price.condoFee ? `${view.price.condoFee.currency} ${view.price.condoFee.amount}` : 'R$ 0';
    model.PropertyTaxes = view.price && view.price.propertyTaxes
      ? `${view.price.propertyTaxes.currency} ${view.price.propertyTaxes.amount}` : 'R$ 0';
    model.Description = description;
    model.PropertyType = view.propertyType && view.propertyType.labelText;
    model.PropertyTypeAreaUnit = view.propertyType && view.propertyType.areaUnit;
    model.PropertyTypeHasArea = view.propertyType && view.propertyType.hasArea;
    model.PropertyTypeHasBuiltUpArea = view.propertyType && view.propertyType.hasBuiltUpArea;
    model.PropertyTypeHasParkingSpots = view.propertyType && view.propertyType.hasParkingSpots;
    model.PropertyTypeHasRooms = view.propertyType && view.propertyType.hasRooms;
    model.PropertyTypeHasTotalArea = view.propertyType && view.propertyType.hasTotalArea;
    model.PropertyTypeId = view.propertyType && view.propertyType.id;
    model.Location = view.location && (view.location.prettyAddress
      ? view.location.prettyAddress : view.location);
    model.Latitude = view.location && (view.location.latitude
      ? view.location.latitude : view.location);
    model.Longitude = view.location && (view.location.prettyAddress
      ? view.location.longitude : view.location);
    model.CreatedDateTime = view.createdAt;
    model.UpdatedDateTime = view.updatedAt;
    model.Rooms = view.size && view.size.rooms;
    model.ParkingSpots = view.size && view.size.parkingSpots;
    model.Title = view.title;
    model.UserFullname = view.user && view.user.name;
    model.PricePerMeter = pricePerMeter;
    model.Price = parseInt(`${view.price && view.price.minimumPrice && view.price.minimumPrice.amount}`, 10);
    model.Area = view.size && view.size.maximumArea && view.size.maximumArea.value;
    model.MinimumArea = view.size && view.size.minimumArea && view.size.minimumArea.value;
    model.MaximumArea = view.size && view.size.maximumArea && view.size.maximumArea.value;
    model.MinimumTotalArea = view.size && view.size.minimumTotalArea
      && view.size.minimumTotalArea.value;
    model.MaximumTotalArea = view.size && view.size.maximumTotalArea
      && view.size.maximumTotalArea.value;
    model.MinimumBuiltUpArea = view.size && view.size.minimumBuiltUpArea
      && view.size.minimumBuiltUpArea.value;
    model.MaximumBuiltUpArea = view.size && view.size.maximumBuiltUpArea
      && view.size.maximumBuiltUpArea.value;
    model.MinimumPrice = (view.price && view.price.minimumPrice
      && parseInt(`${view.price.minimumPrice.amount}00`, 10)) || 0;
    model.MaximumPrice = (view.price && view.price.maximumPrice
      && parseInt(`${view.price.maximumPrice.amount}00`, 10)) || 0;
    model.TotalMatches = view.matchesCount;
    model.DaysPriorToExpiration = view.daysPriorToExpiration;
    model.IsRent = view.isRent;
    model.StaticMapUrl = view.location && view.location.staticMapUrl;
    model.PostMedia = (view.medias && view.medias.map(({
      id: PostMediaId,
      url: MediaUrl,
      ...rest
    }) => ({
      PostMediaId,
      MediaUrl,
      ...rest,
    }))) || [];
    model.PriceLabelText = `R$ ${view.price || view.price.minimumPrice ? view.price.minimumPrice.toLocaleString('pt-br', { minimumFractionDigits: 0 }) : '0'}`;
    model.AvatarUrl = view.user && view.user.avatarUrl;

    model.PostMedia.forEach((media) => {
      const mediaProxy = media;
      const mediaProxyContains = mediaProxy && mediaProxy.MediaUrl && mediaProxy.MediaUrl.indexOf('homer-posts');
      if (mediaProxyContains > 0) {
        mediaProxy.MediaUrl = `${media.MediaUrl}`;
      } else {
        mediaProxy.MediaUrl = `${Config.END_POINT_API}${Config.POST.INSERT}${Config.POST.MEDIAS}${Config.POST.CONTENT}${Config.POST.URL_MEDIA}${media.MediaUrl}`;
      }
    });

    return model;
  } catch (error) {
    throw new Error(`PostDetailsMapper - ViewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models, state) => {
  try {
    if (!models) return null;
    const views = [];

    models.data.forEach(async (model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    const upState = state || {};
    const division = parseInt(models.total / upState.PageSize, 10);
    const remainder = models.total % upState.PageSize;

    const viewsPag = {
      total: division + (remainder <= 0 ? 0 : 1),
      list: views,
    };

    return viewsPag;
  } catch (error) {
    throw new Error(`PostDetailsMapper - ViewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (postId) => {
  try {
    if (!postId) return null;

    const view = {};

    view.postId = postId;

    return view;
  } catch (error) {
    throw new Error(`PostDetailsMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`PostDetailsMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Name = view.Name || '';

    return model;
  } catch (error) {
    throw new Error(`PostDetailsMapper - ViewModelToState = ${error}`);
  }
};

export const modelToFilter = (model) => {
  try {
    if (!model) return null;

    let view = '';

    model.Filter.forEach((filter) => {
      const id = filter.id.toLowerCase();

      if (id.includes('document')) {
        view += `${id}=${encodeURIComponent(filter.value.replace(/\D/g, ''))}&`;
      } else {
        view += `${id}=${encodeURIComponent(filter.value)}&`;
      }
    });

    model.Sort.forEach((sort) => {
      const id = sort.id.toLowerCase();
      view += `sort=${id}&`;
      view += `desc=${sort.desc}&`;
    });

    view += `page=${model.Page + 1}&`;
    view += `quantity=${model.PageSize}`;

    return view;
  } catch (error) {
    throw new Error(`PostDetailsMapper - ModelToFilter = ${error}`);
  }
};
