import { axiosInstance as axios, axiosApiHomer } from '../lib/axios';
import Config from '../config/environments';
import { POST_CONTACT, LIST_CONTACT, MESSAGE_ENTERPRISE } from '../actionTypes/contact';
import { viewsModelToModels, modelToVieModelMessage, requestEnterprise } from '../mapper/contact';
import updateState from './common/common';

export const getDataContact = (data, state) => async (dispatch) => {
  const base = {
    type: LIST_CONTACT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.CONTACT.URL}?${data}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewsModelToModels(base.payload.data, state);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const getDataContactWithoutPagination = () => async (dispatch) => {
  const base = {
    type: LIST_CONTACT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.CONTACT.URL}?page=1&quantity=100`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewsModelToModels(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const postDataContactNoPost = (data) => async (dispatch) => {
  const base = {
    type: POST_CONTACT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.CONTACT.URL}/${Config.CONTACT.URL_NO_POST}`;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
    base.payload.data = modelToVieModelMessage(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const postDataContactPropDev = (data) => async (dispatch) => {
  const base = {
    type: POST_CONTACT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.CONTACT.URL}/${Config.CONTACT.ULR_PROP_DEV}`;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
    base.payload.data = modelToVieModelMessage(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const postDataContactOtherPost = (data) => async (dispatch) => {
  const base = {
    type: POST_CONTACT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.CONTACT.URL}/${Config.CONTACT.URL_OTHER_POST}`;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
    base.payload.data = modelToVieModelMessage(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const postDataContactAgentPost = (data) => async (dispatch) => {
  const base = {
    type: POST_CONTACT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.CONTACT.URL}/${Config.CONTACT.URL_AGENT_POST}`;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
    base.payload.data = modelToVieModelMessage(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const postDataMessage = (data) => async (dispatch) => {
  const base = {
    type: POST_CONTACT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.CONTACT.ME}${Config.AGENT.URL}${Config.CONTACT.MESSAGES}`;

  axiosApiHomer.post(url, data).then((response) => {
    base.payload = updateState(response);
    base.payload.data = modelToVieModelMessage(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const putDataContact = (data) => async (dispatch) => {
  const base = {
    type: POST_CONTACT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.CONTACT.URL}/${data.uuid}`;

  axios.put(url, data).then((response) => {
    base.payload = updateState(response);
    base.payload.data = modelToVieModelMessage(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const setStatusPost = () => async (dispatch) => {
  const base = {
    type: POST_CONTACT,
    payload: {},
  };

  dispatch(base);
};

export const getDataMessage = (data, visit) => async (dispatch) => {
  const base = {
    type: MESSAGE_ENTERPRISE,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.POST.DEVELOPMENTS}/${data}${Config.POST.CONTACT_MESSAGE}${visit ? 'ScheduleVisit' : 'TalkToSpecialist'}`;

  axiosApiHomer.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = requestEnterprise(base.payload.data, data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const clearDataMessage = () => async (dispatch) => {
  const base = {
    type: MESSAGE_ENTERPRISE,
    payload: {
      data: null,
    },
    state: false,
  };

  dispatch(base);
};
