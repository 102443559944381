export default {
  Location: '',
  GooglePlaceId: '',
  IsRent: false,
  Amount: '',
  PropertyType: 'Apartamento',
  PropertyTypeSearch: '',
  QttRooms: 0,
  QttRoomsSearch: '',
  QttParkings: 0,
  OrderBy: 'HigherCost',
  RequestedAgent: 'false',
  RequestedAgentDetails: false,
  RequestedProperties: false,
  RequestedMap: false,
  RequestedFilter: false,
  LatCenter: 0,
  LngCenter: 0,
  ZoomLevel: 18,
  MaxZoomLevel: 15,
  LocationBoundaries: null,
  MapNE: null,
  MapSW: null,
  SelectedClusterId: 0,
  SelectedProperties: null,
  IsOpen: false,
  PostId: 0,
};
