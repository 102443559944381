import { axiosLogin as axios } from '../lib/axios';
import Config from '../config/environments';
import { viewModelToModel } from '../mapper/user';
import { AUTH_USER } from '../actionTypes/auth';
import updateState from './common/common';

export const authDataUser = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    state: true,
    payload: {},
  };

  const url = Config.AUTH.URL;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = error;
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const resetDataPassword = (data, token) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    state: true,
    payload: {},
  };

  const url = `${Config.USER.RESET}/${token}`;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = error;
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const setStatusAuth = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    payload: { data, state: false },
  };

  dispatch(base);
};
