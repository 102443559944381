import React, { Component } from 'react';
import {
  Col, Container, Row, Label, Button,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Contact from '../../const/404';
import BackgroundImage from '../../assets/imgs/empty_house.svg';

class Page404 extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  handleRedirect(event) {
    event.preventDefault();

    const { history } = this.props;
    const path = '/buscar-imoveis';
    history.push(path);
    window.location.reload(false);
  }

  render() {
    return (
      <div
        className="app align-items-center"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100vw',
          height: '20vh',
        }}
      >
        <Container className="container-404">
          <Row>
            <Col md="5">
              {/* <div className="clearfix"> */}
              <Row>
                <Label
                  className="label-erro"
                  id="InformationError"
                  name="InformationError"
                  data-testid="InformationError"
                >
                  {Contact.ERROR}
                </Label>
              </Row>
              <Row style={{ width: '350px' }}>
                <Label
                  className="label-ops"
                  id="PageNotFound"
                  name="PageNotFound"
                  data-testid="PageNotFound"
                >
                  {Contact.LOST}
                </Label>
              </Row>
              <Row style={{ width: '300px' }}>
                <Label
                  className="label-notfound"
                  id="CheckTheUrl"
                  name="CheckTheUrl"
                  data-testid="CheckTheUrl"
                >
                  {Contact.NOT_FOUND}
                </Label>
              </Row>
              <Row style={{ width: '350px' }}>
                <Label
                  className="label-searchtext"
                  id="EnterInHomer"
                  name="EnterInHomer"
                  data-testid="EnterInHomer"
                >
                  {Contact.SEARCH_TEXT}
                </Label>
              </Row>
              <Row>
                <Button
                  type="button"
                  className="btn-salmon-seleto"
                  onClick={(e) => this.handleRedirect(e)}
                >
                  {Contact.SEARCH}
                </Button>
              </Row>
              {/* </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Page404.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Page404);
