export default {
  HELP: 'Quer ajuda de ',
  MESSAGE: 'Deixe uma mensagem',
  NAME: 'Nome:',
  TELEPHONE: 'Telefone:',
  EMAIL: 'E-mail:',
  MESSAGE_LABEL: 'Mensagem:',
  ONCLICK: 'Ao clicar no botao "Fale com o corretor", você afirma ter mais de 18 anos e aceita nossos',
  TERMS: ' termos de uso',
  AND: ' e ',
  PRIVACY: 'política de privacidade',
  TALK_TO: 'Fale com ',
};
