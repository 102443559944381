import { GET_ENTERPRISE_DETAILS, LIST_ENTERPRISE } from '../actionTypes/enterprise';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

export const INITIAL_STATE = {
  getEnterpriseDetails: BASE,
  listEnterprise: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ENTERPRISE_DETAILS:
      return { ...state, getEnterpriseDetail: action.payload };
    case LIST_ENTERPRISE:
      return { ...state, listEnterprise: action.payload };
    default:
      return state;
  }
};
